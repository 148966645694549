// Main sidebar
.sidebar-container {
    @include betweenitems;
    flex-direction: column;
    width: 64px;
    height: 100%;
    padding: 16px 0;
    opacity: 0;
    overflow-x: initial;
    overflow-y: initial;
    -webkit-overflow-y: initial;
    -webkit-overflow-scrolling: touch;
    row-gap: 12px;
    min-height: 550px;
    &:not(.active) {
        z-index: -1;
    }
    &__content {
        row-gap: 20px;
        @media (max-height: 685px) {
            row-gap: 12px;
        }
        &:first-child:hover {
            z-index: 10001;
        }
    }
    .menu-item {
        display: block;
        position: relative;
        .active {
            display: none;
        }
        &:hover,
        &.is-active {
            .active {
                display: block;
            }
            .default {
                display: none;
            }
        }
    }
    @media only screen and (max-width: 1024px) {
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-y: auto;
        .menu-item {
            &:hover .tooltiptext {
                visibility: hidden;
            }
        }
    }
}
// Sidebar pages
.sidebar-pages {
    border-right: solid 1px $border-color-grey;
    line-height: 20px;
    font-weight: 500;
    flex: 0 0 auto;
    padding: 24px 8px;
    width: 240px;
    &.is-hide-sidebar {
        width: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        border-right: 0;
        overflow: hidden;
        opacity: 0;
        .tags__info {
            opacity: 0;
        }
    }
    &.--sm {
        width: 200px;
        padding: 12px !important;
    }
    > ul {
        &:not(.wrap-loading) {
            .items {
                border-radius: 4px;
                &:hover {
                    background: $btn-hover;
                    color: $grey-extradark;
                }
                &:active,
                &.active {
                    background: $yellow-light;
                    color: $grey-extradark;
                    @extend .fw-600;
                }
                &:hover:not(.svg-noeffect),
                &.active:not(.svg-noeffect) {
                    svg {
                        path {
                            fill: $purple-light;
                            &:last-child {
                                fill: $purple-default;
                            }
                        }
                        rect,
                        circle {
                            fill: $purple-default;
                        }
                    }
                    &.svg-sources-default,
                    &.svg-name {
                        svg {
                            path:not(:first-child) {
                                fill: $purple-default;
                            }
                        }
                    }
                }
            }
        }
    }
    .items:not(.dropbtn):not(.item-location):not(.tag):not(.item-deleted) {
        @include flexcenter;
        min-height: 32px;
        padding: 4px 10px;
        margin-top: 4px;
        cursor: pointer;
        &:not(.svg-star) {
            svg {
                margin-right: 8px;
            }
        }
    }
    .sub-title {
        font-size: 12px;
        text-transform: uppercase;
    }
    .sub-title,
    .customer-title {
        padding-left: 12px;
    }
    .border-line {
        border-top: 1px solid $border-color-grey;
        margin: 12px;
    }
    .sidebar-title {
        padding: 0px 8px 12px;
        color: $black-2;
    }
}
// New style sidebar pages
.sidebar-left {
    .sidebar-items {
        background: light-dark(#ffffff99,#181a1b99);
        border-radius: 4px;
        cursor: pointer;
        padding: 4px;
        width: 100%;
        &:hover {
            background: $btn-hover;
        }
        &:active,
        &.active {
            background: $yellow-light;
            box-shadow: $boxshadow-grey-softlight;
        }
        svg {
            flex: 0 0 auto;
        }
    }
}
.sidebar-menu {
    padding: 0 4px 16px 0;
    width: 240px;
    &.is-hide-sidebar {
        width: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        border-right: 0;
        overflow: hidden;
        opacity: 0;
    }
    &__nav {
        gap: 4px;
    }
    .sidebar-items {
        color: $black-verylight-green;
        height: 32px;
        padding: 6px;
        &.active {
            color: $grey-extradark;
            @extend .fw-600;
        }
        .title {
            .tooltiptext {
                max-width: 180px;
            }
        }
    }
}
