.container-print {
    position: relative;
    @include flexcolumns;
    &:not(.contents-pages) {
        @include size(100%);
    }
    &.has-tab {
        .tab-contents:not(.tabs-contents) {
            overflow-y: auto;
            flex: 1;
            .tab-conts,
            .tables {
                height: 100%;
            }
        }
    }
    &.--hasfooter {
        padding-bottom: 64px;
    }
    .tables {
        color: $black;
        font-size: 13px;
        flex: 1;
        overflow-y: auto;
        position: relative;
        &.--revenue {
            .tables-list {
                > .rows {
                    > .col:not(.--total):not(.--empty) {
                        color: $violet-name;
                    }
                }
            }
            .price-btn {
                cursor: pointer;
                border-radius: 4px;
                margin: auto -5px auto auto;
                padding: 1px 5px;
                word-break: break-word;
                &.selected {
                    color: $white-default;
                    background-color: $background-title;
                }
            }
        }
        &.--material {
            &.table-virtual {
                min-width: fit-content;
            }
            .title-material {
                padding: 20px 16px 10px;
                background-color: $white;
                @extend .fw-600;
                @include font-color(20px, 14px, $black-2);
                & + .--header {
                    border-top: 1px solid $border-color-grey;
                }
            }
            .tables-list {
                min-height: calc(100% - 87px);
            }
        }
        &.--forecast {
            .rows {
                > .col {
                    &:not(.col-md-plus):not(.col-xl) {
                        min-width: 110px;
                    }
                }
                @media only screen and (min-width: 1180px) {
                    &.--total {
                        .col:nth-child(-n + 1) .col-label {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
        &:not(.--forecast) {
            @media only screen and (min-width: 1180px) {
                .rows.--total {
                    .col:nth-child(-n + 2) .col-label {
                        white-space: nowrap;
                    }
                }
            }
        }
        &.table-leads {
            .rows {
                &:nth-last-child(-n + 10) {
                    .search-result {
                        top: auto;
                        bottom: calc(100% + 2px);
                    }
                }
            }
            .dropdown-assign {
                > .v2-dropdown__menu {
                    min-width: 230px;
                    .has-icon {
                        .txt-ellipsis {
                            color: $violet-name;
                            font-weight: 500;
                        }
                    }
                }
                .search-result {
                    width: 100% !important;
                }
                .has-form {
                    padding: 9px 16px 16px;
                    border-bottom: 1px solid $border-color-grey;
                }
                .scrolls {
                    max-height: 210px;
                }
                .user-result {
                    z-index: 1;
                }
            }
        }
        &.table-multi-column {
            .rows {
                > .col {
                    width: 90px;
                    min-width: 90px;
                    flex-basis: 90px;
                    &.col-sm {
                        width: 105px;
                    }
                    &.col-md {
                        width: 130px;
                        min-width: 130px;
                    }
                }
            }
        }
        &.has-checkbox {
            .rows {
                padding-left: 45px;
                &.--total {
                    .col:nth-child(2) {
                        position: relative;
                        left: -28px;
                    }
                }
                &:not(.--inmodal) {
                    .col {
                        &.--checkbox {
                            position: absolute;
                            left: 0px;
                            .check-radio__label {
                                display: block;
                                margin-top: 1px;
                            }
                        }
                    }
                }
            }
        }
        .tables-list {
            min-width: 100%;
            &.--hastotal {
                min-height: calc(100% - 73px);
            }
            .rows {
                .col {
                    &.--empty {
                        color: $background-circle;
                        pointer-events: none;
                        .available {
                            color: $background-circle;
                        }
                    }
                    &.--nodata {
                        pointer-events: none;
                        &::after {
                            content: '-';
                            color: $grey-soft;
                        }
                    }
                }
                .check-items {
                    margin-top: -3px;
                }
            }
        }
        .info-card {
            .card-number {
                height: 22px;
            }
            & + .info-card {
                margin-top: 2px;
            }
        }
        .status-btn:not(.has-icon) {
            height: auto;
            width: fit-content;
            line-height: 12px;
            text-align: left;
            word-break: break-word;
            padding: 4px;
            max-width: 100%;
            & + .status-btn {
                margin-top: 2px;
            }
            .tooltiptext {
                max-width: 400px;
            }
            &:not(.tooltip) {
                @include text-ellipsis;
            }
        }
        &:not(.--material) {
            .tables-list {
                &:not(.--hastotal) {
                    min-height: calc(100% - 37px);
                }
            }
        }
        .currency {
            position: relative;
            color: $violet-name;
            &.active {
                .currency__list {
                    display: block;
                }
                .currency__items {
                    svg {
                        @include transform(rotateZ(180deg));
                    }
                }
            }
            &__items {
                @include flexend;
                cursor: pointer;
                svg {
                    margin-left: 2px;
                }
                .txt-ellipsis {
                    width: calc(100% - 26px);
                }
            }
            &__list {
                display: none;
                background: $white;
                border: solid 1px $violet-name;
                border-radius: 4px;
                padding: 2px;
                min-width: 90px;
                max-width: 120px;
                max-height: 20vh;
                height: auto;
                overflow-y: auto;
                z-index: 1;
                .items {
                    @include flexend;
                    height: 32px;
                    cursor: pointer;
                    border-radius: 4px;
                    padding: 0 4px;
                    & + .items {
                        margin-top: 2px;
                    }
                    &:hover {
                        background-color: $purple-hover;
                        color: $white;
                    }
                }
            }
        }
        .valid-phone {
            line-height: 18px;
            margin: 1px 0;
            & + .valid-phone {
                margin-top: 2px;
            }
            &__number {
                font-size: 13px;
                flex: none;
                max-width: 80%;
                &:not(:has(+ .valid-phone__btn)) {
                    max-width: 100%;
                }
            }
        }
        &.table-time-tracker {
            .tables-list {
                .rows {
                    cursor: pointer;
                    .col {
                        font-weight: 500;
                        &.col-clock,
                        &.col-duration,
                        &.col-sm {
                            @extend .fw-600;
                        }
                        &.col-duration {
                            .col-label {
                                color: $blue-default;
                            }
                            &.--overlap {
                                .col-label {
                                    color: $red-default;
                                }
                            }
                        }
                    }
                }
            }
            .rows {
                .col-lg {
                    min-width: 250px !important;
                }
            }
        }
        // Table Custom Field
        &.custom-field {
            .rows {
                .col {
                    &:not(.--checkbox):not(.--menu):not(.col-value) {
                        max-width: 250px;
                    }
                    &.--menu {
                        max-width: 36px;
                        justify-content: flex-start;
                        cursor: grab;
                    }
                    &:first-child {
                        padding-left: 16px;
                    }
                    @media (max-width: 1180px) {
                        &.col-value {
                            max-width: unset !important;
                        }
                    }
                }
                &:not(:has(.--checkbox)) {
                    padding-left: 0;
                }
            }
            .tables-list {
                .rows {
                    background-color: $white;
                    &.has-drag {
                        border: 0.5px dashed $black-2;
                        background: $black-verydark;
                        opacity: 0.5;
                        min-height: 36px;
                        &[draggable] * {
                            opacity: 0.8;
                            user-select: none;
                        }
                    }
                }
            }
        }
        // Text ellipsis
        &.has-text-ellipsis {
            .rows:not(.--header):not(.--total) {
                .col-label,
                .col > .word-break,
                .name {
                    @include text-ellipsis;
                }
            }
        }
    }

    .tables-header,
    .tables-total,
    .tables {
        .rows {
            @include flextop;
            justify-content: space-between;
            box-shadow: inset 0px -1px 0px $border-color-grey;
            column-gap: 5px;
            min-height: 28px;
            min-width: fit-content;
            overflow: hidden;
            .--label-r {
                text-align: right;
                justify-content: flex-end;
            }
            &.--fixed {
                position: sticky;
                z-index: 1;
            }
            &.--header {
                background-color: $grey-extralight;
                box-shadow: 0px 1px 3px $black-verydark, 0px 1px 2px $black-light, 0px 0px 1px $black-extradark;
                font-family: "Inter Bold", sans-serif;
                text-transform: capitalize;
                inset-block-start: 0;
                align-items: center;
                .check-items {
                    margin-top: -10px;
                }
                & + .--total {
                    z-index: auto !important;
                }
                &:has(.has-orderby) {
                    .col {
                        padding-top: 4px;
                        padding-bottom: 4px;
                    }
                }
                .has-orderby {
                    width: 100%;
                }
                .orderby-txt,
                .col-label {
                    @include text-ellipsis;
                    white-space: nowrap !important;
                }
                .--label-r {
                    .orderby-txt {
                        flex: 1;
                    }
                }
            }
            &.--total {
                @extend .fw-600;
                inset-block-end: 0;
                .col {
                    &.--total {
                        .col-label {
                            white-space: normal !important;
                        }
                    }
                    &.col-total {
                        .col-label {
                            text-overflow: unset;
                            white-space: normal;
                        }
                    }
                }
                & + .tables-list {
                    min-height: calc(100% - 108px);
                }
            }
            > .col {
                padding: 8px 0;
                line-height: 18px;
                box-sizing: content-box;
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 90px;
                min-width: 70px;
                word-break: break-word;
                &:first-child {
                    padding-left: 16px;
                }
                &:last-child {
                    padding-right: 10px;
                }
                .col-label,
                .name,
                .word-break {
                    white-space: normal;
                    word-break: break-word;
                    margin: 1px 0;
                }
                .name {
                    color: $purple-default;
                    cursor: pointer;
                    margin: 1px 0;
                }
                &.--checkbox {
                    width: 45px;
                    min-width: 45px;
                    padding-right: 0;
                    box-sizing: border-box;
                }
                .col__customer {
                    @include flextop;
                    width: 100%;
                    .name,
                    .col-label {
                        flex: 1;
                    }
                    & + .col__customer {
                        margin-top: 3px;
                    }
                }
                &.--menu {
                    @include centeritem;
                    max-width: 32px;
                    min-width: 32px;
                    padding: 6px 0;
                    > .v2-dropdown {
                        @include size(24px);
                        > .dropbtn {
                            border-radius: 8px;
                        }
                        .v2-dropdown__menu {
                            width: 165px;
                            right: 0;
                            .items {
                                font-weight: 500;
                            }
                        }
                    }
                }
                &.--card {
                    min-width: 160px;
                    &:has(.info-card) {
                        padding: 7px 0;
                        &:last-child {
                            padding-right: 10px;
                        }
                    }
                    .col-label:empty {
                        margin: 0;
                    }
                }
                &.--rating {
                    min-width: 140px;
                    max-width: 160px;
                }
                &.--tags {
                    min-width: 195px;
                    max-width: 230px;
                    .tag-label {
                        width: inherit;
                        min-width: fit-content;
                        margin: 1px 2px 2px 0;
                        min-height: auto;
                        line-height: 16px;
                        background-color: $black-verydark;
                    }
                    .content-checked__all {
                        .tag-label {
                            min-width: auto;
                        }
                    }
                }
                &.col-email {
                    min-width: 150px;
                }
                &.col-phone {
                    min-width: 150px;
                }
                &.col-note {
                    min-width: 155px;
                }
                &.col-xs {
                    max-width: 105px;
                    min-width: 65px;
                    flex-basis: 65px;
                    display: flex;
                }
                &.col-sm {
                    flex-basis: 105px;
                    max-width: 105px;
                    min-width: 85px;
                }
                &.col-sm-plus {
                    max-width: 120px;
                    min-width: 105px;
                    flex-basis: 120px;
                }
                &.col-md {
                    min-width: 120px;
                    max-width: 150px;
                }
                &.col-md-plus {
                    max-width: 150px;
                    min-width: 135px;
                }
                &.col-lg {
                    min-width: 155px;
                }
                &.col-xl,
                &.col-xl-plus {
                    min-width: 180px;
                }
                &.col-icon {
                    min-width: 36px;
                    max-width: 36px;
                    text-align: center;
                    margin-top: -2px;
                }
                &.col-chart {
                    @include centeritem;
                    cursor: default;
                }
                &.col-status {
                    min-width: 150px;
                    max-width: 180px;
                    .status-btn {
                        &:has(.cursor-pointer) {
                            display: flex;
                            max-width: 100%;
                            .cursor-pointer {
                                padding-left: 4px;
                                flex: 1;
                                @include text-ellipsis;
                            }
                        }
                        &.available-other,
                        &.available {
                            @include text-ellipsis;
                            width: max-content;
                            max-width: 100%;
                        }
                    }
                }
                &.col-frequency,
                &.col-address {
                    min-width: 180px;
                    display: flex;
                    .col-label:first-child {
                        & + .col-label {
                            flex: 1;
                            padding-left: 6px;
                        }
                        &:empty + .col-label {
                            padding-left: 0;
                        }
                    }
                }
                &.col-address {
                    min-width: 200px;
                    &:has(.col-label + .col-label) {
                        .col-label {
                            &:last-child {
                                position: relative;
                                &::before {
                                    content: ',';
                                    position: absolute;
                                    left: 0px;
                                }
                            }
                            &:empty + .col-label {
                                &::before {
                                    content: none;
                                }
                            }
                        }
                    }
                }
                &.col-total {
                    min-width: 140px;
                    max-width: 140px;
                }
                &.col-date {
                    min-width: 190px;
                    max-width: 190px;
                }
                &.col-invoice {
                    min-width: 220px;
                    max-width: 250px;
                    display: flex;
                    .col-label {
                        flex: 1;
                    }
                }
                &.col-job-status {
                    min-width: 155px;
                    max-width: 230px;
                    flex-wrap: wrap;
                    .status-btn {
                        margin-top: 0;
                        @include text-ellipsis;
                    }
                }
                .wrap-tag {
                    @include flexwrap;
                    &__label {
                        margin-right: 3px;
                    }
                    & + .wrap-tag {
                        margin-top: 4px;
                    }
                }
                .available {
                    color: $green-default;
                }
                .v2-dropdown {
                    &.active {
                        z-index: 2;
                        .dropbtn {
                            background-color: $white;
                        }
                    }
                }
                > .v2-btn-default {
                    font-size: 12px;
                    font-weight: normal;
                    &:not(.dropbtn):not(.btn-plus) {
                        height: 20px;
                        width: max-content;
                    }
                    &.--transparent:hover {
                        border-color: $transparent !important;
                    }
                }
                .credit-stt {
                    text-transform: uppercase;
                    color: $orange-default;
                    margin-left: 3px;
                }
                &.col-user {
                    min-width: 195px;
                    max-width: 250px;
                    .user-name {
                        @include flexcenter;
                        overflow: unset !important;
                    }
                }
                &.col-duration {
                    max-width: 100px;
                    min-width: 100px;
                }
                &.col-clock {
                    max-width: 165px;
                    min-width: 165px;
                }
                &.col-custom-field,
                &.col-job {
                    padding-right: 5px;
                    .has-orderby {
                        width: 100%;
                        .orderby-txt {
                            @include text-ellipsis;
                        }
                    }
                }
                &.col-custom-field {
                    min-width: 160px;
                }
                &.col-job {
                    min-width: 220px;
                }
            }
        }
        .avt-img:not(.--large):not(.--lg),
        .avt:not(.--large):not(.--lg) {
            margin-right: 4px;
            margin-top: -1px;
            @include size(22px);
            img {
                @include size(22px);
            }
        }
        .has-orderby {
            @include flexinline;
            width: max-content;
        }
        .has-orderby .txt-ellipsis,
        .has-orderby {
            .orderby-icons {
                height: 28px;
                .icon {
                    cursor: pointer;
                    height: 14px;
                    overflow: hidden;
                    > svg {
                        path {
                            fill: $grey-middle;
                        }
                    }
                    &:hover {
                        svg path {
                            fill: $grey-dark;
                        }
                    }

                    &.--desc {
                        @include transform(rotateX(180deg));
                    }
                }
            }
            &.desc {
                .orderby-icons {
                    .icon.--desc {
                        cursor: default;
                        svg path {
                            fill: $grey-dark;
                        }
                    }
                }
            }
            &.asc {
                .orderby-icons {
                    .icon.--asc {
                        cursor: default;
                        svg path {
                            fill: $grey-dark;
                        }
                    }
                }
            }
        }
        .has-orderby:not(:has(.txt-ellipsis)) {
            .orderby-icons {
                margin-left: 3px;
            }
        }
        .tables-list {
            .col-note {
                & > * {
                    display: none;
                    &:first-child {
                        display: block;
                    }
                }
                img {
                    height: 40px;
                    object-fit: cover;
                }
                &:not(:has(> .content)) {
                    max-height: 50px;
                    &,
                    & > *:first-child {
                        @include text-line-clamp(3);
                    }
                }
            }
            & + .--total {
                box-shadow: inset 0px 1px 0px $border-color-grey;
                background-color: $white;
            }
        }
        // Table has line
        .wrap-rows {
            &:nth-child(odd) {
                .rows {
                    background: $bg-body;
                }
            }
            .rows {
                position: relative;
                overflow: unset;
                & > .line {
                    border-left: solid 1px $grey-american-silver;
                    left: 24px;
                    top: 14px;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                }
                &:not(:last-child) {
                    box-shadow: none;
                }
                &:last-child {
                    box-shadow: inset 0px -1px 0px $border-color-grey !important;
                    & > .line {
                        display: none;
                    }
                }
                .col {
                    white-space: normal;
                    &.--checkbox {
                        z-index: 2;
                        .line {
                            position: absolute;
                            height: 20px;
                            width: 18px;
                            left: 24px;
                            top: -1px;
                            border-bottom: solid 1px $grey-american-silver;
                            border-left: solid 1px $grey-american-silver;
                            border-bottom-left-radius: 6px;
                        }
                    }
                }
            }
        }
    }

    .table-recurring,
    .table-subscription {
        .v2-dropdown {
            &.active {
                z-index: unset !important;
                .v2-dropdown__menu {
                    z-index: 1001;
                }
            }
        }
    }

    .wrap-tables-fixed {
        position: relative;
        @include flexcolumns;
        overflow: hidden;
        color: $black;
        height: 100%;
        .container_div_id:empty {
            @include size(100%);
            border-left: 1px solid $border-color-grey;
        }
        .has-orderby {
            flex-direction: column;
            align-items: flex-start;
        }
        .user-name {
            @include flexcenter;
            line-height: 22px;
        }
        .avt-img,
        .avt {
            margin-right: 4px;
            margin-top: -1px;
            @include size(22px);
            img {
                @include size(22px);
            }
        }
        .tables-header,
        .rows.--header,
        .tables-content {
            background-color: $white-lotion;
        }
        .tables-header,
        .rows.--header {
            align-items: flex-start;
        }
        .rows {
            column-gap: unset;
            .col {
                .txt-ellipsis {
                    white-space: nowrap;
                }
            }
        }
        .tables-header,
        .tables-total {
            @include flextop;
            .table-fixed {
                height: 100%;
                overflow: hidden;
                border-left: 1px solid $border-color-grey;
            }
        }
        .tables-header {
            box-shadow: 0px 1px 3px $black-verydark, 0px 1px 2px $black-light, 0px 0px 1px $black-extradark;
            font-weight: bold;
            z-index: 2;
            .table-fixed {
                .rows {
                    box-shadow: none;
                    .col {
                        line-height: 20px;
                    }
                }
            }
            .list-user {
                height: 56px;
                .has-orderby .icon {
                    line-height: 20px;
                }
            }
        }
        .tables-total {
            box-shadow: inset 0px 1px 0px $border-color-grey;
            background: $white;
            .list-user {
                height: 36px;
            }
            .col-fixed {
                @extend .fw-600;
                inset-block-end: 0;
            }
        }
        .tables-content {
            @include flextop;
            @include size(100%);
            overflow-y: overlay;
            .tables-list {
                min-height: 100%;
                .col-label {
                    line-height: 20px;
                }
            }
            .list-user-scroll {
                overflow-x: scroll;
            }
            .list-user,
            .tables {
                height: 100%;
                overflow-y: auto;
            }
            .table-fixed {
                border-left: 1px solid $border-color-grey;
            }
            .rows {
                background-color: $white;
                height: 38px;
            }
        }
        .col-fixed {
            padding: 8px 8px 8px 16px;
            line-height: 18px;
            box-sizing: content-box;
        }
        .list-user {
            flex: 1;
            max-width: 20%;
            min-width: 250px;
            .rows {
                box-shadow: inset 0px -1px 0px $border-color-grey;
            }
            &::-webkit-scrollbar {
                width: 0;
            }
        }
        .table-fixed {
            .rows {
                .col {
                    border-right: 1px solid $border-color-grey;
                    padding: 8px;
                    position: relative;
                    &:first-child {
                        padding-left: 8px;
                    }
                    &:last-child {
                        padding-right: 10px;
                    }
                    &.col-lg {
                        width: 195px;
                        min-width: 230px;
                    }
                    &.--empty {
                        .col-label {
                            color: $background-circle !important;
                        }
                    }
                }
            }
            .tables-list {
                .rows:last-child {
                    box-shadow: inset 0px -1px 0px $border-color-grey;
                }
            }
        }
        &.is-shadown {
            .list-user {
                box-shadow: 0px 0px 1px 0px $black-darker6, 0px 1px 2px 0px $black-light, 0px 2px 6px 0px $black-verydark;
                z-index: 1;
            }
            .tables-header {
                box-shadow: none;
                .table-fixed .rows {
                    box-shadow: inset 0px -1px 0px $border-color-grey;
                }
                .list-user {
                    height: 55px;
                }
            }
        }
    }

    .table-virtual {
        .tables-list {
            .rows {
                &:first-child {
                    &:has(.valid-phone__number .tooltiptext) {
                        z-index: 1 !important;
                    }
                    .valid-phone__number {
                        .tooltiptext {
                            top: calc(100% + 2px);
                            bottom: unset;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1180px) {
        .tables {
            &:not(.--forecast):not(.table-time-tracker) {
                .rows {
                    > .col {
                        &:not(.--device):not(.--card):not(.--tags):not(.--menu):not(.--checkbox):not(.--rating):not([class*='col-']) {
                            min-width: 100px;
                            max-width: 200px;
                        }
                        &.col-xs {
                            min-width: 65px !important;
                            max-width: 200px;
                        }
                        &.col-lg {
                            min-width: 170px !important;
                            max-width: 200px;
                        }
                        &.col-sm {
                            min-width: 115px !important;
                            max-width: 200px;
                        }
                        &.col-sm-plus {
                            min-width: 125px !important;
                            max-width: 200px;
                        }
                        &.col-md {
                            min-width: 140px !important;
                            max-width: 200px;
                        }
                        &.col-md-plus {
                            min-width: 155px !important;
                            max-width: 200px;
                        }
                        &.col-xl {
                            min-width: 190px !important;
                        }
                        &.col-xl-plus {
                            min-width: 210px !important;
                        }
                    }
                }
            }
        }
    }
}

.container-wrap {
    &:not(.custom-grid-sidebar):not(.addons-wrap):not(.container-chatbot) {
        @include flexcolumns;
    }
    &:not(.addons-wrap:not(:has(.custom-field))) {
        .header {
            @include flexwrap;
            align-items: flex-start;
            gap: 4px;
            &__left {
                @include flexwrap;
                align-items: flex-start;
                min-width: max-content;
                gap: 4px;
            }
            .header-label {
                font-size: 18px;
                line-height: 32px;
            }
            &.--main {
                padding: 16px 16px 16px 0;
                justify-content: flex-end;
            }
            &.--filter {
                padding: 8px 16px;
                box-shadow: inset 0 -1px 0 $border-color-grey;
            }
            .line-outstanding {
                text-align: right;
                height: 32px;
            }
            .header-items {
                &:not(.list-menu) {
                    .v2-dropdown__menu {
                        min-width: 100%;
                        max-width: 300px !important;
                    }
                }
                &.check-items {
                    color: $grey-generic;
                    line-height: 32px;
                    min-width: 115px;
                    .item-checkbox {
                        height: auto;
                    }
                }
                .dropbtn {
                    .budget {
                        @media (min-width: 1200px) {
                            max-width: 8vw;
                        }
                        @media (min-width: 767px) {
                            max-width: 10vw;
                        }
                    }
                }
                .v2-dropdown__menu {
                    z-index: 999;
                    width: max-content;
                }
                .header-search {
                    .search-input input {
                        border: solid 1px $border-color-grey;
                    }
                }
            }
            .btn-limit,
            .export {
                height: 32px;
                &__option {
                    background: rgba($color: $blue-default, $alpha: 0.06);
                    border: solid 1px rgba($color: $blue-default, $alpha: 0.5);
                    color: $blue-default;
                    cursor: pointer;
                    padding: 3px 6px;
                    &:hover {
                        text-decoration: underline;
                    }
                    &.--left {
                        border-radius: 4px 0 0 4px;
                        border-width: 1px 0 1px 1px;
                    }
                    &.--right {
                        border-radius: 0 4px 4px 0;
                    }
                }
            }
            .wrap-process {
                width: 100%;
                margin: 10px 16px 0px;
                display: none;
            }
            .v2-dropdown {
                background-color: $white;
                border-radius: 8px;
            }
            .react-datepicker__tab-loop {
                margin-left: -4px;
            }
        }
        .sidebar-pages {
            padding: 8px;
        }
    }
    &.container-table {
        .title-header,
        .header-title {
            padding: 0 16px;
        }
        .wrap-process {
            margin: 8px;
        }
    }
    .wrapper-columns {
        @include flextop;
        overflow-y: auto;
        height: 100%;
        .contents-pages {
            height: 100%;
            flex: 1;
        }
    }
    .wrap-tables {
        @extend .wrap-border;
        overflow-y: auto;
        height: 100%;
        border-radius: 8px 8px 0 0 !important;
        .rows {
            &.--header {
                background-color: $grey-extralight;
                box-shadow: 0px 1px 3px $black-verydark, 0px 1px 2px $black-light,
                    0px 0px 1px $black-extradark;
            }
        }
        .tables-list {
            .rows {
                &:last-child {
                    box-shadow: none;
                }
            }
        }
        .v2-dropdown {
            .tables:first-child {
                border-radius: 8px 8px 0 0;
            }
        }
        .table-title {
            border-bottom: 1px solid $border-color-grey;
            padding: 16px;
        }
    }
    .has-chart {
        .wrap-tables {
            overflow: unset;
            min-height: max-content;
            height: fit-content;
            flex: 1;
            .tables {
                overflow: unset;
            }
            .header {
                &.--filter {
                    .v2-dropdown.active {
                        .v2-dropdown__menu {
                            z-index: 1002 !important;
                        }
                    }
                    @media (min-width: 1200px) {
                        z-index: 1002;
                    }
                }
            }
        }
        .contents-pages {
            overflow: auto;
            padding-right: 16px;
            min-width: fit-content;
        }
        .tab-contents {
            flex: 1;
        }
        .tab-conts {
            &,
            .tables {
                height: 100%;
            }
        }
        .rows {
            .valid-phone__number {
                .tooltiptext {
                    z-index: 1002;
                }
            }
            &.--footer {
                z-index: auto !important;
            }
            &.--total {
                z-index: auto !important;
            }
            &.--header {
                z-index: 1001;
            }
            .dropdown-assign.active {
                z-index: unset !important;
                .v2-dropdown__menu {
                    z-index: 1002 !important;
                }
            }
        }
    }
    .has-total {
        .wrap-tables {
            border-bottom: 0;
        }
    }
}

.custom-grid-sidebar {
    @include grid(auto auto 1fr);
    grid-template-areas:
        'bookmark bookmark'
        'header header'
        'sidebar table';
    &:not(.is-hide-panel) {
        grid-template-columns: 240px 1fr;
    }
    &.is-hide-panel {
        grid-template-columns: 0 1fr;
        @include transitions(all 0.2s);
    }
    .wrapper-columns {
        grid-area: table;
    }
    .header.--main {
        grid-area: header;
    }
    .sidebar-left,
    .sidebar-pages {
        grid-area: sidebar;
    }
    .job-group {
        grid-area: bookmark;
    }
}

.tables-empty {
    margin: 24px auto;
    padding: 24px 16px;
    background: $btn-disable;
    border-radius: 16px;
    word-break: break-word;
    text-align: center;
    @include font-color(20px, 14px, $grey-dark);
}
.content-empty {
    background: $white-cultured;
    color: $black-darklight;
    padding: 16px 12px;
}
// Need remove apply new UI bg grey for Setting pages
.container-setting,
.container-customer-list {
    .header {
        &.--main {
            box-shadow: inset 0 -1px 0 $border-color-grey;
            padding-left: 16px !important;
        }
    }
    .rows {
        &.--header {
            background-color: $white !important;
        }
    }
    .tables-list:not(:has(.wrap-rows)) {
        .rows {
            &:nth-child(odd) {
                background: $bg-body;
            }
        }
    }
}
.container-commision {
    padding-left: 0;
    .container-print {
        padding: 0;
    }
}
// End - Need remove apply new UI bg grey for Setting pages