@import "mixins";
.splash {
    @include size(100%);
    position: relative;
    padding: 0px;
    .wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        &__loader {
            @include animations(rotation 1.5s infinite linear);
            svg {
                @include size(50px);
            }
        }
    }
}
@keyframes rotation {
    from {
        @include transform(rotate(0deg));
    }

    to {
        @include transform(rotate(360deg));
    }
}
