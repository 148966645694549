@import 'src/assets/css/common/variables';
@import 'src/assets/css/common/mixins';

.wrap-collapse {
    &:not(.is-open) {
        .arrow {
            @include transform(rotateZ(180deg));
        }
        .wrap-collapse__details {
            display: none;
        }
    }
}
.wrap-note {
    border-bottom: 1px solid $border-color-grey;
    &.is-location {
        &:hover {
            background: $grey-verylight-brown;
        }
    }
    &.is-topnote {
        background: #ffbb001a;
        &:hover {
            background: rgba(255, 187, 0, 0.2);
        }
    }
    &.is-jobnote {
        background: $blue-alice-light;
        &:hover {
            background: light-dark(#e8eef4, #222426);
        }
    }
    .note-header {
        cursor: pointer;
        min-height: 20px;
        padding: 8px 16px;
    }
    .note-details {
        padding: 0 16px 8px;
        word-break: break-word;
    }
}
