.switch {
    @include flexcenter;
    &__label {
        margin-right: 4px;
        color: $grey-greenish;
        &.--active {
            color: $purple-default;
        }
    }
    .toggle {
        position: absolute;
        visibility: hidden;
        & + label {
            display: block;
            position: relative;
            cursor: pointer;
            outline: none;
            user-select: none;
        }
    }
    .toggle-round {
        & + label {
            width: 26px;
            height: 16px;
            border-radius: 8px;

            &:before,
            &:after {
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                top: 1px;
                left: 1px;
                bottom: 1px;
                content: '';
            }

            &:before {
                background-color: $grey-middle;
                border-radius: 8px;
            }

            &:after {
                width: 12px;
                height: 12px;
                background-color: $white-default;
                border-radius: 6px;
                top: 3px;
                left: 3px;
            }
        }

        &:checked + label:before {
            background-color: $purple-default;
        }

        &:checked + label:after {
            margin-left: 12px;
            left: auto;
        }
    }
    &.large {
        .toggle-round {
            & + label {
                width: 32px;
                height: 20px;
                border-radius: 7px;

                &:after {
                    width: 16px;
                    height: 16px;
                    border-radius: 6px;
                }
            }
            &:checked + label {
                &:after {
                    margin-left: 15px;
                    left: auto;
                }

                .onoff:after {
                    content: 'On';
                }

                .onoff.purple:after {
                    color: $violet-name;
                }

                .hideshow:after,
                .showhidden:after {
                    content: 'Show';
                    color: $violet-name;
                }

                .enable-disable:after {
                    content: 'Enabled';
                    color: $violet-name;
                }

                .same:after {
                    content: 'Same';
                    color: $violet-name;
                }
                .yesno:after {
                    content: 'Yes';
                    color: $violet-name;
                }
                .in-active:after {
                    content: 'Active';
                    color: $violet-name;
                }
            }
        }
    }
    .onoff {
        position: absolute;
        top: 2px;
        right: calc(100% + 6px);
        font-weight: 400;

        &:after {
            content: 'Off';
        }
    }
    .hideshow {
        &:after {
            content: 'Hide';
            color: $grey-greenish;
        }
    }
    .enable-disable {
        position: absolute;
        top: 2px;
        right: calc(100% + 6px);

        &:after {
            content: 'Disabled';
            color: $grey-greenish;
        }
    }
    .same {
        position: absolute;
        left: -43px;
        top: 50%;

        @include transform(translateY(-50%));

        &:after {
            content: 'Same';
            color: $date-color;
        }
    }
    .in-active {
        position: absolute;
        top: 1px;
        left: calc(100% + 10px);

        &:after {
            content: 'Inactive';
            color: $grey-greenish;
        }
    }
    .showhidden {
        &:after {
            content: 'Hidden';
            color: $grey-extrlight;
        }
    }
}
// switch with pure html
.switch-icon {
    border-radius: 118px;
    height: 20px;
    width: 32px;
    position: relative;
    &.has-toggle {
        background: $purple-default;
        .switch-icon__dots {
            right: 2px;
        }
    }
    &:not(.has-toggle) {
        background: $grey-middle;
        .switch-icon__dots {
            left: 2px;
        }
    }
    &__dots {
        @include size(16px);
        background: $white-default;
        border-radius: 50%;
        display: inline-block;
        top: 2px;
        position: absolute;
    }
}
