// Text color
@each $color, $value in $colors {
    .#{$color} {
        color: $value !important;
    }
}
// Background color
@each $color, $value in $colors {
    .bg-#{$color} {
        background-color: $value !important;
    }
}
// Border color
@each $color, $value in $colors {
    .border-#{$color} {
        border: 1px solid $value !important;
    }

    .border-top-#{$color} {
        border-top: 1px solid $value !important;
    }

    .border-bottom-#{$color} {
        border-bottom: 1px solid $value !important;
    }

    .border-left-#{$color} {
        border-left: 1px solid $value !important;
    }

    .border-right-#{$color} {
        border-right: 1px solid $value !important;
    }
}
.border-none {
    border: $border-none !important;
}
.shadow-none {
    box-shadow: none !important;
}
.shadow-grey {
    box-shadow: inset 0 1px $grey-light-silver;
}
