@import 'src/assets/css/common/variables';
@import 'src/assets/css/common/mixins';

.container-referral {
    padding: 16px 16px 0;
    gap: 16px;
    .wrap-referral {
        padding: 40px;
        background-color: #000;
        gap: 40px;
        border-radius: 8px;
        @include flextop;
        justify-content: center;
        @media (min-width: 1750px) {
            gap: 6vw;
        }
        @media (max-width: 1100px) {
            padding: 30px;
            gap: 30px;
        }
        .referral-box {
            border-radius: 8px;
            padding: 15px 24px 24px;
            background-color: $white;
            .box-icon {
                @include flexcenter;
            }
            .box-items {
                min-width: 115px;
                flex: 1;
                @include centeritem;
                gap: 4px;
                .is-divider {
                    height: 24px;
                }
                .txt-number {
                    height: 20px;
                }
            }
        }
        .referral-content {
            max-width: 750px;
            &:not(.referral-box) {
                padding-top: 18px;
            }
            @media (max-width: 1300px) {
                width: 50%;
                .box-icon {
                    flex-wrap: wrap;
                }
                .box-items {
                    justify-content: flex-start;
                }
                .is-divider:nth-child(4) {
                    height: 1px;
                    width: 100%;
                    margin: 10px 0;
                    background: $light-brown;
                }
            }
        }
        .field-input {
            border-color: $white;
            color: $black;
            @include text-ellipsis;
            &.is-disable {
                opacity: 1;
            }
        }
        .v2-btn-default:active {
            background-color: $white !important;
        }
        .line {
            border-top: 1px solid $light-brown;
            margin-bottom: 10px;
        }
    }
    .container-print {
        @media (min-height: 720px) {
            overflow-y: auto;
        }
        .wrap-tables {
            .tables {
                .rows {
                    align-items: center;
                    .col-icon {
                        margin-top: 0;
                        padding-right: 20px;
                        svg {
                            @include size(32px);
                        }
                    }
                    @media (max-width: 1180px) {
                        .col {
                            &.col-lg, 
                            &.col-md {
                                min-width: 150px !important;
                                max-width: 150px !important;
                            }
                            &.col-xl {
                                max-width: unset !important;
                            }
                        } 
                    }
                }
            }
            .status-btn {
                &.--trial, 
                &.--active {
                    background-color: $blue-default;
                }
                &.--applied {
                    background-color: $yellow-default;
                }
                &.--expired {
                    background-color: $grey-platinum;
                    color: $granite-gray;
                }
            }
        }
    }
}