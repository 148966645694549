.header-job {
    @include flexcenter;
    border-radius: 2px 2px 0 0;
    font-size: 10px;
    line-height: 10px;
    padding-left: 1px;
    white-space: nowrap;
    background-color: inherit;
    z-index: 1;
    .emoji-content {
        margin-right: 2px;
    }
    .content-job {
        line-height: 12px;
    }
    .icon-svg {
        margin-top: -1px;
        margin-left: -1px;
        &.--lock {
            transform: scale(1.1);
        }
        &.--link {
            transform: rotate(45deg);
        }
    }
    .icon {
        @include size(13px);
        background-color: #fff;
        border-radius: 50%;
        margin-right: 1px;
    }
    .icon-loading {
        margin: 0 8px 0 5px;
        display: none;
    }
    .icon-sms {
        @include size(10px);
        background-color: #00aadd;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        right: 2px;
        z-index: 2;
    }
}

.content-job {
    flex: 1 1 0%;
    padding-left: 1px;
    font-family: Arial, Helvetica, sans-serif;
    overflow: hidden;
    z-index: 1;
    &.--desc {
        line-height: 1.15;
        font-size: 11px;
    }
}

.view-month {
    .content-job:not(.--desc) {
        font-family: 'Inter Bold', sans-serif;
    }
}

.fc-event-jobs {
    @include flexcolumns;
    border-radius: 2px;
    max-height: 100%;
    width: 100%;
    overflow: hidden;
    box-sizing: content-box;
    border: 1px solid;
}

// Size job
.h-35 {
    .fc-timegrid-slot {
        height: 35px !important;
    }
}

.h-25 {
    .fc-timegrid-slot {
        height: 25px !important;
    }
}

.h-15 {
    .fc-timegrid-slot {
        height: 15px !important;
    }
}

.small-tasks {
    .fc-event-jobs {
        height: 32px !important;
    }
}
.regular-tasks {
    .fc-event-jobs {
        height: 67px !important;
    }
}
.large-tasks {
    .fc-event-jobs {
        height: 150px !important;
    }
}

// 15 20 25 30 35 40 45 50
@for $x from 0 through 50 {
    .w-#{$x}:not(.view-twoweek):not(.view-month) {
        .fc-timeline-slot:not(.fc-timeline-slot-label) {
            width: ($x * 1px) !important;
        }
        .fc-timeline-slot-label .fc-timeline-slot-frame {
            width: ($x * 4px) !important;
        }
    }
}

// Header Calendar
.schedules-wrap {
    height: 30px;
    padding: 0 2px;
}
.bg-today {
    background-color: rgba($blue-default, 0.078);
}