.c-error {
    width: 100%;
    min-height: 100%;
    height: max-content;
    background: #f1f5f8;
    justify-content: space-between;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99999999;
    &,
    .container {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .container {
        justify-content: center;
        flex: 1;
        max-width: 1024px;
        margin: 24px;
        &__box {
            width: 485px;
            background: #fff;
            box-shadow: inset 0px 0px 0px 1px #d8d8d8;
            color: #232426;
            padding: 40px;
            border-radius: 4px;
            line-height: 21px;
            .desc {
                &.--number {
                    display: list-item;
                    margin-left: 1em;
                    line-height: 26px;
                }
            }
        }
        @media screen and (max-width: 767px) {
            margin: 16px;
            &__box {
                width: 100%;
            }
        }
        &__wrap {
            margin-top: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            .label {
                margin: 8px 8px 8px 0;
            }
        }
    }
    .title {
        color: #232426;
        font-size: 29px;
        margin: 0;
    }
    .description {
        margin: 24px 0px 50px;
    }
    .v2-btn-default {
        border: 1px solid #c1c9d4;
        border-radius: 4px;
        padding: 4px 8px 4px 4px;
        font-weight: 500;
        height: 32px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:not(.--icon-r) {
            svg {
                margin-right: 4px;
            }
        }
        &.--noborder {
            border-color: transparent;
        }
        &.--icon-r {
            padding: 4px 4px 4px 8px;
            svg {
                margin-left: 4px;
            }
        }
        &:hover {
            border-color: #8c929a;
            background-color: #fff;
        }
    }
    .btn-contact {
        background-color: #fff;
        margin-left: 24px;
    }
}
