// modal job magnet
.modal-job-magnet {
    &.modal {
        @media (max-width: 1024px) {
            position: absolute;
            overflow-x: auto;
            .modal__container {
                min-width: 950px;
            }
        }
    }
    & > .modal__container {
        max-width: 1700px;
        width: 95%;
        height: 912px;
        .body-modal {
            padding: 0;
            max-height: unset;
            & > * {
                min-height: 400px;
            }
        }
        .magnet-sidebar {
            width: 350px;
            @include flexcolumns;
            border-right: 1px solid $border-color-grey;
            height: 100%;
            .v2-dropdown:has(.list-schedule) {
                width: calc(100% - 120px);
                .dropbtn {
                    height: auto;
                    min-height: 32px;
                    padding: 3px 6px 3px 8px;
                    .list-schedule {
                        width: calc(100% - 20px);
                        row-gap: 4px;
                        .schedule-user:nth-child(n + 4) {
                            display: none;
                        }
                    }
                    .schedule-user {
                        max-width: 100%;
                    }
                }
            }
            .v2-dropdown__menu {
                z-index: 5;
                @media (max-height: 650px) {
                    &.scrolls,
                    .scrolls {
                        max-height: 200px;
                    }
                }
                .txt-ellipsis {
                    white-space: nowrap;
                }
            }
            & > * {
                padding: 16px;
            }
            .txt {
                width: 120px;
                line-height: 32px;
            }
            .list-jobs {
                border-top: 1px solid $border-color-grey;
                .job-items {
                    border: 2px solid var(--color-magnet);
                    border-radius: 4px;
                    position: relative;
                    cursor: pointer;
                    &.has-drag {
                        opacity: 0.5;
                    }
                    &.active {
                        box-shadow: 2px 2px 0px 1px $black-dark-charcoal;
                    }
                }
                .box-job {
                    position: relative;
                    border-radius: 4px 4px 0 0;
                    padding-bottom: 2px;
                    &::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: $white-default;
                        opacity: 0.3;
                    }
                    .items-content {
                        position: relative;
                        z-index: 1;
                        padding: 1px 3px;
                        overflow: hidden;
                        &.--header {
                            white-space: nowrap;
                        }
                    }
                }
                &:has(.content-empty) {
                    overflow-x: hidden;
                }
                .content-empty {
                    width: calc(100% + 32px);
                    margin: -16px 0 0 -16px;
                }
            }
        }
        .magnet-container {
            height: 100%;
            & > * {
                height: 100%;
                width: 50%;
            }
            .wrap-calendar {
                border-right: 1px solid $border-color-grey;
            }
            .has-mappin {
                .map-pin {
                    display: block;
                }
            }
            .fc {
                line-height: 1;
            }
            .fc-event-resizer-end {
                bottom: 0;
            }
            .fc-event:has(.prevent-resize) {
                .fc-event-resizer-end {
                    display: none;
                }
            }
        }
        .fc-event-main {
            cursor: pointer;
            &::before {
                content: '';
                position: absolute;
                @include size(100%);
                top: 1px;
                left: 1px;
                background: $white-default;
                opacity: 0.3;
                z-index: 1;
            }
            &:has(.has-mappin),
            &:has(.selected) {
                &::before {
                    height: 100%;
                    top: 2px;
                    left: 2px;
                }
            }
        }
        .fc-col-header-cell-cushion > * {
            background-color: $transparent;
        }
        .fc-day-today {
            background-color: $white;
        }
    }
}
.box-distance {
    padding: 2px 2px 0px;
    color: $white-default;
    background-color: var(--color-magnet);
    z-index: 2;
    font-size: 10px;
    text-align: right;
    .dots::before {
        margin: 0 4px;
        background-color: $white-default;
    }
}
.map-pin {
    position: absolute;
    left: -10px;
    top: -17px;
    z-index: 6;
    span,
    &::after {
        position: absolute;
        left: 50%;
        @include transform(translateX(-50%));
        top: 3px;
        line-height: 13px;
    }
}
.has-mappin {
    position: relative;
    border: none !important;
    &::before {
        @include size(100%);
        content: '';
        left: 0px;
        top: 0px;
        position: absolute;
        border: 2px solid var(--color-magnet) !important;
        z-index: 2;
    }
}
.fc-event-dragging {
    &.fc-event {
        border: 0;
    }
    &.job-items {
        .box-job {
            border: 2px solid var(--color-magnet);
            border-radius: 3px 3px 0 0;
            position: relative;
            padding-left: 2px;
        }
        .box-distance {
            border-radius: 0 0 3px 3px;
            padding: 2px;
        }
        .map-pin {
            left: -8px;
        }
    }
}
.ReactModal__Body--open {
    & > .fc-event-draggable {
        .fc-event-main {
            padding: 0;
        }
        .fc-event-magnet {
            overflow: unset !important;
            & > *:not(.map-pin) {
                overflow: hidden;
            }
        }
    }
}
.calendar-page,
.magnet-container {
    .box-distance {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 15px;
        padding: 1px 2px 1px 4px;
        & > *,
        & {
            line-height: 14px;
        }
    }
    .fc-event-magnet {
        & > * {
            &:not(.btn-magnet):not(.box-distance) {
                padding-left: 3px !important;
            }
            &:first-child {
                padding-top: 2px;
            }
        }
    }
    .btn-magnet {
        position: absolute;
        bottom: 3px;
        left: 4px;
        z-index: 5;
        border: 1px solid $purple-default;
        background-color: $purple-lavender-darker;
        &:active {
            background-color: $purple-lavender-darker !important;
        }
        svg {
            transform: translateX(-1px);
        }
    }
}
.fc-event-main {
    .map-pin {
        top: -16px;
        left: -8px;
        &::after {
            content: attr(data-number);
            z-index: 6;
            color: $white-default;
            font-size: 10px;
            @extend .fw-600;
        }
    }
    &:has(.btn-magnet) {
        z-index: unset;
    }
}

.box-magnet {
    position: relative;
    &:not(:has(.active)),
    &:not(:hover) {
        .box-slider {
            display: none;
        }
    }
    .box-slider {
        position: absolute;
        top: calc(100% + 4px);
        left: 50%;
        @include transform(translateX(-50%));
        background-color: $white;
        z-index: 5;
        box-shadow: 0 0 2px 1px $border-progress;
        padding: 10px;
        border-radius: 4px;
        gap: 4px;
        .slidecontainer {
            position: relative;
            .slider {
                width: 130px;
                cursor: pointer;
            }
        }
        &::before {
            content: '';
            position: absolute;
            height: 5px;
            width: 100%;
            top: -5px;
            left: 0;
            background-color: $transparent;
        }
        .tooltip {
            position: absolute;
            width: calc(100% - 20px);
            left: 10px;
            top: -8px;
        }
        .tooltiptext {
            transform: translateX(- var(--value-slider));
            left: var(--value-slider);
            visibility: hidden;
        }
        .zoom-bar:has(input:hover) {
            .tooltiptext {
                visibility: visible;
            }
        }
    }
}