.auth-page {
    .wrapper {
        background: #f9f9f9;
        display: flex;
        flex-flow: column;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        .logo-text-gorilla {
            padding: 40px 20px 25px;
        }
        .main-login {
            display: flex;
            flex-flow: column;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            flex: 1;
            width: 515px;
            @media screen and (max-width: 610px) {
                width: 95%;
            }
        }
        .gd-form-v2 {
            background-color: #fff;
            border-radius: 8px;
            border: solid 2px #f0f0f0;
            box-sizing: border-box !important;
            opacity: 1;
            width: 100%;
        }
        .gd-login-confirm {
            padding: 15px 0 45px;
            color: #555b64;
            font-size: 13px;
            font-weight: 500;
            text-align: center;
        }
        .gd-login-label {
            color: #8d3eff;
            margin-left: 4px;
            &:hover {
                text-decoration: underline;
                opacity: 0.8;
            }
        }
        .mascot {
            margin-top: 15px;
            flex: 0 0 auto;
        }
    }
}
