.auth-container {
    .banner {
        position: relative;
        width: 100%;
    }
    .canvas {
        position: absolute;
        width: 100vw;
        height: 100vh;
    }
    .container {
        position: absolute;
        width: 100%;
        height: 100vh;
    }
    .container .scene {
        position: fixed;
        width: 104%;
        height: 104%;
        top: -2%;
        left: -2%;
        background-color: #b2c3c5;
    }
    .container .scene .layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .container .scene .layer img {
        position: absolute;
        object-fit: cover;
        overflow: visible;
        width: 100%;
        height: 100%;
    }
    .right-top-corner {
        filter: brightness(1);
        top: 0;
        right: 0;
    }
    .flowers {
        filter: brightness(1);
        bottom: 0;
        right: 0;
    }
    .right-bottom-corner {
        filter: brightness(1);
        bottom: 0;
        right: 0;
    }
    .banana {
        filter: brightness(1);
        bottom: 0;
        right: 0;
    }
    .left-top-corner {
        filter: brightness(1);
        top: 0;
        left: 0;
    }
    .left-bottom-corner {
        filter: brightness(1);
        top: 0;
        left: 0;
    }
    .mitddle-stik {
        filter: blur(1px) brightness(1);
        bottom: 0;
        right: 0;
    }
    .blue-particulars {
        filter: brightness(1);
        bottom: 0;
        right: 0;
    }
    .left-platform {
        filter: blur(1.3px) brightness(1);
        top: 0;
        left: 0;
    }
    .water {
        filter: brightness(1);
        bottom: 0;
        right: 0;
    }
    .right-middle {
        filter: blur(2px) brightness(1);
        top: 0px;
        right: 0;
    }
    .background {
        filter: brightness(1);
        opacity: 1;
        top: 0;
        left: 0;
    }
    .background_right {
        filter: blur(3px) brightness(1);
        opacity: 1;
        top: 0;
        right: 0;
    }
    .noise {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: contrast(1%) brightness(20%);
        opacity: 0.2;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0));
    }
    .form {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .form-wrapper {
        margin: 0;
        position: absolute;
        top: 40%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        border: 1px solid #d9d9d9;
        box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        overflow: hidden;
        background-color: white;
    }
    .gorilla-front-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .gorilla {
        scale: 0.68;
        position: absolute;
        bottom: -5.5%;
        left: 50%;
        margin-right: 0%;
        transform: translate(-75%, 0);
    }
    .particles {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
    }
    .background {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        height: 100%;
        -webkit-mask-image: radial-gradient(white 0%, white 30%, transparent 80%, transparent);
        mask-image: radial-gradient(white 0%, white 30%, transparent 80%, transparent);
    }
    .circle-container {
        position: absolute;
        transform: translateY(-10vh);
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
    }
    .circle-container .circle {
        filter: saturate(1.5);
        width: 100%;
        height: 100%;
        border-radius: 50%;
        mix-blend-mode: screen;
        background-image: radial-gradient(#0091ff, #00ddff 10%, rgba(153, 255, 255, 0) 56%);
        -webkit-animation: fadein-frames 4s infinite, scale-frames 20s infinite;
        animation: fadein-frames 4s infinite, scale-frames 7s infinite;
    }
    @-webkit-keyframes fade-frames {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.7;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes fade-frames {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.7;
        }
        100% {
            opacity: 1;
        }
    }
    @-webkit-keyframes scale-frames {
        0% {
            transform: scale3d(0.4, 0.4, 1);
        }
        50% {
            transform: scale3d(2.2, 2.2, 1);
        }
        100% {
            transform: scale3d(0.4, 0.4, 1);
        }
    }
    @keyframes scale-frames {
        0% {
            transform: scale3d(0.4, 0.4, 1);
        }
        50% {
            transform: scale3d(2.2, 2.2, 1);
        }
        100% {
            transform: scale3d(0.4, 0.4, 1);
        }
    }
    .circle-container:nth-child(1) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-1;
        animation-name: move-frames-1;
        -webkit-animation-duration: 66843ms;
        animation-duration: 66843ms;
        -webkit-animation-delay: 50135ms;
        animation-delay: 50135ms;
    }
    @-webkit-keyframes move-frames-1 {
        from {
            transform: translate3d(23vw, 106vh, 0);
        }
        to {
            transform: translate3d(39vw, -124vh, 0);
        }
    }
    @keyframes move-frames-1 {
        from {
            transform: translate3d(23vw, 106vh, 0);
        }
        to {
            transform: translate3d(39vw, -124vh, 0);
        }
    }
    .circle-container:nth-child(1) .circle {
        -webkit-animation-delay: 1986ms;
        animation-delay: 1986ms;
    }
    .circle-container:nth-child(2) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-2;
        animation-name: move-frames-2;
        -webkit-animation-duration: 66947ms;
        animation-duration: 66947ms;
        -webkit-animation-delay: 42982ms;
        animation-delay: 42982ms;
    }
    @-webkit-keyframes move-frames-2 {
        from {
            transform: translate3d(78vw, 109vh, 0);
        }
        to {
            transform: translate3d(16vw, -114vh, 0);
        }
    }
    @keyframes move-frames-2 {
        from {
            transform: translate3d(78vw, 109vh, 0);
        }
        to {
            transform: translate3d(16vw, -114vh, 0);
        }
    }
    .circle-container:nth-child(2) .circle {
        -webkit-animation-delay: 2474ms;
        animation-delay: 2474ms;
    }
    .circle-container:nth-child(3) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-3;
        animation-name: move-frames-3;
        -webkit-animation-duration: 62509ms;
        animation-duration: 62509ms;
        -webkit-animation-delay: 22136ms;
        animation-delay: 22136ms;
    }
    @-webkit-keyframes move-frames-3 {
        from {
            transform: translate3d(36vw, 105vh, 0);
        }
        to {
            transform: translate3d(35vw, -112vh, 0);
        }
    }
    @keyframes move-frames-3 {
        from {
            transform: translate3d(36vw, 105vh, 0);
        }
        to {
            transform: translate3d(35vw, -112vh, 0);
        }
    }
    .circle-container:nth-child(3) .circle {
        -webkit-animation-delay: 1038ms;
        animation-delay: 1038ms;
    }
    .circle-container:nth-child(4) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-4;
        animation-name: move-frames-4;
        -webkit-animation-duration: 64589ms;
        animation-duration: 64589ms;
        -webkit-animation-delay: 6035ms;
        animation-delay: 6035ms;
    }
    @-webkit-keyframes move-frames-4 {
        from {
            transform: translate3d(77vw, 110vh, 0);
        }
        to {
            transform: translate3d(92vw, -114vh, 0);
        }
    }
    @keyframes move-frames-4 {
        from {
            transform: translate3d(77vw, 110vh, 0);
        }
        to {
            transform: translate3d(92vw, -114vh, 0);
        }
    }
    .circle-container:nth-child(4) .circle {
        -webkit-animation-delay: 914ms;
        animation-delay: 914ms;
    }
    .circle-container:nth-child(5) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-5;
        animation-name: move-frames-5;
        -webkit-animation-duration: 48795ms;
        animation-duration: 48795ms;
        -webkit-animation-delay: 441ms;
        animation-delay: 441ms;
    }
    @-webkit-keyframes move-frames-5 {
        from {
            transform: translate3d(5vw, 101vh, 0);
        }
        to {
            transform: translate3d(85vw, -108vh, 0);
        }
    }
    @keyframes move-frames-5 {
        from {
            transform: translate3d(5vw, 101vh, 0);
        }
        to {
            transform: translate3d(85vw, -108vh, 0);
        }
    }
    .circle-container:nth-child(5) .circle {
        -webkit-animation-delay: 6242ms;
        animation-delay: 6242ms;
    }
    .circle-container:nth-child(6) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-6;
        animation-name: move-frames-6;
        -webkit-animation-duration: 49143ms;
        animation-duration: 49143ms;
        -webkit-animation-delay: 47676ms;
        animation-delay: 47676ms;
    }
    @-webkit-keyframes move-frames-6 {
        from {
            transform: translate3d(67vw, 110vh, 0);
        }
        to {
            transform: translate3d(35vw, -116vh, 0);
        }
    }
    @keyframes move-frames-6 {
        from {
            transform: translate3d(67vw, 110vh, 0);
        }
        to {
            transform: translate3d(35vw, -116vh, 0);
        }
    }
    .circle-container:nth-child(6) .circle {
        -webkit-animation-delay: 5353ms;
        animation-delay: 5353ms;
    }
    .circle-container:nth-child(7) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-7;
        animation-name: move-frames-7;
        -webkit-animation-duration: 48908ms;
        animation-duration: 48908ms;
        -webkit-animation-delay: 27327ms;
        animation-delay: 27327ms;
    }
    @-webkit-keyframes move-frames-7 {
        from {
            transform: translate3d(44vw, 104vh, 0);
        }
        to {
            transform: translate3d(88vw, -118vh, 0);
        }
    }
    @keyframes move-frames-7 {
        from {
            transform: translate3d(44vw, 104vh, 0);
        }
        to {
            transform: translate3d(88vw, -118vh, 0);
        }
    }
    .circle-container:nth-child(7) .circle {
        -webkit-animation-delay: 4416ms;
        animation-delay: 4416ms;
    }
    .circle-container:nth-child(8) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-8;
        animation-name: move-frames-8;
        -webkit-animation-duration: 61231ms;
        animation-duration: 61231ms;
        -webkit-animation-delay: 27958ms;
        animation-delay: 27958ms;
    }
    @-webkit-keyframes move-frames-8 {
        from {
            transform: translate3d(53vw, 110vh, 0);
        }
        to {
            transform: translate3d(27vw, -121vh, 0);
        }
    }
    @keyframes move-frames-8 {
        from {
            transform: translate3d(53vw, 110vh, 0);
        }
        to {
            transform: translate3d(27vw, -121vh, 0);
        }
    }
    .circle-container:nth-child(8) .circle {
        -webkit-animation-delay: 4339ms;
        animation-delay: 4339ms;
    }
    .circle-container:nth-child(9) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-9;
        animation-name: move-frames-9;
        -webkit-animation-duration: 60012ms;
        animation-duration: 60012ms;
        -webkit-animation-delay: 40877ms;
        animation-delay: 40877ms;
    }
    @-webkit-keyframes move-frames-9 {
        from {
            transform: translate3d(89vw, 105vh, 0);
        }
        to {
            transform: translate3d(37vw, -114vh, 0);
        }
    }
    @keyframes move-frames-9 {
        from {
            transform: translate3d(89vw, 105vh, 0);
        }
        to {
            transform: translate3d(37vw, -114vh, 0);
        }
    }
    .circle-container:nth-child(9) .circle {
        -webkit-animation-delay: 1185ms;
        animation-delay: 1185ms;
    }
    .circle-container:nth-child(10) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-10;
        animation-name: move-frames-10;
        -webkit-animation-duration: 48934ms;
        animation-duration: 48934ms;
        -webkit-animation-delay: 42621ms;
        animation-delay: 42621ms;
    }
    @-webkit-keyframes move-frames-10 {
        from {
            transform: translate3d(28vw, 102vh, 0);
        }
        to {
            transform: translate3d(13vw, -111vh, 0);
        }
    }
    @keyframes move-frames-10 {
        from {
            transform: translate3d(28vw, 102vh, 0);
        }
        to {
            transform: translate3d(13vw, -111vh, 0);
        }
    }
    .circle-container:nth-child(10) .circle {
        -webkit-animation-delay: 2138ms;
        animation-delay: 2138ms;
    }
    .circle-container:nth-child(11) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-11;
        animation-name: move-frames-11;
        -webkit-animation-duration: 62181ms;
        animation-duration: 62181ms;
        -webkit-animation-delay: 8214ms;
        animation-delay: 8214ms;
    }
    @-webkit-keyframes move-frames-11 {
        from {
            transform: translate3d(38vw, 104vh, 0);
        }
        to {
            transform: translate3d(32vw, -105vh, 0);
        }
    }
    @keyframes move-frames-11 {
        from {
            transform: translate3d(38vw, 104vh, 0);
        }
        to {
            transform: translate3d(32vw, -105vh, 0);
        }
    }
    .circle-container:nth-child(11) .circle {
        -webkit-animation-delay: 1611ms;
        animation-delay: 1611ms;
    }
    .circle-container:nth-child(12) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-12;
        animation-name: move-frames-12;
        -webkit-animation-duration: 66587ms;
        animation-duration: 66587ms;
        -webkit-animation-delay: 47528ms;
        animation-delay: 47528ms;
    }
    @-webkit-keyframes move-frames-12 {
        from {
            transform: translate3d(9vw, 102vh, 0);
        }
        to {
            transform: translate3d(53vw, -111vh, 0);
        }
    }
    @keyframes move-frames-12 {
        from {
            transform: translate3d(9vw, 102vh, 0);
        }
        to {
            transform: translate3d(53vw, -111vh, 0);
        }
    }
    .circle-container:nth-child(12) .circle {
        -webkit-animation-delay: 1547ms;
        animation-delay: 1547ms;
    }
    .circle-container:nth-child(13) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-13;
        animation-name: move-frames-13;
        -webkit-animation-duration: 60723ms;
        animation-duration: 60723ms;
        -webkit-animation-delay: 23328ms;
        animation-delay: 23328ms;
    }
    @-webkit-keyframes move-frames-13 {
        from {
            transform: translate3d(100vw, 104vh, 0);
        }
        to {
            transform: translate3d(70vw, -126vh, 0);
        }
    }
    @keyframes move-frames-13 {
        from {
            transform: translate3d(100vw, 104vh, 0);
        }
        to {
            transform: translate3d(70vw, -126vh, 0);
        }
    }
    .circle-container:nth-child(13) .circle {
        -webkit-animation-delay: 2021ms;
        animation-delay: 2021ms;
    }
    .circle-container:nth-child(14) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-14;
        animation-name: move-frames-14;
        -webkit-animation-duration: 48741ms;
        animation-duration: 48741ms;
        -webkit-animation-delay: 63234ms;
        animation-delay: 63234ms;
    }
    @-webkit-keyframes move-frames-14 {
        from {
            transform: translate3d(83vw, 105vh, 0);
        }
        to {
            transform: translate3d(61vw, -106vh, 0);
        }
    }
    @keyframes move-frames-14 {
        from {
            transform: translate3d(83vw, 105vh, 0);
        }
        to {
            transform: translate3d(61vw, -106vh, 0);
        }
    }
    .circle-container:nth-child(14) .circle {
        -webkit-animation-delay: 6343ms;
        animation-delay: 6343ms;
    }
    .circle-container:nth-child(15) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-15;
        animation-name: move-frames-15;
        -webkit-animation-duration: 61635ms;
        animation-duration: 61635ms;
        -webkit-animation-delay: 16959ms;
        animation-delay: 16959ms;
    }
    @-webkit-keyframes move-frames-15 {
        from {
            transform: translate3d(97vw, 107vh, 0);
        }
        to {
            transform: translate3d(43vw, -125vh, 0);
        }
    }
    @keyframes move-frames-15 {
        from {
            transform: translate3d(97vw, 107vh, 0);
        }
        to {
            transform: translate3d(43vw, -125vh, 0);
        }
    }
    .circle-container:nth-child(15) .circle {
        -webkit-animation-delay: 2737ms;
        animation-delay: 2737ms;
    }
    .circle-container:nth-child(16) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-16;
        animation-name: move-frames-16;
        -webkit-animation-duration: 66120ms;
        animation-duration: 66120ms;
        -webkit-animation-delay: 21364ms;
        animation-delay: 21364ms;
    }
    @-webkit-keyframes move-frames-16 {
        from {
            transform: translate3d(25vw, 103vh, 0);
        }
        to {
            transform: translate3d(9vw, -131vh, 0);
        }
    }
    @keyframes move-frames-16 {
        from {
            transform: translate3d(25vw, 103vh, 0);
        }
        to {
            transform: translate3d(9vw, -131vh, 0);
        }
    }
    .circle-container:nth-child(16) .circle {
        -webkit-animation-delay: 6948ms;
        animation-delay: 6948ms;
    }
    .circle-container:nth-child(17) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-17;
        animation-name: move-frames-17;
        -webkit-animation-duration: 49585ms;
        animation-duration: 49585ms;
        -webkit-animation-delay: 16966ms;
        animation-delay: 16966ms;
    }
    @-webkit-keyframes move-frames-17 {
        from {
            transform: translate3d(62vw, 107vh, 0);
        }
        to {
            transform: translate3d(62vw, -118vh, 0);
        }
    }
    @keyframes move-frames-17 {
        from {
            transform: translate3d(62vw, 107vh, 0);
        }
        to {
            transform: translate3d(62vw, -118vh, 0);
        }
    }
    .circle-container:nth-child(17) .circle {
        -webkit-animation-delay: 2164ms;
        animation-delay: 2164ms;
    }
    .circle-container:nth-child(18) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-18;
        animation-name: move-frames-18;
        -webkit-animation-duration: 60900ms;
        animation-duration: 60900ms;
        -webkit-animation-delay: 27781ms;
        animation-delay: 27781ms;
    }
    @-webkit-keyframes move-frames-18 {
        from {
            transform: translate3d(44vw, 108vh, 0);
        }
        to {
            transform: translate3d(92vw, -116vh, 0);
        }
    }
    @keyframes move-frames-18 {
        from {
            transform: translate3d(44vw, 108vh, 0);
        }
        to {
            transform: translate3d(92vw, -116vh, 0);
        }
    }
    .circle-container:nth-child(18) .circle {
        -webkit-animation-delay: 2044ms;
        animation-delay: 2044ms;
    }
    .circle-container:nth-child(19) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-19;
        animation-name: move-frames-19;
        -webkit-animation-duration: 49264ms;
        animation-duration: 49264ms;
        -webkit-animation-delay: 20971ms;
        animation-delay: 20971ms;
    }
    @-webkit-keyframes move-frames-19 {
        from {
            transform: translate3d(97vw, 102vh, 0);
        }
        to {
            transform: translate3d(13vw, -113vh, 0);
        }
    }
    @keyframes move-frames-19 {
        from {
            transform: translate3d(97vw, 102vh, 0);
        }
        to {
            transform: translate3d(13vw, -113vh, 0);
        }
    }
    .circle-container:nth-child(19) .circle {
        -webkit-animation-delay: 6430ms;
        animation-delay: 6430ms;
    }
    .circle-container:nth-child(20) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-20;
        animation-name: move-frames-20;
        -webkit-animation-duration: 63819ms;
        animation-duration: 63819ms;
        -webkit-animation-delay: 47010ms;
        animation-delay: 47010ms;
    }
    @-webkit-keyframes move-frames-20 {
        from {
            transform: translate3d(86vw, 102vh, 0);
        }
        to {
            transform: translate3d(80vw, -115vh, 0);
        }
    }
    @keyframes move-frames-20 {
        from {
            transform: translate3d(86vw, 102vh, 0);
        }
        to {
            transform: translate3d(80vw, -115vh, 0);
        }
    }
    .circle-container:nth-child(20) .circle {
        -webkit-animation-delay: 1075ms;
        animation-delay: 1075ms;
    }
    .circle-container:nth-child(21) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-21;
        animation-name: move-frames-21;
        -webkit-animation-duration: 62661ms;
        animation-duration: 62661ms;
        -webkit-animation-delay: 64571ms;
        animation-delay: 64571ms;
    }
    @-webkit-keyframes move-frames-21 {
        from {
            transform: translate3d(37vw, 106vh, 0);
        }
        to {
            transform: translate3d(99vw, -116vh, 0);
        }
    }
    @keyframes move-frames-21 {
        from {
            transform: translate3d(37vw, 106vh, 0);
        }
        to {
            transform: translate3d(99vw, -116vh, 0);
        }
    }
    .circle-container:nth-child(21) .circle {
        -webkit-animation-delay: 2643ms;
        animation-delay: 2643ms;
    }
    .circle-container:nth-child(22) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-22;
        animation-name: move-frames-22;
        -webkit-animation-duration: 49566ms;
        animation-duration: 449566ms;
        -webkit-animation-delay: 64580ms;
        animation-delay: 64580ms;
    }
    @-webkit-keyframes move-frames-22 {
        from {
            transform: translate3d(89vw, 108vh, 0);
        }
        to {
            transform: translate3d(12vw, -111vh, 0);
        }
    }
    @keyframes move-frames-22 {
        from {
            transform: translate3d(89vw, 108vh, 0);
        }
        to {
            transform: translate3d(12vw, -111vh, 0);
        }
    }
    .circle-container:nth-child(22) .circle {
        -webkit-animation-delay: 6496ms;
        animation-delay: 6496ms;
    }
    .circle-container:nth-child(23) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-23;
        animation-name: move-frames-23;
        -webkit-animation-duration: 48113ms;
        animation-duration: 48113ms;
        -webkit-animation-delay: 28062ms;
        animation-delay: 28062ms;
    }
    @-webkit-keyframes move-frames-23 {
        from {
            transform: translate3d(28vw, 106vh, 0);
        }
        to {
            transform: translate3d(77vw, -131vh, 0);
        }
    }
    @keyframes move-frames-23 {
        from {
            transform: translate3d(28vw, 106vh, 0);
        }
        to {
            transform: translate3d(77vw, -131vh, 0);
        }
    }
    .circle-container:nth-child(23) .circle {
        -webkit-animation-delay: 1285ms;
        animation-delay: 1285ms;
    }
    .circle-container:nth-child(24) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-24;
        animation-name: move-frames-24;
        -webkit-animation-duration: 64435ms;
        animation-duration: 64435ms;
        -webkit-animation-delay: 22802ms;
        animation-delay: 22802ms;
    }
    @-webkit-keyframes move-frames-24 {
        from {
            transform: translate3d(99vw, 102vh, 0);
        }
        to {
            transform: translate3d(78vw, -127vh, 0);
        }
    }
    @keyframes move-frames-24 {
        from {
            transform: translate3d(99vw, 102vh, 0);
        }
        to {
            transform: translate3d(78vw, -127vh, 0);
        }
    }
    .circle-container:nth-child(24) .circle {
        -webkit-animation-delay: 669ms;
        animation-delay: 669ms;
    }
    .circle-container:nth-child(25) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-25;
        animation-name: move-frames-25;
        -webkit-animation-duration: 88618ms;
        animation-duration: 88618ms;
        -webkit-animation-delay: 83636ms;
        animation-delay: 83636ms;
    }
    @-webkit-keyframes move-frames-25 {
        from {
            transform: translate3d(85vw, 109vh, 0);
        }
        to {
            transform: translate3d(60vw, -112vh, 0);
        }
    }
    @keyframes move-frames-25 {
        from {
            transform: translate3d(85vw, 109vh, 0);
        }
        to {
            transform: translate3d(60vw, -112vh, 0);
        }
    }
    .circle-container:nth-child(25) .circle {
        -webkit-animation-delay: 3853ms;
        animation-delay: 3853ms;
    }
    .circle-container:nth-child(26) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-26;
        animation-name: move-frames-26;
        -webkit-animation-duration: 61171ms;
        animation-duration: 61171ms;
        -webkit-animation-delay: 43841ms;
        animation-delay: 43841ms;
    }
    @-webkit-keyframes move-frames-26 {
        from {
            transform: translate3d(30vw, 108vh, 0);
        }
        to {
            transform: translate3d(11vw, -111vh, 0);
        }
    }
    @keyframes move-frames-26 {
        from {
            transform: translate3d(30vw, 108vh, 0);
        }
        to {
            transform: translate3d(11vw, -111vh, 0);
        }
    }
    .circle-container:nth-child(26) .circle {
        -webkit-animation-delay: 1526ms;
        animation-delay: 1526ms;
    }
    .circle-container:nth-child(27) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-27;
        animation-name: move-frames-27;
        -webkit-animation-duration: 60734ms;
        animation-duration: 60734ms;
        -webkit-animation-delay: 28237ms;
        animation-delay: 28237ms;
    }
    @-webkit-keyframes move-frames-27 {
        from {
            transform: translate3d(14vw, 103vh, 0);
        }
        to {
            transform: translate3d(59vw, -123vh, 0);
        }
    }
    @keyframes move-frames-27 {
        from {
            transform: translate3d(14vw, 103vh, 0);
        }
        to {
            transform: translate3d(59vw, -123vh, 0);
        }
    }
    .circle-container:nth-child(27) .circle {
        -webkit-animation-delay: 567ms;
        animation-delay: 567ms;
    }
    .circle-container:nth-child(28) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-28;
        animation-name: move-frames-28;
        -webkit-animation-duration: 65858ms;
        animation-duration: 65858ms;
        -webkit-animation-delay: 27930ms;
        animation-delay: 27930ms;
    }
    @-webkit-keyframes move-frames-28 {
        from {
            transform: translate3d(59vw, 107vh, 0);
        }
        to {
            transform: translate3d(43vw, -128vh, 0);
        }
    }
    @keyframes move-frames-28 {
        from {
            transform: translate3d(59vw, 107vh, 0);
        }
        to {
            transform: translate3d(43vw, -128vh, 0);
        }
    }
    .circle-container:nth-child(28) .circle {
        -webkit-animation-delay: 3955ms;
        animation-delay: 3955ms;
    }
    .circle-container:nth-child(29) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-29;
        animation-name: move-frames-29;
        -webkit-animation-duration: 62680ms;
        animation-duration: 62680ms;
        -webkit-animation-delay: 28365ms;
        animation-delay: 28365ms;
    }
    @-webkit-keyframes move-frames-29 {
        from {
            transform: translate3d(31vw, 107vh, 0);
        }
        to {
            transform: translate3d(5vw, -114vh, 0);
        }
    }
    @keyframes move-frames-29 {
        from {
            transform: translate3d(31vw, 107vh, 0);
        }
        to {
            transform: translate3d(5vw, -114vh, 0);
        }
    }
    .circle-container:nth-child(29) .circle {
        -webkit-animation-delay: 4778ms;
        animation-delay: 4778ms;
    }
    .circle-container:nth-child(30) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-30;
        animation-name: move-frames-30;
        -webkit-animation-duration: 63996ms;
        animation-duration: 63996ms;
        -webkit-animation-delay: 29300ms;
        animation-delay: 29300ms;
    }
    @-webkit-keyframes move-frames-30 {
        from {
            transform: translate3d(1vw, 104vh, 0);
        }
        to {
            transform: translate3d(87vw, -133vh, 0);
        }
    }
    @keyframes move-frames-30 {
        from {
            transform: translate3d(1vw, 104vh, 0);
        }
        to {
            transform: translate3d(87vw, -133vh, 0);
        }
    }
    .circle-container:nth-child(30) .circle {
        -webkit-animation-delay: 2486ms;
        animation-delay: 2486ms;
    }
    .circle-container:nth-child(31) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-31;
        animation-name: move-frames-31;
        -webkit-animation-duration: 29121ms;
        animation-duration: 29121ms;
        -webkit-animation-delay: 16419ms;
        animation-delay: 16419ms;
    }
    @-webkit-keyframes move-frames-31 {
        from {
            transform: translate3d(5vw, 109vh, 0);
        }
        to {
            transform: translate3d(24vw, -113vh, 0);
        }
    }
    @keyframes move-frames-31 {
        from {
            transform: translate3d(5vw, 109vh, 0);
        }
        to {
            transform: translate3d(24vw, -113vh, 0);
        }
    }
    .circle-container:nth-child(31) .circle {
        -webkit-animation-delay: 2170ms;
        animation-delay: 2170ms;
    }
    .circle-container:nth-child(32) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-32;
        animation-name: move-frames-32;
        -webkit-animation-duration: 62905ms;
        animation-duration: 62905ms;
        -webkit-animation-delay: 60137ms;
        animation-delay: 60137ms;
    }
    @-webkit-keyframes move-frames-32 {
        from {
            transform: translate3d(89vw, 109vh, 0);
        }
        to {
            transform: translate3d(81vw, -113vh, 0);
        }
    }
    @keyframes move-frames-32 {
        from {
            transform: translate3d(89vw, 109vh, 0);
        }
        to {
            transform: translate3d(81vw, -113vh, 0);
        }
    }
    .circle-container:nth-child(32) .circle {
        -webkit-animation-delay: 1061ms;
        animation-delay: 1061ms;
    }
    .circle-container:nth-child(33) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-33;
        animation-name: move-frames-33;
        -webkit-animation-duration: 48931ms;
        animation-duration: 48931ms;
        -webkit-animation-delay: 8252ms;
        animation-delay: 8252ms;
    }
    @-webkit-keyframes move-frames-33 {
        from {
            transform: translate3d(79vw, 106vh, 0);
        }
        to {
            transform: translate3d(69vw, -135vh, 0);
        }
    }
    @keyframes move-frames-33 {
        from {
            transform: translate3d(79vw, 106vh, 0);
        }
        to {
            transform: translate3d(69vw, -135vh, 0);
        }
    }
    .circle-container:nth-child(33) .circle {
        -webkit-animation-delay: 806ms;
        animation-delay: 806ms;
    }
    .circle-container:nth-child(34) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-34;
        animation-name: move-frames-34;
        -webkit-animation-duration: 65976ms;
        animation-duration: 65976ms;
        -webkit-animation-delay: 2322ms;
        animation-delay: 2322ms;
    }
    @-webkit-keyframes move-frames-34 {
        from {
            transform: translate3d(33vw, 103vh, 0);
        }
        to {
            transform: translate3d(70vw, -132vh, 0);
        }
    }
    @keyframes move-frames-34 {
        from {
            transform: translate3d(33vw, 103vh, 0);
        }
        to {
            transform: translate3d(70vw, -132vh, 0);
        }
    }
    .circle-container:nth-child(34) .circle {
        -webkit-animation-delay: 48ms;
        animation-delay: 48ms;
    }
    .circle-container:nth-child(35) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-35;
        animation-name: move-frames-35;
        -webkit-animation-duration: 61996ms;
        animation-duration: 61996ms;
        -webkit-animation-delay: 25961ms;
        animation-delay: 25961ms;
    }
    @-webkit-keyframes move-frames-35 {
        from {
            transform: translate3d(58vw, 102vh, 0);
        }
        to {
            transform: translate3d(82vw, -115vh, 0);
        }
    }
    @keyframes move-frames-35 {
        from {
            transform: translate3d(58vw, 102vh, 0);
        }
        to {
            transform: translate3d(82vw, -115vh, 0);
        }
    }
    .circle-container:nth-child(35) .circle {
        -webkit-animation-delay: 1106ms;
        animation-delay: 1106ms;
    }
    .circle-container:nth-child(36) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-36;
        animation-name: move-frames-36;
        -webkit-animation-duration: 60502ms;
        animation-duration: 60502ms;
        -webkit-animation-delay: 2046ms;
        animation-delay: 2046ms;
    }
    @-webkit-keyframes move-frames-36 {
        from {
            transform: translate3d(39vw, 110vh, 0);
        }
        to {
            transform: translate3d(60vw, -122vh, 0);
        }
    }
    @keyframes move-frames-36 {
        from {
            transform: translate3d(39vw, 110vh, 0);
        }
        to {
            transform: translate3d(60vw, -122vh, 0);
        }
    }
    .circle-container:nth-child(36) .circle {
        -webkit-animation-delay: 1949ms;
        animation-delay: 1949ms;
    }
    .circle-container:nth-child(37) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-37;
        animation-name: move-frames-37;
        -webkit-animation-duration: 63724ms;
        animation-duration: 63724ms;
        -webkit-animation-delay: 26888ms;
        animation-delay: 26888ms;
    }
    @-webkit-keyframes move-frames-37 {
        from {
            transform: translate3d(31vw, 110vh, 0);
        }
        to {
            transform: translate3d(76vw, -126vh, 0);
        }
    }
    @keyframes move-frames-37 {
        from {
            transform: translate3d(31vw, 110vh, 0);
        }
        to {
            transform: translate3d(76vw, -126vh, 0);
        }
    }
    .circle-container:nth-child(37) .circle {
        -webkit-animation-delay: 1938ms;
        animation-delay: 1938ms;
    }
    .circle-container:nth-child(38) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-38;
        animation-name: move-frames-38;
        -webkit-animation-duration: 63736ms;
        animation-duration: 63736ms;
        -webkit-animation-delay: 44981ms;
        animation-delay: 44981ms;
    }
    @-webkit-keyframes move-frames-38 {
        from {
            transform: translate3d(100vw, 102vh, 0);
        }
        to {
            transform: translate3d(8vw, -123vh, 0);
        }
    }
    @keyframes move-frames-38 {
        from {
            transform: translate3d(100vw, 102vh, 0);
        }
        to {
            transform: translate3d(8vw, -123vh, 0);
        }
    }
    .circle-container:nth-child(38) .circle {
        -webkit-animation-delay: 509ms;
        animation-delay: 509ms;
    }
    .circle-container:nth-child(39) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-39;
        animation-name: move-frames-39;
        -webkit-animation-duration: 66119ms;
        animation-duration: 66119ms;
        -webkit-animation-delay: 66797ms;
        animation-delay: 66797ms;
    }
    @-webkit-keyframes move-frames-39 {
        from {
            transform: translate3d(31vw, 104vh, 0);
        }
        to {
            transform: translate3d(63vw, -131vh, 0);
        }
    }
    @keyframes move-frames-39 {
        from {
            transform: translate3d(31vw, 104vh, 0);
        }
        to {
            transform: translate3d(63vw, -131vh, 0);
        }
    }
    .circle-container:nth-child(39) .circle {
        -webkit-animation-delay: 1558ms;
        animation-delay: 1558ms;
    }
    .circle-container:nth-child(40) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-40;
        animation-name: move-frames-40;
        -webkit-animation-duration: 62211ms;
        animation-duration: 62211ms;
        -webkit-animation-delay: 46055ms;
        animation-delay: 46055ms;
    }
    @-webkit-keyframes move-frames-40 {
        from {
            transform: translate3d(58vw, 102vh, 0);
        }
        to {
            transform: translate3d(8vw, -125vh, 0);
        }
    }
    @keyframes move-frames-40 {
        from {
            transform: translate3d(58vw, 102vh, 0);
        }
        to {
            transform: translate3d(8vw, -125vh, 0);
        }
    }
    .circle-container:nth-child(40) .circle {
        -webkit-animation-delay: 878ms;
        animation-delay: 878ms;
    }
    .circle-container:nth-child(41) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-41;
        animation-name: move-frames-41;
        -webkit-animation-duration: 61704ms;
        animation-duration: 61704ms;
        -webkit-animation-delay: 66984ms;
        animation-delay: 66984ms;
    }
    @-webkit-keyframes move-frames-41 {
        from {
            transform: translate3d(36vw, 105vh, 0);
        }
        to {
            transform: translate3d(88vw, -119vh, 0);
        }
    }
    @keyframes move-frames-41 {
        from {
            transform: translate3d(36vw, 105vh, 0);
        }
        to {
            transform: translate3d(88vw, -119vh, 0);
        }
    }
    .circle-container:nth-child(41) .circle {
        -webkit-animation-delay: 248ms;
        animation-delay: 248ms;
    }
    .circle-container:nth-child(42) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-42;
        animation-name: move-frames-42;
        -webkit-animation-duration: 63037ms;
        animation-duration: 63037ms;
        -webkit-animation-delay: 49905ms;
        animation-delay: 49905ms;
    }
    @-webkit-keyframes move-frames-42 {
        from {
            transform: translate3d(83vw, 110vh, 0);
        }
        to {
            transform: translate3d(96vw, -114vh, 0);
        }
    }
    @keyframes move-frames-42 {
        from {
            transform: translate3d(83vw, 110vh, 0);
        }
        to {
            transform: translate3d(96vw, -114vh, 0);
        }
    }
    .circle-container:nth-child(42) .circle {
        -webkit-animation-delay: 850ms;
        animation-delay: 850ms;
    }
    .circle-container:nth-child(43) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-43;
        animation-name: move-frames-43;
        -webkit-animation-duration: 66456ms;
        animation-duration: 66456ms;
        -webkit-animation-delay: 30219ms;
        animation-delay: 30219ms;
    }
    @-webkit-keyframes move-frames-43 {
        from {
            transform: translate3d(22vw, 110vh, 0);
        }
        to {
            transform: translate3d(100vw, -121vh, 0);
        }
    }
    @keyframes move-frames-43 {
        from {
            transform: translate3d(22vw, 110vh, 0);
        }
        to {
            transform: translate3d(100vw, -121vh, 0);
        }
    }
    .circle-container:nth-child(43) .circle {
        -webkit-animation-delay: 526ms;
        animation-delay: 526ms;
    }
    .circle-container:nth-child(44) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-44;
        animation-name: move-frames-44;
        -webkit-animation-duration: 64811ms;
        animation-duration: 64811ms;
        -webkit-animation-delay: 66836ms;
        animation-delay: 66836ms;
    }
    @-webkit-keyframes move-frames-44 {
        from {
            transform: translate3d(15vw, 102vh, 0);
        }
        to {
            transform: translate3d(25vw, -108vh, 0);
        }
    }
    @keyframes move-frames-44 {
        from {
            transform: translate3d(15vw, 102vh, 0);
        }
        to {
            transform: translate3d(25vw, -108vh, 0);
        }
    }
    .circle-container:nth-child(44) .circle {
        -webkit-animation-delay: 1395ms;
        animation-delay: 1395ms;
    }
    .circle-container:nth-child(45) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-45;
        animation-name: move-frames-45;
        -webkit-animation-duration: 34671ms;
        animation-duration: 34671ms;
        -webkit-animation-delay: 7928ms;
        animation-delay: 7928ms;
    }
    @-webkit-keyframes move-frames-45 {
        from {
            transform: translate3d(93vw, 104vh, 0);
        }
        to {
            transform: translate3d(22vw, -116vh, 0);
        }
    }
    @keyframes move-frames-45 {
        from {
            transform: translate3d(93vw, 104vh, 0);
        }
        to {
            transform: translate3d(22vw, -116vh, 0);
        }
    }
    .circle-container:nth-child(45) .circle {
        -webkit-animation-delay: 2096ms;
        animation-delay: 2096ms;
    }
    .circle-container:nth-child(46) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-46;
        animation-name: move-frames-46;
        -webkit-animation-duration: 35480ms;
        animation-duration: 35480ms;
        -webkit-animation-delay: 17980ms;
        animation-delay: 17980ms;
    }
    @-webkit-keyframes move-frames-46 {
        from {
            transform: translate3d(70vw, 109vh, 0);
        }
        to {
            transform: translate3d(63vw, -111vh, 0);
        }
    }
    @keyframes move-frames-46 {
        from {
            transform: translate3d(70vw, 109vh, 0);
        }
        to {
            transform: translate3d(63vw, -111vh, 0);
        }
    }
    .circle-container:nth-child(46) .circle {
        -webkit-animation-delay: 562ms;
        animation-delay: 562ms;
    }
    .circle-container:nth-child(47) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-47;
        animation-name: move-frames-47;
        -webkit-animation-duration: 29403ms;
        animation-duration: 29403ms;
        -webkit-animation-delay: 5627ms;
        animation-delay: 5627ms;
    }
    @-webkit-keyframes move-frames-47 {
        from {
            transform: translate3d(77vw, 106vh, 0);
        }
        to {
            transform: translate3d(10vw, -127vh, 0);
        }
    }
    @keyframes move-frames-47 {
        from {
            transform: translate3d(77vw, 106vh, 0);
        }
        to {
            transform: translate3d(10vw, -127vh, 0);
        }
    }
    .circle-container:nth-child(47) .circle {
        -webkit-animation-delay: 3370ms;
        animation-delay: 3370ms;
    }
    .circle-container:nth-child(48) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-48;
        animation-name: move-frames-48;
        -webkit-animation-duration: 29897ms;
        animation-duration: 29897ms;
        -webkit-animation-delay: 32577ms;
        animation-delay: 32577ms;
    }
    @-webkit-keyframes move-frames-48 {
        from {
            transform: translate3d(67vw, 110vh, 0);
        }
        to {
            transform: translate3d(98vw, -126vh, 0);
        }
    }
    @keyframes move-frames-48 {
        from {
            transform: translate3d(67vw, 110vh, 0);
        }
        to {
            transform: translate3d(98vw, -126vh, 0);
        }
    }
    .circle-container:nth-child(48) .circle {
        -webkit-animation-delay: 333ms;
        animation-delay: 333ms;
    }
    .circle-container:nth-child(49) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-49;
        animation-name: move-frames-49;
        -webkit-animation-duration: 35205ms;
        animation-duration: 35205ms;
        -webkit-animation-delay: 2231ms;
        animation-delay: 2231ms;
    }
    @-webkit-keyframes move-frames-49 {
        from {
            transform: translate3d(61vw, 103vh, 0);
        }
        to {
            transform: translate3d(81vw, -132vh, 0);
        }
    }
    @keyframes move-frames-49 {
        from {
            transform: translate3d(61vw, 103vh, 0);
        }
        to {
            transform: translate3d(81vw, -132vh, 0);
        }
    }
    .circle-container:nth-child(49) .circle {
        -webkit-animation-delay: 2245ms;
        animation-delay: 2245ms;
    }
    .circle-container:nth-child(50) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-50;
        animation-name: move-frames-50;
        -webkit-animation-duration: 31405ms;
        animation-duration: 31405ms;
        -webkit-animation-delay: 20004ms;
        animation-delay: 20004ms;
    }
    @-webkit-keyframes move-frames-50 {
        from {
            transform: translate3d(15vw, 108vh, 0);
        }
        to {
            transform: translate3d(76vw, -133vh, 0);
        }
    }
    @keyframes move-frames-50 {
        from {
            transform: translate3d(15vw, 108vh, 0);
        }
        to {
            transform: translate3d(76vw, -133vh, 0);
        }
    }
    .circle-container:nth-child(50) .circle {
        -webkit-animation-delay: 1470ms;
        animation-delay: 1470ms;
    }
    .circle-container:nth-child(51) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-51;
        animation-name: move-frames-51;
        -webkit-animation-duration: 28267ms;
        animation-duration: 28267ms;
        -webkit-animation-delay: 11982ms;
        animation-delay: 11982ms;
    }
    @-webkit-keyframes move-frames-51 {
        from {
            transform: translate3d(40vw, 105vh, 0);
        }
        to {
            transform: translate3d(10vw, -108vh, 0);
        }
    }
    @keyframes move-frames-51 {
        from {
            transform: translate3d(40vw, 105vh, 0);
        }
        to {
            transform: translate3d(10vw, -108vh, 0);
        }
    }
    .circle-container:nth-child(51) .circle {
        -webkit-animation-delay: 1446ms;
        animation-delay: 1446ms;
    }
    .circle-container:nth-child(52) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-52;
        animation-name: move-frames-52;
        -webkit-animation-duration: 32599ms;
        animation-duration: 32599ms;
        -webkit-animation-delay: 24353ms;
        animation-delay: 24353ms;
    }
    @-webkit-keyframes move-frames-52 {
        from {
            transform: translate3d(81vw, 107vh, 0);
        }
        to {
            transform: translate3d(57vw, -136vh, 0);
        }
    }
    @keyframes move-frames-52 {
        from {
            transform: translate3d(81vw, 107vh, 0);
        }
        to {
            transform: translate3d(57vw, -136vh, 0);
        }
    }
    .circle-container:nth-child(52) .circle {
        -webkit-animation-delay: 3841ms;
        animation-delay: 3841ms;
    }
    .circle-container:nth-child(53) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-53;
        animation-name: move-frames-53;
        -webkit-animation-duration: 30430ms;
        animation-duration: 30430ms;
        -webkit-animation-delay: 20593ms;
        animation-delay: 20593ms;
    }
    @-webkit-keyframes move-frames-53 {
        from {
            transform: translate3d(22vw, 101vh, 0);
        }
        to {
            transform: translate3d(90vw, -127vh, 0);
        }
    }
    @keyframes move-frames-53 {
        from {
            transform: translate3d(22vw, 101vh, 0);
        }
        to {
            transform: translate3d(90vw, -127vh, 0);
        }
    }
    .circle-container:nth-child(53) .circle {
        -webkit-animation-delay: 2875ms;
        animation-delay: 2875ms;
    }
    .circle-container:nth-child(54) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-54;
        animation-name: move-frames-54;
        -webkit-animation-duration: 30515ms;
        animation-duration: 30515ms;
        -webkit-animation-delay: 18150ms;
        animation-delay: 18150ms;
    }
    @-webkit-keyframes move-frames-54 {
        from {
            transform: translate3d(71vw, 102vh, 0);
        }
        to {
            transform: translate3d(41vw, -115vh, 0);
        }
    }
    @keyframes move-frames-54 {
        from {
            transform: translate3d(71vw, 102vh, 0);
        }
        to {
            transform: translate3d(41vw, -115vh, 0);
        }
    }
    .circle-container:nth-child(54) .circle {
        -webkit-animation-delay: 108ms;
        animation-delay: 108ms;
    }
    .circle-container:nth-child(55) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-55;
        animation-name: move-frames-55;
        -webkit-animation-duration: 36032ms;
        animation-duration: 36032ms;
        -webkit-animation-delay: 29623ms;
        animation-delay: 29623ms;
    }
    @-webkit-keyframes move-frames-55 {
        from {
            transform: translate3d(76vw, 107vh, 0);
        }
        to {
            transform: translate3d(40vw, -114vh, 0);
        }
    }
    @keyframes move-frames-55 {
        from {
            transform: translate3d(76vw, 107vh, 0);
        }
        to {
            transform: translate3d(40vw, -114vh, 0);
        }
    }
    .circle-container:nth-child(55) .circle {
        -webkit-animation-delay: 2997ms;
        animation-delay: 2997ms;
    }
    .circle-container:nth-child(56) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-56;
        animation-name: move-frames-56;
        -webkit-animation-duration: 32668ms;
        animation-duration: 32668ms;
        -webkit-animation-delay: 35464ms;
        animation-delay: 35464ms;
    }
    @-webkit-keyframes move-frames-56 {
        from {
            transform: translate3d(7vw, 108vh, 0);
        }
        to {
            transform: translate3d(27vw, -120vh, 0);
        }
    }
    @keyframes move-frames-56 {
        from {
            transform: translate3d(7vw, 108vh, 0);
        }
        to {
            transform: translate3d(27vw, -120vh, 0);
        }
    }
    .circle-container:nth-child(56) .circle {
        -webkit-animation-delay: 250ms;
        animation-delay: 250ms;
    }
    .circle-container:nth-child(57) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-57;
        animation-name: move-frames-57;
        -webkit-animation-duration: 28609ms;
        animation-duration: 28609ms;
        -webkit-animation-delay: 28194ms;
        animation-delay: 28194ms;
    }
    @-webkit-keyframes move-frames-57 {
        from {
            transform: translate3d(99vw, 109vh, 0);
        }
        to {
            transform: translate3d(3vw, -139vh, 0);
        }
    }
    @keyframes move-frames-57 {
        from {
            transform: translate3d(99vw, 109vh, 0);
        }
        to {
            transform: translate3d(3vw, -139vh, 0);
        }
    }
    .circle-container:nth-child(57) .circle {
        -webkit-animation-delay: 3610ms;
        animation-delay: 3610ms;
    }
    .circle-container:nth-child(58) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-58;
        animation-name: move-frames-58;
        -webkit-animation-duration: 28708ms;
        animation-duration: 28708ms;
        -webkit-animation-delay: 11975ms;
        animation-delay: 11975ms;
    }
    @-webkit-keyframes move-frames-58 {
        from {
            transform: translate3d(43vw, 108vh, 0);
        }
        to {
            transform: translate3d(90vw, -130vh, 0);
        }
    }
    @keyframes move-frames-58 {
        from {
            transform: translate3d(43vw, 108vh, 0);
        }
        to {
            transform: translate3d(90vw, -130vh, 0);
        }
    }
    .circle-container:nth-child(58) .circle {
        -webkit-animation-delay: 3053ms;
        animation-delay: 3053ms;
    }
    .circle-container:nth-child(59) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-59;
        animation-name: move-frames-59;
        -webkit-animation-duration: 32271ms;
        animation-duration: 32271ms;
        -webkit-animation-delay: 18410ms;
        animation-delay: 18410ms;
    }
    @-webkit-keyframes move-frames-59 {
        from {
            transform: translate3d(62vw, 103vh, 0);
        }
        to {
            transform: translate3d(74vw, -125vh, 0);
        }
    }
    @keyframes move-frames-59 {
        from {
            transform: translate3d(62vw, 103vh, 0);
        }
        to {
            transform: translate3d(74vw, -125vh, 0);
        }
    }
    .circle-container:nth-child(59) .circle {
        -webkit-animation-delay: 3578ms;
        animation-delay: 3578ms;
    }
    .circle-container:nth-child(60) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-60;
        animation-name: move-frames-60;
        -webkit-animation-duration: 33927ms;
        animation-duration: 33927ms;
        -webkit-animation-delay: 15608ms;
        animation-delay: 15608ms;
    }
    @-webkit-keyframes move-frames-60 {
        from {
            transform: translate3d(59vw, 107vh, 0);
        }
        to {
            transform: translate3d(76vw, -111vh, 0);
        }
    }
    @keyframes move-frames-60 {
        from {
            transform: translate3d(59vw, 107vh, 0);
        }
        to {
            transform: translate3d(76vw, -111vh, 0);
        }
    }
    .circle-container:nth-child(60) .circle {
        -webkit-animation-delay: 3981ms;
        animation-delay: 3981ms;
    }
    .circle-container:nth-child(61) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-61;
        animation-name: move-frames-61;
        -webkit-animation-duration: 34703ms;
        animation-duration: 34703ms;
        -webkit-animation-delay: 7577ms;
        animation-delay: 7577ms;
    }
    @-webkit-keyframes move-frames-61 {
        from {
            transform: translate3d(78vw, 109vh, 0);
        }
        to {
            transform: translate3d(1vw, -119vh, 0);
        }
    }
    @keyframes move-frames-61 {
        from {
            transform: translate3d(78vw, 109vh, 0);
        }
        to {
            transform: translate3d(1vw, -119vh, 0);
        }
    }
    .circle-container:nth-child(61) .circle {
        -webkit-animation-delay: 3846ms;
        animation-delay: 3846ms;
    }
    .circle-container:nth-child(62) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-62;
        animation-name: move-frames-62;
        -webkit-animation-duration: 28533ms;
        animation-duration: 28533ms;
        -webkit-animation-delay: 3065ms;
        animation-delay: 3065ms;
    }
    @-webkit-keyframes move-frames-62 {
        from {
            transform: translate3d(45vw, 104vh, 0);
        }
        to {
            transform: translate3d(77vw, -114vh, 0);
        }
    }
    @keyframes move-frames-62 {
        from {
            transform: translate3d(45vw, 104vh, 0);
        }
        to {
            transform: translate3d(77vw, -114vh, 0);
        }
    }
    .circle-container:nth-child(62) .circle {
        -webkit-animation-delay: 3109ms;
        animation-delay: 3109ms;
    }
    .circle-container:nth-child(63) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-63;
        animation-name: move-frames-63;
        -webkit-animation-duration: 36339ms;
        animation-duration: 36339ms;
        -webkit-animation-delay: 16944ms;
        animation-delay: 16944ms;
    }
    @-webkit-keyframes move-frames-63 {
        from {
            transform: translate3d(77vw, 104vh, 0);
        }
        to {
            transform: translate3d(17vw, -117vh, 0);
        }
    }
    @keyframes move-frames-63 {
        from {
            transform: translate3d(77vw, 104vh, 0);
        }
        to {
            transform: translate3d(17vw, -117vh, 0);
        }
    }
    .circle-container:nth-child(63) .circle {
        -webkit-animation-delay: 3046ms;
        animation-delay: 3046ms;
    }
    .circle-container:nth-child(64) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-64;
        animation-name: move-frames-64;
        -webkit-animation-duration: 36371ms;
        animation-duration: 36371ms;
        -webkit-animation-delay: 32810ms;
        animation-delay: 32810ms;
    }
    @-webkit-keyframes move-frames-64 {
        from {
            transform: translate3d(2vw, 104vh, 0);
        }
        to {
            transform: translate3d(83vw, -113vh, 0);
        }
    }
    @keyframes move-frames-64 {
        from {
            transform: translate3d(2vw, 104vh, 0);
        }
        to {
            transform: translate3d(83vw, -113vh, 0);
        }
    }
    .circle-container:nth-child(64) .circle {
        -webkit-animation-delay: 1979ms;
        animation-delay: 1979ms;
    }
    .circle-container:nth-child(65) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-65;
        animation-name: move-frames-65;
        -webkit-animation-duration: 31493ms;
        animation-duration: 31493ms;
        -webkit-animation-delay: 1041ms;
        animation-delay: 1041ms;
    }
    @-webkit-keyframes move-frames-65 {
        from {
            transform: translate3d(47vw, 110vh, 0);
        }
        to {
            transform: translate3d(6vw, -129vh, 0);
        }
    }
    @keyframes move-frames-65 {
        from {
            transform: translate3d(47vw, 110vh, 0);
        }
        to {
            transform: translate3d(6vw, -129vh, 0);
        }
    }
    .circle-container:nth-child(65) .circle {
        -webkit-animation-delay: 857ms;
        animation-delay: 857ms;
    }
    .circle-container:nth-child(66) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-66;
        animation-name: move-frames-66;
        -webkit-animation-duration: 33429ms;
        animation-duration: 33429ms;
        -webkit-animation-delay: 30057ms;
        animation-delay: 30057ms;
    }
    @-webkit-keyframes move-frames-66 {
        from {
            transform: translate3d(62vw, 109vh, 0);
        }
        to {
            transform: translate3d(97vw, -111vh, 0);
        }
    }
    @keyframes move-frames-66 {
        from {
            transform: translate3d(62vw, 109vh, 0);
        }
        to {
            transform: translate3d(97vw, -111vh, 0);
        }
    }
    .circle-container:nth-child(66) .circle {
        -webkit-animation-delay: 2117ms;
        animation-delay: 2117ms;
    }
    .circle-container:nth-child(67) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-67;
        animation-name: move-frames-67;
        -webkit-animation-duration: 33699ms;
        animation-duration: 33699ms;
        -webkit-animation-delay: 13921ms;
        animation-delay: 13921ms;
    }
    @-webkit-keyframes move-frames-67 {
        from {
            transform: translate3d(67vw, 107vh, 0);
        }
        to {
            transform: translate3d(76vw, -126vh, 0);
        }
    }
    @keyframes move-frames-67 {
        from {
            transform: translate3d(67vw, 107vh, 0);
        }
        to {
            transform: translate3d(76vw, -126vh, 0);
        }
    }
    .circle-container:nth-child(67) .circle {
        -webkit-animation-delay: 338ms;
        animation-delay: 338ms;
    }
    .circle-container:nth-child(68) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-68;
        animation-name: move-frames-68;
        -webkit-animation-duration: 34266ms;
        animation-duration: 34266ms;
        -webkit-animation-delay: 17576ms;
        animation-delay: 17576ms;
    }
    @-webkit-keyframes move-frames-68 {
        from {
            transform: translate3d(91vw, 104vh, 0);
        }
        to {
            transform: translate3d(71vw, -114vh, 0);
        }
    }
    @keyframes move-frames-68 {
        from {
            transform: translate3d(91vw, 104vh, 0);
        }
        to {
            transform: translate3d(71vw, -114vh, 0);
        }
    }
    .circle-container:nth-child(68) .circle {
        -webkit-animation-delay: 3753ms;
        animation-delay: 3753ms;
    }
    .circle-container:nth-child(69) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-69;
        animation-name: move-frames-69;
        -webkit-animation-duration: 30788ms;
        animation-duration: 30788ms;
        -webkit-animation-delay: 1457ms;
        animation-delay: 1457ms;
    }
    @-webkit-keyframes move-frames-69 {
        from {
            transform: translate3d(96vw, 105vh, 0);
        }
        to {
            transform: translate3d(95vw, -124vh, 0);
        }
    }
    @keyframes move-frames-69 {
        from {
            transform: translate3d(96vw, 105vh, 0);
        }
        to {
            transform: translate3d(95vw, -124vh, 0);
        }
    }
    .circle-container:nth-child(69) .circle {
        -webkit-animation-delay: 1285ms;
        animation-delay: 1285ms;
    }
    .circle-container:nth-child(70) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-70;
        animation-name: move-frames-70;
        -webkit-animation-duration: 28954ms;
        animation-duration: 28954ms;
        -webkit-animation-delay: 35486ms;
        animation-delay: 35486ms;
    }
    @-webkit-keyframes move-frames-70 {
        from {
            transform: translate3d(78vw, 109vh, 0);
        }
        to {
            transform: translate3d(79vw, -134vh, 0);
        }
    }
    @keyframes move-frames-70 {
        from {
            transform: translate3d(78vw, 109vh, 0);
        }
        to {
            transform: translate3d(79vw, -134vh, 0);
        }
    }
    .circle-container:nth-child(70) .circle {
        -webkit-animation-delay: 79ms;
        animation-delay: 79ms;
    }
    .circle-container:nth-child(71) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-71;
        animation-name: move-frames-71;
        -webkit-animation-duration: 29889ms;
        animation-duration: 29889ms;
        -webkit-animation-delay: 10728ms;
        animation-delay: 10728ms;
    }
    @-webkit-keyframes move-frames-71 {
        from {
            transform: translate3d(30vw, 101vh, 0);
        }
        to {
            transform: translate3d(67vw, -121vh, 0);
        }
    }
    @keyframes move-frames-71 {
        from {
            transform: translate3d(30vw, 101vh, 0);
        }
        to {
            transform: translate3d(67vw, -121vh, 0);
        }
    }
    .circle-container:nth-child(71) .circle {
        -webkit-animation-delay: 1507ms;
        animation-delay: 1507ms;
    }
    .circle-container:nth-child(72) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-72;
        animation-name: move-frames-72;
        -webkit-animation-duration: 34100ms;
        animation-duration: 34100ms;
        -webkit-animation-delay: 5286ms;
        animation-delay: 5286ms;
    }
    @-webkit-keyframes move-frames-72 {
        from {
            transform: translate3d(79vw, 103vh, 0);
        }
        to {
            transform: translate3d(74vw, -112vh, 0);
        }
    }
    @keyframes move-frames-72 {
        from {
            transform: translate3d(79vw, 103vh, 0);
        }
        to {
            transform: translate3d(74vw, -112vh, 0);
        }
    }
    .circle-container:nth-child(72) .circle {
        -webkit-animation-delay: 1772ms;
        animation-delay: 1772ms;
    }
    .circle-container:nth-child(73) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-73;
        animation-name: move-frames-73;
        -webkit-animation-duration: 32060ms;
        animation-duration: 32060ms;
        -webkit-animation-delay: 1134ms;
        animation-delay: 1134ms;
    }
    @-webkit-keyframes move-frames-73 {
        from {
            transform: translate3d(8vw, 109vh, 0);
        }
        to {
            transform: translate3d(10vw, -127vh, 0);
        }
    }
    @keyframes move-frames-73 {
        from {
            transform: translate3d(8vw, 109vh, 0);
        }
        to {
            transform: translate3d(10vw, -127vh, 0);
        }
    }
    .circle-container:nth-child(73) .circle {
        -webkit-animation-delay: 783ms;
        animation-delay: 783ms;
    }
    .circle-container:nth-child(74) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-74;
        animation-name: move-frames-74;
        -webkit-animation-duration: 32825ms;
        animation-duration: 32825ms;
        -webkit-animation-delay: 20685ms;
        animation-delay: 20685ms;
    }
    @-webkit-keyframes move-frames-74 {
        from {
            transform: translate3d(39vw, 104vh, 0);
        }
        to {
            transform: translate3d(77vw, -124vh, 0);
        }
    }
    @keyframes move-frames-74 {
        from {
            transform: translate3d(39vw, 104vh, 0);
        }
        to {
            transform: translate3d(77vw, -124vh, 0);
        }
    }
    .circle-container:nth-child(74) .circle {
        -webkit-animation-delay: 3865ms;
        animation-delay: 3865ms;
    }
    .circle-container:nth-child(75) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-75;
        animation-name: move-frames-75;
        -webkit-animation-duration: 29708ms;
        animation-duration: 29708ms;
        -webkit-animation-delay: 12605ms;
        animation-delay: 12605ms;
    }
    @-webkit-keyframes move-frames-75 {
        from {
            transform: translate3d(74vw, 110vh, 0);
        }
        to {
            transform: translate3d(45vw, -130vh, 0);
        }
    }
    @keyframes move-frames-75 {
        from {
            transform: translate3d(74vw, 110vh, 0);
        }
        to {
            transform: translate3d(45vw, -130vh, 0);
        }
    }
    .circle-container:nth-child(75) .circle {
        -webkit-animation-delay: 161ms;
        animation-delay: 161ms;
    }
    .circle-container:nth-child(76) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-76;
        animation-name: move-frames-76;
        -webkit-animation-duration: 34786ms;
        animation-duration: 34786ms;
        -webkit-animation-delay: 10681ms;
        animation-delay: 10681ms;
    }
    @-webkit-keyframes move-frames-76 {
        from {
            transform: translate3d(35vw, 101vh, 0);
        }
        to {
            transform: translate3d(20vw, -115vh, 0);
        }
    }
    @keyframes move-frames-76 {
        from {
            transform: translate3d(35vw, 101vh, 0);
        }
        to {
            transform: translate3d(20vw, -115vh, 0);
        }
    }
    .circle-container:nth-child(76) .circle {
        -webkit-animation-delay: 286ms;
        animation-delay: 286ms;
    }
    .circle-container:nth-child(77) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-77;
        animation-name: move-frames-77;
        -webkit-animation-duration: 29292ms;
        animation-duration: 29292ms;
        -webkit-animation-delay: 15545ms;
        animation-delay: 15545ms;
    }
    @-webkit-keyframes move-frames-77 {
        from {
            transform: translate3d(37vw, 103vh, 0);
        }
        to {
            transform: translate3d(13vw, -123vh, 0);
        }
    }
    @keyframes move-frames-77 {
        from {
            transform: translate3d(37vw, 103vh, 0);
        }
        to {
            transform: translate3d(13vw, -123vh, 0);
        }
    }
    .circle-container:nth-child(77) .circle {
        -webkit-animation-delay: 2751ms;
        animation-delay: 2751ms;
    }
    .circle-container:nth-child(78) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-78;
        animation-name: move-frames-78;
        -webkit-animation-duration: 30584ms;
        animation-duration: 30584ms;
        -webkit-animation-delay: 4117ms;
        animation-delay: 4117ms;
    }
    @-webkit-keyframes move-frames-78 {
        from {
            transform: translate3d(66vw, 110vh, 0);
        }
        to {
            transform: translate3d(61vw, -123vh, 0);
        }
    }
    @keyframes move-frames-78 {
        from {
            transform: translate3d(66vw, 110vh, 0);
        }
        to {
            transform: translate3d(61vw, -123vh, 0);
        }
    }
    .circle-container:nth-child(78) .circle {
        -webkit-animation-delay: 2008ms;
        animation-delay: 2008ms;
    }
    .circle-container:nth-child(79) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-79;
        animation-name: move-frames-79;
        -webkit-animation-duration: 32994ms;
        animation-duration: 32994ms;
        -webkit-animation-delay: 35715ms;
        animation-delay: 35715ms;
    }
    @-webkit-keyframes move-frames-79 {
        from {
            transform: translate3d(39vw, 106vh, 0);
        }
        to {
            transform: translate3d(51vw, -116vh, 0);
        }
    }
    @keyframes move-frames-79 {
        from {
            transform: translate3d(39vw, 106vh, 0);
        }
        to {
            transform: translate3d(51vw, -116vh, 0);
        }
    }
    .circle-container:nth-child(79) .circle {
        -webkit-animation-delay: 477ms;
        animation-delay: 477ms;
    }
    .circle-container:nth-child(80) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-80;
        animation-name: move-frames-80;
        -webkit-animation-duration: 31127ms;
        animation-duration: 31127ms;
        -webkit-animation-delay: 34831ms;
        animation-delay: 34831ms;
    }
    @-webkit-keyframes move-frames-80 {
        from {
            transform: translate3d(64vw, 105vh, 0);
        }
        to {
            transform: translate3d(70vw, -109vh, 0);
        }
    }
    @keyframes move-frames-80 {
        from {
            transform: translate3d(64vw, 105vh, 0);
        }
        to {
            transform: translate3d(70vw, -109vh, 0);
        }
    }
    .circle-container:nth-child(80) .circle {
        -webkit-animation-delay: 2856ms;
        animation-delay: 2856ms;
    }
    .circle-container:nth-child(81) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-81;
        animation-name: move-frames-81;
        -webkit-animation-duration: 33096ms;
        animation-duration: 33096ms;
        -webkit-animation-delay: 8410ms;
        animation-delay: 8410ms;
    }
    @-webkit-keyframes move-frames-81 {
        from {
            transform: translate3d(5vw, 103vh, 0);
        }
        to {
            transform: translate3d(11vw, -110vh, 0);
        }
    }
    @keyframes move-frames-81 {
        from {
            transform: translate3d(5vw, 103vh, 0);
        }
        to {
            transform: translate3d(11vw, -110vh, 0);
        }
    }
    .circle-container:nth-child(81) .circle {
        -webkit-animation-delay: 1628ms;
        animation-delay: 1628ms;
    }
    .circle-container:nth-child(82) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-82;
        animation-name: move-frames-82;
        -webkit-animation-duration: 29478ms;
        animation-duration: 29478ms;
        -webkit-animation-delay: 21013ms;
        animation-delay: 21013ms;
    }
    @-webkit-keyframes move-frames-82 {
        from {
            transform: translate3d(19vw, 104vh, 0);
        }
        to {
            transform: translate3d(21vw, -131vh, 0);
        }
    }
    @keyframes move-frames-82 {
        from {
            transform: translate3d(19vw, 104vh, 0);
        }
        to {
            transform: translate3d(21vw, -131vh, 0);
        }
    }
    .circle-container:nth-child(82) .circle {
        -webkit-animation-delay: 2659ms;
        animation-delay: 2659ms;
    }
    .circle-container:nth-child(83) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-83;
        animation-name: move-frames-83;
        -webkit-animation-duration: 29606ms;
        animation-duration: 29606ms;
        -webkit-animation-delay: 1425ms;
        animation-delay: 1425ms;
    }
    @-webkit-keyframes move-frames-83 {
        from {
            transform: translate3d(22vw, 108vh, 0);
        }
        to {
            transform: translate3d(32vw, -117vh, 0);
        }
    }
    @keyframes move-frames-83 {
        from {
            transform: translate3d(22vw, 108vh, 0);
        }
        to {
            transform: translate3d(32vw, -117vh, 0);
        }
    }
    .circle-container:nth-child(83) .circle {
        -webkit-animation-delay: 72ms;
        animation-delay: 72ms;
    }
    .circle-container:nth-child(84) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-84;
        animation-name: move-frames-84;
        -webkit-animation-duration: 35169ms;
        animation-duration: 35169ms;
        -webkit-animation-delay: 4812ms;
        animation-delay: 4812ms;
    }
    @-webkit-keyframes move-frames-84 {
        from {
            transform: translate3d(69vw, 101vh, 0);
        }
        to {
            transform: translate3d(1vw, -102vh, 0);
        }
    }
    @keyframes move-frames-84 {
        from {
            transform: translate3d(69vw, 101vh, 0);
        }
        to {
            transform: translate3d(1vw, -102vh, 0);
        }
    }
    .circle-container:nth-child(84) .circle {
        -webkit-animation-delay: 530ms;
        animation-delay: 530ms;
    }
    .circle-container:nth-child(85) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-85;
        animation-name: move-frames-85;
        -webkit-animation-duration: 33109ms;
        animation-duration: 33109ms;
        -webkit-animation-delay: 1146ms;
        animation-delay: 1146ms;
    }
    @-webkit-keyframes move-frames-85 {
        from {
            transform: translate3d(10vw, 105vh, 0);
        }
        to {
            transform: translate3d(31vw, -131vh, 0);
        }
    }
    @keyframes move-frames-85 {
        from {
            transform: translate3d(10vw, 105vh, 0);
        }
        to {
            transform: translate3d(31vw, -131vh, 0);
        }
    }
    .circle-container:nth-child(85) .circle {
        -webkit-animation-delay: 2597ms;
        animation-delay: 2597ms;
    }
    .circle-container:nth-child(86) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-86;
        animation-name: move-frames-86;
        -webkit-animation-duration: 31542ms;
        animation-duration: 31542ms;
        -webkit-animation-delay: 28426ms;
        animation-delay: 28426ms;
    }
    @-webkit-keyframes move-frames-86 {
        from {
            transform: translate3d(70vw, 107vh, 0);
        }
        to {
            transform: translate3d(100vw, -117vh, 0);
        }
    }
    @keyframes move-frames-86 {
        from {
            transform: translate3d(70vw, 107vh, 0);
        }
        to {
            transform: translate3d(100vw, -117vh, 0);
        }
    }
    .circle-container:nth-child(86) .circle {
        -webkit-animation-delay: 2133ms;
        animation-delay: 2133ms;
    }
    .circle-container:nth-child(87) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-87;
        animation-name: move-frames-87;
        -webkit-animation-duration: 29975ms;
        animation-duration: 29975ms;
        -webkit-animation-delay: 19607ms;
        animation-delay: 19607ms;
    }
    @-webkit-keyframes move-frames-87 {
        from {
            transform: translate3d(73vw, 102vh, 0);
        }
        to {
            transform: translate3d(25vw, -108vh, 0);
        }
    }
    @keyframes move-frames-87 {
        from {
            transform: translate3d(73vw, 102vh, 0);
        }
        to {
            transform: translate3d(25vw, -108vh, 0);
        }
    }
    .circle-container:nth-child(87) .circle {
        -webkit-animation-delay: 1530ms;
        animation-delay: 1530ms;
    }
    .circle-container:nth-child(88) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-88;
        animation-name: move-frames-88;
        -webkit-animation-duration: 28458ms;
        animation-duration: 28458ms;
        -webkit-animation-delay: 24310ms;
        animation-delay: 24310ms;
    }
    @-webkit-keyframes move-frames-88 {
        from {
            transform: translate3d(27vw, 106vh, 0);
        }
        to {
            transform: translate3d(43vw, -130vh, 0);
        }
    }
    @keyframes move-frames-88 {
        from {
            transform: translate3d(27vw, 106vh, 0);
        }
        to {
            transform: translate3d(43vw, -130vh, 0);
        }
    }
    .circle-container:nth-child(88) .circle {
        -webkit-animation-delay: 2219ms;
        animation-delay: 2219ms;
    }
    .circle-container:nth-child(89) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-89;
        animation-name: move-frames-89;
        -webkit-animation-duration: 28692ms;
        animation-duration: 28692ms;
        -webkit-animation-delay: 27423ms;
        animation-delay: 27423ms;
    }
    @-webkit-keyframes move-frames-89 {
        from {
            transform: translate3d(24vw, 106vh, 0);
        }
        to {
            transform: translate3d(27vw, -116vh, 0);
        }
    }
    @keyframes move-frames-89 {
        from {
            transform: translate3d(24vw, 106vh, 0);
        }
        to {
            transform: translate3d(27vw, -116vh, 0);
        }
    }
    .circle-container:nth-child(89) .circle {
        -webkit-animation-delay: 1980ms;
        animation-delay: 1980ms;
    }
    .circle-container:nth-child(90) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-90;
        animation-name: move-frames-90;
        -webkit-animation-duration: 32621ms;
        animation-duration: 32621ms;
        -webkit-animation-delay: 32423ms;
        animation-delay: 32423ms;
    }
    @-webkit-keyframes move-frames-90 {
        from {
            transform: translate3d(87vw, 110vh, 0);
        }
        to {
            transform: translate3d(93vw, -136vh, 0);
        }
    }
    @keyframes move-frames-90 {
        from {
            transform: translate3d(87vw, 110vh, 0);
        }
        to {
            transform: translate3d(93vw, -136vh, 0);
        }
    }
    .circle-container:nth-child(90) .circle {
        -webkit-animation-delay: 3247ms;
        animation-delay: 3247ms;
    }
    .circle-container:nth-child(91) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-91;
        animation-name: move-frames-91;
        -webkit-animation-duration: 29541ms;
        animation-duration: 29541ms;
        -webkit-animation-delay: 29136ms;
        animation-delay: 29136ms;
    }
    @-webkit-keyframes move-frames-91 {
        from {
            transform: translate3d(82vw, 106vh, 0);
        }
        to {
            transform: translate3d(25vw, -115vh, 0);
        }
    }
    @keyframes move-frames-91 {
        from {
            transform: translate3d(82vw, 106vh, 0);
        }
        to {
            transform: translate3d(25vw, -115vh, 0);
        }
    }
    .circle-container:nth-child(91) .circle {
        -webkit-animation-delay: 1484ms;
        animation-delay: 1484ms;
    }
    .circle-container:nth-child(92) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-92;
        animation-name: move-frames-92;
        -webkit-animation-duration: 30493ms;
        animation-duration: 30493ms;
        -webkit-animation-delay: 27923ms;
        animation-delay: 27923ms;
    }
    @-webkit-keyframes move-frames-92 {
        from {
            transform: translate3d(49vw, 109vh, 0);
        }
        to {
            transform: translate3d(70vw, -117vh, 0);
        }
    }
    @keyframes move-frames-92 {
        from {
            transform: translate3d(49vw, 109vh, 0);
        }
        to {
            transform: translate3d(70vw, -117vh, 0);
        }
    }
    .circle-container:nth-child(92) .circle {
        -webkit-animation-delay: 3362ms;
        animation-delay: 3362ms;
    }
    .circle-container:nth-child(93) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-93;
        animation-name: move-frames-93;
        -webkit-animation-duration: 32182ms;
        animation-duration: 32182ms;
        -webkit-animation-delay: 32313ms;
        animation-delay: 32313ms;
    }
    @-webkit-keyframes move-frames-93 {
        from {
            transform: translate3d(65vw, 110vh, 0);
        }
        to {
            transform: translate3d(34vw, -120vh, 0);
        }
    }
    @keyframes move-frames-93 {
        from {
            transform: translate3d(65vw, 110vh, 0);
        }
        to {
            transform: translate3d(34vw, -120vh, 0);
        }
    }
    .circle-container:nth-child(93) .circle {
        -webkit-animation-delay: 3767ms;
        animation-delay: 3767ms;
    }
    .circle-container:nth-child(94) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-94;
        animation-name: move-frames-94;
        -webkit-animation-duration: 33078ms;
        animation-duration: 33078ms;
        -webkit-animation-delay: 6868ms;
        animation-delay: 6868ms;
    }
    @-webkit-keyframes move-frames-94 {
        from {
            transform: translate3d(63vw, 101vh, 0);
        }
        to {
            transform: translate3d(69vw, -106vh, 0);
        }
    }
    @keyframes move-frames-94 {
        from {
            transform: translate3d(63vw, 101vh, 0);
        }
        to {
            transform: translate3d(69vw, -106vh, 0);
        }
    }
    .circle-container:nth-child(94) .circle {
        -webkit-animation-delay: 2959ms;
        animation-delay: 2959ms;
    }
    .circle-container:nth-child(95) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-95;
        animation-name: move-frames-95;
        -webkit-animation-duration: 34889ms;
        animation-duration: 34889ms;
        -webkit-animation-delay: 33640ms;
        animation-delay: 33640ms;
    }
    @-webkit-keyframes move-frames-95 {
        from {
            transform: translate3d(73vw, 110vh, 0);
        }
        to {
            transform: translate3d(81vw, -136vh, 0);
        }
    }
    @keyframes move-frames-95 {
        from {
            transform: translate3d(73vw, 110vh, 0);
        }
        to {
            transform: translate3d(81vw, -136vh, 0);
        }
    }
    .circle-container:nth-child(95) .circle {
        -webkit-animation-delay: 183ms;
        animation-delay: 183ms;
    }
    .circle-container:nth-child(96) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-96;
        animation-name: move-frames-96;
        -webkit-animation-duration: 28615ms;
        animation-duration: 28615ms;
        -webkit-animation-delay: 21968ms;
        animation-delay: 21968ms;
    }
    @-webkit-keyframes move-frames-96 {
        from {
            transform: translate3d(87vw, 110vh, 0);
        }
        to {
            transform: translate3d(57vw, -116vh, 0);
        }
    }
    @keyframes move-frames-96 {
        from {
            transform: translate3d(87vw, 110vh, 0);
        }
        to {
            transform: translate3d(57vw, -116vh, 0);
        }
    }
    .circle-container:nth-child(96) .circle {
        -webkit-animation-delay: 57ms;
        animation-delay: 57ms;
    }
    .circle-container:nth-child(97) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-97;
        animation-name: move-frames-97;
        -webkit-animation-duration: 34106ms;
        animation-duration: 34106ms;
        -webkit-animation-delay: 36609ms;
        animation-delay: 36609ms;
    }
    @-webkit-keyframes move-frames-97 {
        from {
            transform: translate3d(82vw, 104vh, 0);
        }
        to {
            transform: translate3d(100vw, -130vh, 0);
        }
    }
    @keyframes move-frames-97 {
        from {
            transform: translate3d(82vw, 104vh, 0);
        }
        to {
            transform: translate3d(100vw, -130vh, 0);
        }
    }
    .circle-container:nth-child(97) .circle {
        -webkit-animation-delay: 1070ms;
        animation-delay: 1070ms;
    }
    .circle-container:nth-child(98) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-98;
        animation-name: move-frames-98;
        -webkit-animation-duration: 35543ms;
        animation-duration: 35543ms;
        -webkit-animation-delay: 24575ms;
        animation-delay: 24575ms;
    }
    @-webkit-keyframes move-frames-98 {
        from {
            transform: translate3d(97vw, 103vh, 0);
        }
        to {
            transform: translate3d(25vw, -122vh, 0);
        }
    }
    @keyframes move-frames-98 {
        from {
            transform: translate3d(97vw, 103vh, 0);
        }
        to {
            transform: translate3d(25vw, -122vh, 0);
        }
    }
    .circle-container:nth-child(98) .circle {
        -webkit-animation-delay: 1932ms;
        animation-delay: 1932ms;
    }
    .circle-container:nth-child(99) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-99;
        animation-name: move-frames-99;
        -webkit-animation-duration: 31513ms;
        animation-duration: 31513ms;
        -webkit-animation-delay: 8536ms;
        animation-delay: 8536ms;
    }
    @-webkit-keyframes move-frames-99 {
        from {
            transform: translate3d(91vw, 106vh, 0);
        }
        to {
            transform: translate3d(33vw, -134vh, 0);
        }
    }
    @keyframes move-frames-99 {
        from {
            transform: translate3d(91vw, 106vh, 0);
        }
        to {
            transform: translate3d(33vw, -134vh, 0);
        }
    }
    .circle-container:nth-child(99) .circle {
        -webkit-animation-delay: 333ms;
        animation-delay: 333ms;
    }
    .circle-container:nth-child(100) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-100;
        animation-name: move-frames-100;
        -webkit-animation-duration: 29374ms;
        animation-duration: 29374ms;
        -webkit-animation-delay: 9813ms;
        animation-delay: 9813ms;
    }
    @-webkit-keyframes move-frames-100 {
        from {
            transform: translate3d(74vw, 110vh, 0);
        }
        to {
            transform: translate3d(5vw, -140vh, 0);
        }
    }
    @keyframes move-frames-100 {
        from {
            transform: translate3d(74vw, 110vh, 0);
        }
        to {
            transform: translate3d(5vw, -140vh, 0);
        }
    }
    .circle-container:nth-child(100) .circle {
        -webkit-animation-delay: 3220ms;
        animation-delay: 3220ms;
    }
    .circle-container:nth-child(101) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-101;
        animation-name: move-frames-101;
        -webkit-animation-duration: 35890ms;
        animation-duration: 35890ms;
        -webkit-animation-delay: 4644ms;
        animation-delay: 4644ms;
    }
    @-webkit-keyframes move-frames-101 {
        from {
            transform: translate3d(86vw, 107vh, 0);
        }
        to {
            transform: translate3d(90vw, -136vh, 0);
        }
    }
    @keyframes move-frames-101 {
        from {
            transform: translate3d(86vw, 107vh, 0);
        }
        to {
            transform: translate3d(90vw, -136vh, 0);
        }
    }
    .circle-container:nth-child(101) .circle {
        -webkit-animation-delay: 1294ms;
        animation-delay: 1294ms;
    }
    .circle-container:nth-child(102) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-102;
        animation-name: move-frames-102;
        -webkit-animation-duration: 32001ms;
        animation-duration: 32001ms;
        -webkit-animation-delay: 12165ms;
        animation-delay: 12165ms;
    }
    @-webkit-keyframes move-frames-102 {
        from {
            transform: translate3d(47vw, 104vh, 0);
        }
        to {
            transform: translate3d(21vw, -117vh, 0);
        }
    }
    @keyframes move-frames-102 {
        from {
            transform: translate3d(47vw, 104vh, 0);
        }
        to {
            transform: translate3d(21vw, -117vh, 0);
        }
    }
    .circle-container:nth-child(102) .circle {
        -webkit-animation-delay: 1948ms;
        animation-delay: 1948ms;
    }
    .circle-container:nth-child(103) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-103;
        animation-name: move-frames-103;
        -webkit-animation-duration: 28438ms;
        animation-duration: 28438ms;
        -webkit-animation-delay: 22265ms;
        animation-delay: 22265ms;
    }
    @-webkit-keyframes move-frames-103 {
        from {
            transform: translate3d(73vw, 105vh, 0);
        }
        to {
            transform: translate3d(94vw, -130vh, 0);
        }
    }
    @keyframes move-frames-103 {
        from {
            transform: translate3d(73vw, 105vh, 0);
        }
        to {
            transform: translate3d(94vw, -130vh, 0);
        }
    }
    .circle-container:nth-child(103) .circle {
        -webkit-animation-delay: 2595ms;
        animation-delay: 2595ms;
    }
    .circle-container:nth-child(104) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-104;
        animation-name: move-frames-104;
        -webkit-animation-duration: 28905ms;
        animation-duration: 28905ms;
        -webkit-animation-delay: 35578ms;
        animation-delay: 35578ms;
    }
    @-webkit-keyframes move-frames-104 {
        from {
            transform: translate3d(6vw, 109vh, 0);
        }
        to {
            transform: translate3d(51vw, -119vh, 0);
        }
    }
    @keyframes move-frames-104 {
        from {
            transform: translate3d(6vw, 109vh, 0);
        }
        to {
            transform: translate3d(51vw, -119vh, 0);
        }
    }
    .circle-container:nth-child(104) .circle {
        -webkit-animation-delay: 2929ms;
        animation-delay: 2929ms;
    }
    .circle-container:nth-child(105) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-105;
        animation-name: move-frames-105;
        -webkit-animation-duration: 29912ms;
        animation-duration: 29912ms;
        -webkit-animation-delay: 17086ms;
        animation-delay: 17086ms;
    }
    @-webkit-keyframes move-frames-105 {
        from {
            transform: translate3d(41vw, 104vh, 0);
        }
        to {
            transform: translate3d(1vw, -124vh, 0);
        }
    }
    @keyframes move-frames-105 {
        from {
            transform: translate3d(41vw, 104vh, 0);
        }
        to {
            transform: translate3d(1vw, -124vh, 0);
        }
    }
    .circle-container:nth-child(105) .circle {
        -webkit-animation-delay: 3307ms;
        animation-delay: 3307ms;
    }
    .circle-container:nth-child(106) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-106;
        animation-name: move-frames-106;
        -webkit-animation-duration: 33382ms;
        animation-duration: 33382ms;
        -webkit-animation-delay: 24601ms;
        animation-delay: 24601ms;
    }
    @-webkit-keyframes move-frames-106 {
        from {
            transform: translate3d(33vw, 102vh, 0);
        }
        to {
            transform: translate3d(75vw, -116vh, 0);
        }
    }
    @keyframes move-frames-106 {
        from {
            transform: translate3d(33vw, 102vh, 0);
        }
        to {
            transform: translate3d(75vw, -116vh, 0);
        }
    }
    .circle-container:nth-child(106) .circle {
        -webkit-animation-delay: 2635ms;
        animation-delay: 2635ms;
    }
    .circle-container:nth-child(107) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-107;
        animation-name: move-frames-107;
        -webkit-animation-duration: 31840ms;
        animation-duration: 31840ms;
        -webkit-animation-delay: 16905ms;
        animation-delay: 16905ms;
    }
    @-webkit-keyframes move-frames-107 {
        from {
            transform: translate3d(16vw, 102vh, 0);
        }
        to {
            transform: translate3d(45vw, -125vh, 0);
        }
    }
    @keyframes move-frames-107 {
        from {
            transform: translate3d(16vw, 102vh, 0);
        }
        to {
            transform: translate3d(45vw, -125vh, 0);
        }
    }
    .circle-container:nth-child(107) .circle {
        -webkit-animation-delay: 205ms;
        animation-delay: 205ms;
    }
    .circle-container:nth-child(108) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-108;
        animation-name: move-frames-108;
        -webkit-animation-duration: 31682ms;
        animation-duration: 31682ms;
        -webkit-animation-delay: 2765ms;
        animation-delay: 2765ms;
    }
    @-webkit-keyframes move-frames-108 {
        from {
            transform: translate3d(48vw, 101vh, 0);
        }
        to {
            transform: translate3d(73vw, -123vh, 0);
        }
    }
    @keyframes move-frames-108 {
        from {
            transform: translate3d(48vw, 101vh, 0);
        }
        to {
            transform: translate3d(73vw, -123vh, 0);
        }
    }
    .circle-container:nth-child(108) .circle {
        -webkit-animation-delay: 3931ms;
        animation-delay: 3931ms;
    }
    .circle-container:nth-child(109) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-109;
        animation-name: move-frames-109;
        -webkit-animation-duration: 35956ms;
        animation-duration: 35956ms;
        -webkit-animation-delay: 11234ms;
        animation-delay: 11234ms;
    }
    @-webkit-keyframes move-frames-109 {
        from {
            transform: translate3d(94vw, 105vh, 0);
        }
        to {
            transform: translate3d(56vw, -112vh, 0);
        }
    }
    @keyframes move-frames-109 {
        from {
            transform: translate3d(94vw, 105vh, 0);
        }
        to {
            transform: translate3d(56vw, -112vh, 0);
        }
    }
    .circle-container:nth-child(109) .circle {
        -webkit-animation-delay: 3706ms;
        animation-delay: 3706ms;
    }
    .circle-container:nth-child(110) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-110;
        animation-name: move-frames-110;
        -webkit-animation-duration: 35292ms;
        animation-duration: 35292ms;
        -webkit-animation-delay: 15597ms;
        animation-delay: 15597ms;
    }
    @-webkit-keyframes move-frames-110 {
        from {
            transform: translate3d(27vw, 105vh, 0);
        }
        to {
            transform: translate3d(39vw, -122vh, 0);
        }
    }
    @keyframes move-frames-110 {
        from {
            transform: translate3d(27vw, 105vh, 0);
        }
        to {
            transform: translate3d(39vw, -122vh, 0);
        }
    }
    .circle-container:nth-child(110) .circle {
        -webkit-animation-delay: 774ms;
        animation-delay: 774ms;
    }
    .circle-container:nth-child(111) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-111;
        animation-name: move-frames-111;
        -webkit-animation-duration: 30081ms;
        animation-duration: 30081ms;
        -webkit-animation-delay: 22942ms;
        animation-delay: 22942ms;
    }
    @-webkit-keyframes move-frames-111 {
        from {
            transform: translate3d(46vw, 106vh, 0);
        }
        to {
            transform: translate3d(15vw, -109vh, 0);
        }
    }
    @keyframes move-frames-111 {
        from {
            transform: translate3d(46vw, 106vh, 0);
        }
        to {
            transform: translate3d(15vw, -109vh, 0);
        }
    }
    .circle-container:nth-child(111) .circle {
        -webkit-animation-delay: 2701ms;
        animation-delay: 2701ms;
    }
    .circle-container:nth-child(112) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-112;
        animation-name: move-frames-112;
        -webkit-animation-duration: 33520ms;
        animation-duration: 33520ms;
        -webkit-animation-delay: 8462ms;
        animation-delay: 8462ms;
    }
    @-webkit-keyframes move-frames-112 {
        from {
            transform: translate3d(57vw, 104vh, 0);
        }
        to {
            transform: translate3d(61vw, -130vh, 0);
        }
    }
    @keyframes move-frames-112 {
        from {
            transform: translate3d(57vw, 104vh, 0);
        }
        to {
            transform: translate3d(61vw, -130vh, 0);
        }
    }
    .circle-container:nth-child(112) .circle {
        -webkit-animation-delay: 315ms;
        animation-delay: 315ms;
    }
    .circle-container:nth-child(113) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-113;
        animation-name: move-frames-113;
        -webkit-animation-duration: 35630ms;
        animation-duration: 35630ms;
        -webkit-animation-delay: 30638ms;
        animation-delay: 30638ms;
    }
    @-webkit-keyframes move-frames-113 {
        from {
            transform: translate3d(81vw, 109vh, 0);
        }
        to {
            transform: translate3d(7vw, -123vh, 0);
        }
    }
    @keyframes move-frames-113 {
        from {
            transform: translate3d(81vw, 109vh, 0);
        }
        to {
            transform: translate3d(7vw, -123vh, 0);
        }
    }
    .circle-container:nth-child(113) .circle {
        -webkit-animation-delay: 2002ms;
        animation-delay: 2002ms;
    }
    .circle-container:nth-child(114) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-114;
        animation-name: move-frames-114;
        -webkit-animation-duration: 29855ms;
        animation-duration: 29855ms;
        -webkit-animation-delay: 13169ms;
        animation-delay: 13169ms;
    }
    @-webkit-keyframes move-frames-114 {
        from {
            transform: translate3d(100vw, 105vh, 0);
        }
        to {
            transform: translate3d(23vw, -117vh, 0);
        }
    }
    @keyframes move-frames-114 {
        from {
            transform: translate3d(100vw, 105vh, 0);
        }
        to {
            transform: translate3d(23vw, -117vh, 0);
        }
    }
    .circle-container:nth-child(114) .circle {
        -webkit-animation-delay: 3794ms;
        animation-delay: 3794ms;
    }
    .circle-container:nth-child(115) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-115;
        animation-name: move-frames-115;
        -webkit-animation-duration: 30025ms;
        animation-duration: 30025ms;
        -webkit-animation-delay: 20712ms;
        animation-delay: 20712ms;
    }
    @-webkit-keyframes move-frames-115 {
        from {
            transform: translate3d(15vw, 105vh, 0);
        }
        to {
            transform: translate3d(58vw, -115vh, 0);
        }
    }
    @keyframes move-frames-115 {
        from {
            transform: translate3d(15vw, 105vh, 0);
        }
        to {
            transform: translate3d(58vw, -115vh, 0);
        }
    }
    .circle-container:nth-child(115) .circle {
        -webkit-animation-delay: 3057ms;
        animation-delay: 3057ms;
    }
    .circle-container:nth-child(116) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-116;
        animation-name: move-frames-116;
        -webkit-animation-duration: 33999ms;
        animation-duration: 33999ms;
        -webkit-animation-delay: 12396ms;
        animation-delay: 12396ms;
    }
    @-webkit-keyframes move-frames-116 {
        from {
            transform: translate3d(42vw, 103vh, 0);
        }
        to {
            transform: translate3d(96vw, -124vh, 0);
        }
    }
    @keyframes move-frames-116 {
        from {
            transform: translate3d(42vw, 103vh, 0);
        }
        to {
            transform: translate3d(96vw, -124vh, 0);
        }
    }
    .circle-container:nth-child(116) .circle {
        -webkit-animation-delay: 2845ms;
        animation-delay: 2845ms;
    }
    .circle-container:nth-child(117) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-117;
        animation-name: move-frames-117;
        -webkit-animation-duration: 36955ms;
        animation-duration: 36955ms;
        -webkit-animation-delay: 15493ms;
        animation-delay: 15493ms;
    }
    @-webkit-keyframes move-frames-117 {
        from {
            transform: translate3d(84vw, 110vh, 0);
        }
        to {
            transform: translate3d(84vw, -136vh, 0);
        }
    }
    @keyframes move-frames-117 {
        from {
            transform: translate3d(84vw, 110vh, 0);
        }
        to {
            transform: translate3d(84vw, -136vh, 0);
        }
    }
    .circle-container:nth-child(117) .circle {
        -webkit-animation-delay: 2423ms;
        animation-delay: 2423ms;
    }
    .circle-container:nth-child(118) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-118;
        animation-name: move-frames-118;
        -webkit-animation-duration: 28508ms;
        animation-duration: 28508ms;
        -webkit-animation-delay: 305ms;
        animation-delay: 305ms;
    }
    @-webkit-keyframes move-frames-118 {
        from {
            transform: translate3d(96vw, 102vh, 0);
        }
        to {
            transform: translate3d(94vw, -117vh, 0);
        }
    }
    @keyframes move-frames-118 {
        from {
            transform: translate3d(96vw, 102vh, 0);
        }
        to {
            transform: translate3d(94vw, -117vh, 0);
        }
    }
    .circle-container:nth-child(118) .circle {
        -webkit-animation-delay: 2941ms;
        animation-delay: 2941ms;
    }
    .circle-container:nth-child(119) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-119;
        animation-name: move-frames-119;
        -webkit-animation-duration: 29849ms;
        animation-duration: 29849ms;
        -webkit-animation-delay: 1512ms;
        animation-delay: 1512ms;
    }
    @-webkit-keyframes move-frames-119 {
        from {
            transform: translate3d(41vw, 107vh, 0);
        }
        to {
            transform: translate3d(25vw, -116vh, 0);
        }
    }
    @keyframes move-frames-119 {
        from {
            transform: translate3d(41vw, 107vh, 0);
        }
        to {
            transform: translate3d(25vw, -116vh, 0);
        }
    }
    .circle-container:nth-child(119) .circle {
        -webkit-animation-delay: 354ms;
        animation-delay: 354ms;
    }
    .circle-container:nth-child(120) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-120;
        animation-name: move-frames-120;
        -webkit-animation-duration: 32296ms;
        animation-duration: 32296ms;
        -webkit-animation-delay: 540ms;
        animation-delay: 540ms;
    }
    @-webkit-keyframes move-frames-120 {
        from {
            transform: translate3d(95vw, 101vh, 0);
        }
        to {
            transform: translate3d(8vw, -128vh, 0);
        }
    }
    @keyframes move-frames-120 {
        from {
            transform: translate3d(95vw, 101vh, 0);
        }
        to {
            transform: translate3d(8vw, -128vh, 0);
        }
    }
    .circle-container:nth-child(120) .circle {
        -webkit-animation-delay: 2503ms;
        animation-delay: 2503ms;
    }
    .circle-container:nth-child(121) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-121;
        animation-name: move-frames-121;
        -webkit-animation-duration: 30113ms;
        animation-duration: 30113ms;
        -webkit-animation-delay: 30050ms;
        animation-delay: 30050ms;
    }
    @-webkit-keyframes move-frames-121 {
        from {
            transform: translate3d(49vw, 108vh, 0);
        }
        to {
            transform: translate3d(93vw, -115vh, 0);
        }
    }
    @keyframes move-frames-121 {
        from {
            transform: translate3d(49vw, 108vh, 0);
        }
        to {
            transform: translate3d(93vw, -115vh, 0);
        }
    }
    .circle-container:nth-child(121) .circle {
        -webkit-animation-delay: 3683ms;
        animation-delay: 3683ms;
    }
    .circle-container:nth-child(122) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-122;
        animation-name: move-frames-122;
        -webkit-animation-duration: 28176ms;
        animation-duration: 28176ms;
        -webkit-animation-delay: 34606ms;
        animation-delay: 34606ms;
    }
    @-webkit-keyframes move-frames-122 {
        from {
            transform: translate3d(44vw, 106vh, 0);
        }
        to {
            transform: translate3d(76vw, -115vh, 0);
        }
    }
    @keyframes move-frames-122 {
        from {
            transform: translate3d(44vw, 106vh, 0);
        }
        to {
            transform: translate3d(76vw, -115vh, 0);
        }
    }
    .circle-container:nth-child(122) .circle {
        -webkit-animation-delay: 3744ms;
        animation-delay: 3744ms;
    }
    .circle-container:nth-child(123) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-123;
        animation-name: move-frames-123;
        -webkit-animation-duration: 34370ms;
        animation-duration: 34370ms;
        -webkit-animation-delay: 5748ms;
        animation-delay: 5748ms;
    }
    @-webkit-keyframes move-frames-123 {
        from {
            transform: translate3d(70vw, 107vh, 0);
        }
        to {
            transform: translate3d(31vw, -117vh, 0);
        }
    }
    @keyframes move-frames-123 {
        from {
            transform: translate3d(70vw, 107vh, 0);
        }
        to {
            transform: translate3d(31vw, -117vh, 0);
        }
    }
    .circle-container:nth-child(123) .circle {
        -webkit-animation-delay: 2884ms;
        animation-delay: 2884ms;
    }
    .circle-container:nth-child(124) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-124;
        animation-name: move-frames-124;
        -webkit-animation-duration: 28013ms;
        animation-duration: 28013ms;
        -webkit-animation-delay: 32704ms;
        animation-delay: 32704ms;
    }
    @-webkit-keyframes move-frames-124 {
        from {
            transform: translate3d(72vw, 103vh, 0);
        }
        to {
            transform: translate3d(25vw, -107vh, 0);
        }
    }
    @keyframes move-frames-124 {
        from {
            transform: translate3d(72vw, 103vh, 0);
        }
        to {
            transform: translate3d(25vw, -107vh, 0);
        }
    }
    .circle-container:nth-child(124) .circle {
        -webkit-animation-delay: 3722ms;
        animation-delay: 3722ms;
    }
    .circle-container:nth-child(125) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-125;
        animation-name: move-frames-125;
        -webkit-animation-duration: 30747ms;
        animation-duration: 30747ms;
        -webkit-animation-delay: 32421ms;
        animation-delay: 32421ms;
    }
    @-webkit-keyframes move-frames-125 {
        from {
            transform: translate3d(17vw, 106vh, 0);
        }
        to {
            transform: translate3d(28vw, -119vh, 0);
        }
    }
    @keyframes move-frames-125 {
        from {
            transform: translate3d(17vw, 106vh, 0);
        }
        to {
            transform: translate3d(28vw, -119vh, 0);
        }
    }
    .circle-container:nth-child(125) .circle {
        -webkit-animation-delay: 2728ms;
        animation-delay: 2728ms;
    }
    .circle-container:nth-child(126) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-126;
        animation-name: move-frames-126;
        -webkit-animation-duration: 28093ms;
        animation-duration: 28093ms;
        -webkit-animation-delay: 28140ms;
        animation-delay: 28140ms;
    }
    @-webkit-keyframes move-frames-126 {
        from {
            transform: translate3d(17vw, 106vh, 0);
        }
        to {
            transform: translate3d(88vw, -113vh, 0);
        }
    }
    @keyframes move-frames-126 {
        from {
            transform: translate3d(17vw, 106vh, 0);
        }
        to {
            transform: translate3d(88vw, -113vh, 0);
        }
    }
    .circle-container:nth-child(126) .circle {
        -webkit-animation-delay: 1115ms;
        animation-delay: 1115ms;
    }
    .circle-container:nth-child(127) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-127;
        animation-name: move-frames-127;
        -webkit-animation-duration: 31267ms;
        animation-duration: 31267ms;
        -webkit-animation-delay: 350ms;
        animation-delay: 350ms;
    }
    @-webkit-keyframes move-frames-127 {
        from {
            transform: translate3d(26vw, 107vh, 0);
        }
        to {
            transform: translate3d(9vw, -115vh, 0);
        }
    }
    @keyframes move-frames-127 {
        from {
            transform: translate3d(26vw, 107vh, 0);
        }
        to {
            transform: translate3d(9vw, -115vh, 0);
        }
    }
    .circle-container:nth-child(127) .circle {
        -webkit-animation-delay: 3464ms;
        animation-delay: 3464ms;
    }
    .circle-container:nth-child(128) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-128;
        animation-name: move-frames-128;
        -webkit-animation-duration: 30429ms;
        animation-duration: 30429ms;
        -webkit-animation-delay: 32735ms;
        animation-delay: 32735ms;
    }
    @-webkit-keyframes move-frames-128 {
        from {
            transform: translate3d(47vw, 101vh, 0);
        }
        to {
            transform: translate3d(61vw, -127vh, 0);
        }
    }
    @keyframes move-frames-128 {
        from {
            transform: translate3d(47vw, 101vh, 0);
        }
        to {
            transform: translate3d(61vw, -127vh, 0);
        }
    }
    .circle-container:nth-child(128) .circle {
        -webkit-animation-delay: 3413ms;
        animation-delay: 3413ms;
    }
    .circle-container:nth-child(129) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-129;
        animation-name: move-frames-129;
        -webkit-animation-duration: 32169ms;
        animation-duration: 32169ms;
        -webkit-animation-delay: 30495ms;
        animation-delay: 30495ms;
    }
    @-webkit-keyframes move-frames-129 {
        from {
            transform: translate3d(66vw, 104vh, 0);
        }
        to {
            transform: translate3d(90vw, -114vh, 0);
        }
    }
    @keyframes move-frames-129 {
        from {
            transform: translate3d(66vw, 104vh, 0);
        }
        to {
            transform: translate3d(90vw, -114vh, 0);
        }
    }
    .circle-container:nth-child(129) .circle {
        -webkit-animation-delay: 487ms;
        animation-delay: 487ms;
    }
    .circle-container:nth-child(130) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-130;
        animation-name: move-frames-130;
        -webkit-animation-duration: 31559ms;
        animation-duration: 31559ms;
        -webkit-animation-delay: 7401ms;
        animation-delay: 7401ms;
    }
    @-webkit-keyframes move-frames-130 {
        from {
            transform: translate3d(2vw, 103vh, 0);
        }
        to {
            transform: translate3d(67vw, -110vh, 0);
        }
    }
    @keyframes move-frames-130 {
        from {
            transform: translate3d(2vw, 103vh, 0);
        }
        to {
            transform: translate3d(67vw, -110vh, 0);
        }
    }
    .circle-container:nth-child(130) .circle {
        -webkit-animation-delay: 2229ms;
        animation-delay: 2229ms;
    }
    .circle-container:nth-child(131) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-131;
        animation-name: move-frames-131;
        -webkit-animation-duration: 30816ms;
        animation-duration: 30816ms;
        -webkit-animation-delay: 13997ms;
        animation-delay: 13997ms;
    }
    @-webkit-keyframes move-frames-131 {
        from {
            transform: translate3d(31vw, 107vh, 0);
        }
        to {
            transform: translate3d(60vw, -116vh, 0);
        }
    }
    @keyframes move-frames-131 {
        from {
            transform: translate3d(31vw, 107vh, 0);
        }
        to {
            transform: translate3d(60vw, -116vh, 0);
        }
    }
    .circle-container:nth-child(131) .circle {
        -webkit-animation-delay: 743ms;
        animation-delay: 743ms;
    }
    .circle-container:nth-child(132) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-132;
        animation-name: move-frames-132;
        -webkit-animation-duration: 29642ms;
        animation-duration: 29642ms;
        -webkit-animation-delay: 14116ms;
        animation-delay: 14116ms;
    }
    @-webkit-keyframes move-frames-132 {
        from {
            transform: translate3d(70vw, 104vh, 0);
        }
        to {
            transform: translate3d(96vw, -107vh, 0);
        }
    }
    @keyframes move-frames-132 {
        from {
            transform: translate3d(70vw, 104vh, 0);
        }
        to {
            transform: translate3d(96vw, -107vh, 0);
        }
    }
    .circle-container:nth-child(132) .circle {
        -webkit-animation-delay: 1694ms;
        animation-delay: 1694ms;
    }
    .circle-container:nth-child(133) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-133;
        animation-name: move-frames-133;
        -webkit-animation-duration: 28261ms;
        animation-duration: 28261ms;
        -webkit-animation-delay: 7409ms;
        animation-delay: 7409ms;
    }
    @-webkit-keyframes move-frames-133 {
        from {
            transform: translate3d(82vw, 105vh, 0);
        }
        to {
            transform: translate3d(50vw, -111vh, 0);
        }
    }
    @keyframes move-frames-133 {
        from {
            transform: translate3d(82vw, 105vh, 0);
        }
        to {
            transform: translate3d(50vw, -111vh, 0);
        }
    }
    .circle-container:nth-child(133) .circle {
        -webkit-animation-delay: 2790ms;
        animation-delay: 2790ms;
    }
    .circle-container:nth-child(134) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-134;
        animation-name: move-frames-134;
        -webkit-animation-duration: 29408ms;
        animation-duration: 29408ms;
        -webkit-animation-delay: 15092ms;
        animation-delay: 15092ms;
    }
    @-webkit-keyframes move-frames-134 {
        from {
            transform: translate3d(40vw, 104vh, 0);
        }
        to {
            transform: translate3d(37vw, -113vh, 0);
        }
    }
    @keyframes move-frames-134 {
        from {
            transform: translate3d(40vw, 104vh, 0);
        }
        to {
            transform: translate3d(37vw, -113vh, 0);
        }
    }
    .circle-container:nth-child(134) .circle {
        -webkit-animation-delay: 1ms;
        animation-delay: 1ms;
    }
    .circle-container:nth-child(135) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-135;
        animation-name: move-frames-135;
        -webkit-animation-duration: 34628ms;
        animation-duration: 34628ms;
        -webkit-animation-delay: 706ms;
        animation-delay: 706ms;
    }
    @-webkit-keyframes move-frames-135 {
        from {
            transform: translate3d(33vw, 109vh, 0);
        }
        to {
            transform: translate3d(17vw, -124vh, 0);
        }
    }
    @keyframes move-frames-135 {
        from {
            transform: translate3d(33vw, 109vh, 0);
        }
        to {
            transform: translate3d(17vw, -124vh, 0);
        }
    }
    .circle-container:nth-child(135) .circle {
        -webkit-animation-delay: 1023ms;
        animation-delay: 1023ms;
    }
    .circle-container:nth-child(136) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-136;
        animation-name: move-frames-136;
        -webkit-animation-duration: 31170ms;
        animation-duration: 31170ms;
        -webkit-animation-delay: 23024ms;
        animation-delay: 23024ms;
    }
    @-webkit-keyframes move-frames-136 {
        from {
            transform: translate3d(40vw, 106vh, 0);
        }
        to {
            transform: translate3d(86vw, -120vh, 0);
        }
    }
    @keyframes move-frames-136 {
        from {
            transform: translate3d(40vw, 106vh, 0);
        }
        to {
            transform: translate3d(86vw, -120vh, 0);
        }
    }
    .circle-container:nth-child(136) .circle {
        -webkit-animation-delay: 546ms;
        animation-delay: 546ms;
    }
    .circle-container:nth-child(137) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-137;
        animation-name: move-frames-137;
        -webkit-animation-duration: 31578ms;
        animation-duration: 31578ms;
        -webkit-animation-delay: 5318ms;
        animation-delay: 5318ms;
    }
    @-webkit-keyframes move-frames-137 {
        from {
            transform: translate3d(48vw, 107vh, 0);
        }
        to {
            transform: translate3d(89vw, -120vh, 0);
        }
    }
    @keyframes move-frames-137 {
        from {
            transform: translate3d(48vw, 107vh, 0);
        }
        to {
            transform: translate3d(89vw, -120vh, 0);
        }
    }
    .circle-container:nth-child(137) .circle {
        -webkit-animation-delay: 2158ms;
        animation-delay: 2158ms;
    }
    .circle-container:nth-child(138) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-138;
        animation-name: move-frames-138;
        -webkit-animation-duration: 34165ms;
        animation-duration: 34165ms;
        -webkit-animation-delay: 26544ms;
        animation-delay: 26544ms;
    }
    @-webkit-keyframes move-frames-138 {
        from {
            transform: translate3d(30vw, 104vh, 0);
        }
        to {
            transform: translate3d(40vw, -119vh, 0);
        }
    }
    @keyframes move-frames-138 {
        from {
            transform: translate3d(30vw, 104vh, 0);
        }
        to {
            transform: translate3d(40vw, -119vh, 0);
        }
    }
    .circle-container:nth-child(138) .circle {
        -webkit-animation-delay: 420ms;
        animation-delay: 420ms;
    }
    .circle-container:nth-child(139) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-139;
        animation-name: move-frames-139;
        -webkit-animation-duration: 36767ms;
        animation-duration: 36767ms;
        -webkit-animation-delay: 7448ms;
        animation-delay: 7448ms;
    }
    @-webkit-keyframes move-frames-139 {
        from {
            transform: translate3d(91vw, 109vh, 0);
        }
        to {
            transform: translate3d(83vw, -124vh, 0);
        }
    }
    @keyframes move-frames-139 {
        from {
            transform: translate3d(91vw, 109vh, 0);
        }
        to {
            transform: translate3d(83vw, -124vh, 0);
        }
    }
    .circle-container:nth-child(139) .circle {
        -webkit-animation-delay: 3676ms;
        animation-delay: 3676ms;
    }
    .circle-container:nth-child(140) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-140;
        animation-name: move-frames-140;
        -webkit-animation-duration: 34605ms;
        animation-duration: 34605ms;
        -webkit-animation-delay: 35403ms;
        animation-delay: 35403ms;
    }
    @-webkit-keyframes move-frames-140 {
        from {
            transform: translate3d(60vw, 103vh, 0);
        }
        to {
            transform: translate3d(31vw, -104vh, 0);
        }
    }
    @keyframes move-frames-140 {
        from {
            transform: translate3d(60vw, 103vh, 0);
        }
        to {
            transform: translate3d(31vw, -104vh, 0);
        }
    }
    .circle-container:nth-child(140) .circle {
        -webkit-animation-delay: 2777ms;
        animation-delay: 2777ms;
    }
    .circle-container:nth-child(141) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-141;
        animation-name: move-frames-141;
        -webkit-animation-duration: 35363ms;
        animation-duration: 35363ms;
        -webkit-animation-delay: 14631ms;
        animation-delay: 14631ms;
    }
    @-webkit-keyframes move-frames-141 {
        from {
            transform: translate3d(9vw, 110vh, 0);
        }
        to {
            transform: translate3d(67vw, -115vh, 0);
        }
    }
    @keyframes move-frames-141 {
        from {
            transform: translate3d(9vw, 110vh, 0);
        }
        to {
            transform: translate3d(67vw, -115vh, 0);
        }
    }
    .circle-container:nth-child(141) .circle {
        -webkit-animation-delay: 2950ms;
        animation-delay: 2950ms;
    }
    .circle-container:nth-child(142) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-142;
        animation-name: move-frames-142;
        -webkit-animation-duration: 32851ms;
        animation-duration: 32851ms;
        -webkit-animation-delay: 20088ms;
        animation-delay: 20088ms;
    }
    @-webkit-keyframes move-frames-142 {
        from {
            transform: translate3d(88vw, 110vh, 0);
        }
        to {
            transform: translate3d(87vw, -111vh, 0);
        }
    }
    @keyframes move-frames-142 {
        from {
            transform: translate3d(88vw, 110vh, 0);
        }
        to {
            transform: translate3d(87vw, -111vh, 0);
        }
    }
    .circle-container:nth-child(142) .circle {
        -webkit-animation-delay: 1065ms;
        animation-delay: 1065ms;
    }
    .circle-container:nth-child(143) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-143;
        animation-name: move-frames-143;
        -webkit-animation-duration: 36599ms;
        animation-duration: 36599ms;
        -webkit-animation-delay: 24879ms;
        animation-delay: 24879ms;
    }
    @-webkit-keyframes move-frames-143 {
        from {
            transform: translate3d(21vw, 102vh, 0);
        }
        to {
            transform: translate3d(85vw, -130vh, 0);
        }
    }
    @keyframes move-frames-143 {
        from {
            transform: translate3d(21vw, 102vh, 0);
        }
        to {
            transform: translate3d(85vw, -130vh, 0);
        }
    }
    .circle-container:nth-child(143) .circle {
        -webkit-animation-delay: 6ms;
        animation-delay: 6ms;
    }
    .circle-container:nth-child(144) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-144;
        animation-name: move-frames-144;
        -webkit-animation-duration: 28100ms;
        animation-duration: 28100ms;
        -webkit-animation-delay: 36658ms;
        animation-delay: 36658ms;
    }
    @-webkit-keyframes move-frames-144 {
        from {
            transform: translate3d(57vw, 105vh, 0);
        }
        to {
            transform: translate3d(38vw, -134vh, 0);
        }
    }
    @keyframes move-frames-144 {
        from {
            transform: translate3d(57vw, 105vh, 0);
        }
        to {
            transform: translate3d(38vw, -134vh, 0);
        }
    }
    .circle-container:nth-child(144) .circle {
        -webkit-animation-delay: 1511ms;
        animation-delay: 1511ms;
    }
    .circle-container:nth-child(145) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-145;
        animation-name: move-frames-145;
        -webkit-animation-duration: 29605ms;
        animation-duration: 29605ms;
        -webkit-animation-delay: 32928ms;
        animation-delay: 32928ms;
    }
    @-webkit-keyframes move-frames-145 {
        from {
            transform: translate3d(74vw, 103vh, 0);
        }
        to {
            transform: translate3d(79vw, -105vh, 0);
        }
    }
    @keyframes move-frames-145 {
        from {
            transform: translate3d(74vw, 103vh, 0);
        }
        to {
            transform: translate3d(79vw, -105vh, 0);
        }
    }
    .circle-container:nth-child(145) .circle {
        -webkit-animation-delay: 3058ms;
        animation-delay: 3058ms;
    }
    .circle-container:nth-child(146) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-146;
        animation-name: move-frames-146;
        -webkit-animation-duration: 36412ms;
        animation-duration: 36412ms;
        -webkit-animation-delay: 16869ms;
        animation-delay: 16869ms;
    }
    @-webkit-keyframes move-frames-146 {
        from {
            transform: translate3d(35vw, 104vh, 0);
        }
        to {
            transform: translate3d(42vw, -113vh, 0);
        }
    }
    @keyframes move-frames-146 {
        from {
            transform: translate3d(35vw, 104vh, 0);
        }
        to {
            transform: translate3d(42vw, -113vh, 0);
        }
    }
    .circle-container:nth-child(146) .circle {
        -webkit-animation-delay: 3819ms;
        animation-delay: 3819ms;
    }
    .circle-container:nth-child(147) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-147;
        animation-name: move-frames-147;
        -webkit-animation-duration: 35459ms;
        animation-duration: 35459ms;
        -webkit-animation-delay: 33110ms;
        animation-delay: 33110ms;
    }
    @-webkit-keyframes move-frames-147 {
        from {
            transform: translate3d(88vw, 108vh, 0);
        }
        to {
            transform: translate3d(66vw, -126vh, 0);
        }
    }
    @keyframes move-frames-147 {
        from {
            transform: translate3d(88vw, 108vh, 0);
        }
        to {
            transform: translate3d(66vw, -126vh, 0);
        }
    }
    .circle-container:nth-child(147) .circle {
        -webkit-animation-delay: 2682ms;
        animation-delay: 2682ms;
    }
    .circle-container:nth-child(148) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-148;
        animation-name: move-frames-148;
        -webkit-animation-duration: 34710ms;
        animation-duration: 34710ms;
        -webkit-animation-delay: 32511ms;
        animation-delay: 32511ms;
    }
    @-webkit-keyframes move-frames-148 {
        from {
            transform: translate3d(90vw, 109vh, 0);
        }
        to {
            transform: translate3d(75vw, -123vh, 0);
        }
    }
    @keyframes move-frames-148 {
        from {
            transform: translate3d(90vw, 109vh, 0);
        }
        to {
            transform: translate3d(75vw, -123vh, 0);
        }
    }
    .circle-container:nth-child(148) .circle {
        -webkit-animation-delay: 1867ms;
        animation-delay: 1867ms;
    }
    .circle-container:nth-child(149) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-149;
        animation-name: move-frames-149;
        -webkit-animation-duration: 36389ms;
        animation-duration: 36389ms;
        -webkit-animation-delay: 27083ms;
        animation-delay: 27083ms;
    }
    @-webkit-keyframes move-frames-149 {
        from {
            transform: translate3d(34vw, 107vh, 0);
        }
        to {
            transform: translate3d(99vw, -114vh, 0);
        }
    }
    @keyframes move-frames-149 {
        from {
            transform: translate3d(34vw, 107vh, 0);
        }
        to {
            transform: translate3d(99vw, -114vh, 0);
        }
    }
    .circle-container:nth-child(149) .circle {
        -webkit-animation-delay: 3992ms;
        animation-delay: 3992ms;
    }
    .circle-container:nth-child(150) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-150;
        animation-name: move-frames-150;
        -webkit-animation-duration: 30990ms;
        animation-duration: 30990ms;
        -webkit-animation-delay: 34561ms;
        animation-delay: 34561ms;
    }
    @-webkit-keyframes move-frames-150 {
        from {
            transform: translate3d(2vw, 108vh, 0);
        }
        to {
            transform: translate3d(35vw, -125vh, 0);
        }
    }
    @keyframes move-frames-150 {
        from {
            transform: translate3d(2vw, 108vh, 0);
        }
        to {
            transform: translate3d(35vw, -125vh, 0);
        }
    }
    .circle-container:nth-child(150) .circle {
        -webkit-animation-delay: 3898ms;
        animation-delay: 3898ms;
    }
    .circle-container:nth-child(151) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-151;
        animation-name: move-frames-151;
        -webkit-animation-duration: 34043ms;
        animation-duration: 34043ms;
        -webkit-animation-delay: 11752ms;
        animation-delay: 11752ms;
    }
    @-webkit-keyframes move-frames-151 {
        from {
            transform: translate3d(12vw, 102vh, 0);
        }
        to {
            transform: translate3d(64vw, -112vh, 0);
        }
    }
    @keyframes move-frames-151 {
        from {
            transform: translate3d(12vw, 102vh, 0);
        }
        to {
            transform: translate3d(64vw, -112vh, 0);
        }
    }
    .circle-container:nth-child(151) .circle {
        -webkit-animation-delay: 2070ms;
        animation-delay: 2070ms;
    }
    .circle-container:nth-child(152) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-152;
        animation-name: move-frames-152;
        -webkit-animation-duration: 28807ms;
        animation-duration: 28807ms;
        -webkit-animation-delay: 11768ms;
        animation-delay: 11768ms;
    }
    @-webkit-keyframes move-frames-152 {
        from {
            transform: translate3d(71vw, 101vh, 0);
        }
        to {
            transform: translate3d(87vw, -106vh, 0);
        }
    }
    @keyframes move-frames-152 {
        from {
            transform: translate3d(71vw, 101vh, 0);
        }
        to {
            transform: translate3d(87vw, -106vh, 0);
        }
    }
    .circle-container:nth-child(152) .circle {
        -webkit-animation-delay: 3766ms;
        animation-delay: 3766ms;
    }
    .circle-container:nth-child(153) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-153;
        animation-name: move-frames-153;
        -webkit-animation-duration: 34234ms;
        animation-duration: 34234ms;
        -webkit-animation-delay: 559ms;
        animation-delay: 559ms;
    }
    @-webkit-keyframes move-frames-153 {
        from {
            transform: translate3d(32vw, 110vh, 0);
        }
        to {
            transform: translate3d(42vw, -116vh, 0);
        }
    }
    @keyframes move-frames-153 {
        from {
            transform: translate3d(32vw, 110vh, 0);
        }
        to {
            transform: translate3d(42vw, -116vh, 0);
        }
    }
    .circle-container:nth-child(153) .circle {
        -webkit-animation-delay: 622ms;
        animation-delay: 622ms;
    }
    .circle-container:nth-child(154) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-154;
        animation-name: move-frames-154;
        -webkit-animation-duration: 28893ms;
        animation-duration: 28893ms;
        -webkit-animation-delay: 33760ms;
        animation-delay: 33760ms;
    }
    @-webkit-keyframes move-frames-154 {
        from {
            transform: translate3d(87vw, 106vh, 0);
        }
        to {
            transform: translate3d(3vw, -126vh, 0);
        }
    }
    @keyframes move-frames-154 {
        from {
            transform: translate3d(87vw, 106vh, 0);
        }
        to {
            transform: translate3d(3vw, -126vh, 0);
        }
    }
    .circle-container:nth-child(154) .circle {
        -webkit-animation-delay: 2278ms;
        animation-delay: 2278ms;
    }
    .circle-container:nth-child(155) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-155;
        animation-name: move-frames-155;
        -webkit-animation-duration: 36209ms;
        animation-duration: 36209ms;
        -webkit-animation-delay: 17944ms;
        animation-delay: 17944ms;
    }
    @-webkit-keyframes move-frames-155 {
        from {
            transform: translate3d(28vw, 102vh, 0);
        }
        to {
            transform: translate3d(34vw, -113vh, 0);
        }
    }
    @keyframes move-frames-155 {
        from {
            transform: translate3d(28vw, 102vh, 0);
        }
        to {
            transform: translate3d(34vw, -113vh, 0);
        }
    }
    .circle-container:nth-child(155) .circle {
        -webkit-animation-delay: 726ms;
        animation-delay: 726ms;
    }
    .circle-container:nth-child(156) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-156;
        animation-name: move-frames-156;
        -webkit-animation-duration: 31143ms;
        animation-duration: 31143ms;
        -webkit-animation-delay: 20343ms;
        animation-delay: 20343ms;
    }
    @-webkit-keyframes move-frames-156 {
        from {
            transform: translate3d(52vw, 108vh, 0);
        }
        to {
            transform: translate3d(16vw, -136vh, 0);
        }
    }
    @keyframes move-frames-156 {
        from {
            transform: translate3d(52vw, 108vh, 0);
        }
        to {
            transform: translate3d(16vw, -136vh, 0);
        }
    }
    .circle-container:nth-child(156) .circle {
        -webkit-animation-delay: 704ms;
        animation-delay: 704ms;
    }
    .circle-container:nth-child(157) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-157;
        animation-name: move-frames-157;
        -webkit-animation-duration: 31393ms;
        animation-duration: 31393ms;
        -webkit-animation-delay: 7670ms;
        animation-delay: 7670ms;
    }
    @-webkit-keyframes move-frames-157 {
        from {
            transform: translate3d(3vw, 102vh, 0);
        }
        to {
            transform: translate3d(19vw, -122vh, 0);
        }
    }
    @keyframes move-frames-157 {
        from {
            transform: translate3d(3vw, 102vh, 0);
        }
        to {
            transform: translate3d(19vw, -122vh, 0);
        }
    }
    .circle-container:nth-child(157) .circle {
        -webkit-animation-delay: 1172ms;
        animation-delay: 1172ms;
    }
    .circle-container:nth-child(158) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-158;
        animation-name: move-frames-158;
        -webkit-animation-duration: 34850ms;
        animation-duration: 34850ms;
        -webkit-animation-delay: 22979ms;
        animation-delay: 22979ms;
    }
    @-webkit-keyframes move-frames-158 {
        from {
            transform: translate3d(92vw, 109vh, 0);
        }
        to {
            transform: translate3d(83vw, -112vh, 0);
        }
    }
    @keyframes move-frames-158 {
        from {
            transform: translate3d(92vw, 109vh, 0);
        }
        to {
            transform: translate3d(83vw, -112vh, 0);
        }
    }
    .circle-container:nth-child(158) .circle {
        -webkit-animation-delay: 1641ms;
        animation-delay: 1641ms;
    }
    .circle-container:nth-child(159) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-159;
        animation-name: move-frames-159;
        -webkit-animation-duration: 30739ms;
        animation-duration: 30739ms;
        -webkit-animation-delay: 20808ms;
        animation-delay: 20808ms;
    }
    @-webkit-keyframes move-frames-159 {
        from {
            transform: translate3d(98vw, 106vh, 0);
        }
        to {
            transform: translate3d(36vw, -117vh, 0);
        }
    }
    @keyframes move-frames-159 {
        from {
            transform: translate3d(98vw, 106vh, 0);
        }
        to {
            transform: translate3d(36vw, -117vh, 0);
        }
    }
    .circle-container:nth-child(159) .circle {
        -webkit-animation-delay: 3125ms;
        animation-delay: 3125ms;
    }
    .circle-container:nth-child(160) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-160;
        animation-name: move-frames-160;
        -webkit-animation-duration: 34829ms;
        animation-duration: 34829ms;
        -webkit-animation-delay: 15978ms;
        animation-delay: 15978ms;
    }
    @-webkit-keyframes move-frames-160 {
        from {
            transform: translate3d(15vw, 107vh, 0);
        }
        to {
            transform: translate3d(39vw, -125vh, 0);
        }
    }
    @keyframes move-frames-160 {
        from {
            transform: translate3d(15vw, 107vh, 0);
        }
        to {
            transform: translate3d(39vw, -125vh, 0);
        }
    }
    .circle-container:nth-child(160) .circle {
        -webkit-animation-delay: 3701ms;
        animation-delay: 3701ms;
    }
    .circle-container:nth-child(161) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-161;
        animation-name: move-frames-161;
        -webkit-animation-duration: 32367ms;
        animation-duration: 32367ms;
        -webkit-animation-delay: 34028ms;
        animation-delay: 34028ms;
    }
    @-webkit-keyframes move-frames-161 {
        from {
            transform: translate3d(15vw, 106vh, 0);
        }
        to {
            transform: translate3d(71vw, -115vh, 0);
        }
    }
    @keyframes move-frames-161 {
        from {
            transform: translate3d(15vw, 106vh, 0);
        }
        to {
            transform: translate3d(71vw, -115vh, 0);
        }
    }
    .circle-container:nth-child(161) .circle {
        -webkit-animation-delay: 1092ms;
        animation-delay: 1092ms;
    }
    .circle-container:nth-child(162) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-162;
        animation-name: move-frames-162;
        -webkit-animation-duration: 31783ms;
        animation-duration: 31783ms;
        -webkit-animation-delay: 4303ms;
        animation-delay: 4303ms;
    }
    @-webkit-keyframes move-frames-162 {
        from {
            transform: translate3d(76vw, 108vh, 0);
        }
        to {
            transform: translate3d(53vw, -120vh, 0);
        }
    }
    @keyframes move-frames-162 {
        from {
            transform: translate3d(76vw, 108vh, 0);
        }
        to {
            transform: translate3d(53vw, -120vh, 0);
        }
    }
    .circle-container:nth-child(162) .circle {
        -webkit-animation-delay: 1061ms;
        animation-delay: 1061ms;
    }
    .circle-container:nth-child(163) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-163;
        animation-name: move-frames-163;
        -webkit-animation-duration: 35212ms;
        animation-duration: 35212ms;
        -webkit-animation-delay: 9972ms;
        animation-delay: 9972ms;
    }
    @-webkit-keyframes move-frames-163 {
        from {
            transform: translate3d(64vw, 109vh, 0);
        }
        to {
            transform: translate3d(10vw, -116vh, 0);
        }
    }
    @keyframes move-frames-163 {
        from {
            transform: translate3d(64vw, 109vh, 0);
        }
        to {
            transform: translate3d(10vw, -116vh, 0);
        }
    }
    .circle-container:nth-child(163) .circle {
        -webkit-animation-delay: 2364ms;
        animation-delay: 2364ms;
    }
    .circle-container:nth-child(164) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-164;
        animation-name: move-frames-164;
        -webkit-animation-duration: 32303ms;
        animation-duration: 32303ms;
        -webkit-animation-delay: 31834ms;
        animation-delay: 31834ms;
    }
    @-webkit-keyframes move-frames-164 {
        from {
            transform: translate3d(64vw, 107vh, 0);
        }
        to {
            transform: translate3d(11vw, -125vh, 0);
        }
    }
    @keyframes move-frames-164 {
        from {
            transform: translate3d(64vw, 107vh, 0);
        }
        to {
            transform: translate3d(11vw, -125vh, 0);
        }
    }
    .circle-container:nth-child(164) .circle {
        -webkit-animation-delay: 2687ms;
        animation-delay: 2687ms;
    }
    .circle-container:nth-child(165) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-165;
        animation-name: move-frames-165;
        -webkit-animation-duration: 28512ms;
        animation-duration: 28512ms;
        -webkit-animation-delay: 13853ms;
        animation-delay: 13853ms;
    }
    @-webkit-keyframes move-frames-165 {
        from {
            transform: translate3d(97vw, 109vh, 0);
        }
        to {
            transform: translate3d(24vw, -123vh, 0);
        }
    }
    @keyframes move-frames-165 {
        from {
            transform: translate3d(97vw, 109vh, 0);
        }
        to {
            transform: translate3d(24vw, -123vh, 0);
        }
    }
    .circle-container:nth-child(165) .circle {
        -webkit-animation-delay: 323ms;
        animation-delay: 323ms;
    }
    .circle-container:nth-child(166) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-166;
        animation-name: move-frames-166;
        -webkit-animation-duration: 29625ms;
        animation-duration: 29625ms;
        -webkit-animation-delay: 29880ms;
        animation-delay: 29880ms;
    }
    @-webkit-keyframes move-frames-166 {
        from {
            transform: translate3d(55vw, 106vh, 0);
        }
        to {
            transform: translate3d(100vw, -119vh, 0);
        }
    }
    @keyframes move-frames-166 {
        from {
            transform: translate3d(55vw, 106vh, 0);
        }
        to {
            transform: translate3d(100vw, -119vh, 0);
        }
    }
    .circle-container:nth-child(166) .circle {
        -webkit-animation-delay: 2391ms;
        animation-delay: 2391ms;
    }
    .circle-container:nth-child(167) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-167;
        animation-name: move-frames-167;
        -webkit-animation-duration: 30327ms;
        animation-duration: 30327ms;
        -webkit-animation-delay: 36695ms;
        animation-delay: 36695ms;
    }
    @-webkit-keyframes move-frames-167 {
        from {
            transform: translate3d(88vw, 102vh, 0);
        }
        to {
            transform: translate3d(44vw, -114vh, 0);
        }
    }
    @keyframes move-frames-167 {
        from {
            transform: translate3d(88vw, 102vh, 0);
        }
        to {
            transform: translate3d(44vw, -114vh, 0);
        }
    }
    .circle-container:nth-child(167) .circle {
        -webkit-animation-delay: 1523ms;
        animation-delay: 1523ms;
    }
    .circle-container:nth-child(168) {
        width: 4px;
        height: 4px;
        -webkit-animation-name: move-frames-168;
        animation-name: move-frames-168;
        -webkit-animation-duration: 34354ms;
        animation-duration: 34354ms;
        -webkit-animation-delay: 8411ms;
        animation-delay: 8411ms;
    }
    @-webkit-keyframes move-frames-168 {
        from {
            transform: translate3d(75vw, 103vh, 0);
        }
        to {
            transform: translate3d(27vw, -110vh, 0);
        }
    }
    @keyframes move-frames-168 {
        from {
            transform: translate3d(75vw, 103vh, 0);
        }
        to {
            transform: translate3d(27vw, -110vh, 0);
        }
    }
    .circle-container:nth-child(168) .circle {
        -webkit-animation-delay: 3576ms;
        animation-delay: 3576ms;
    }
    .circle-container:nth-child(169) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-169;
        animation-name: move-frames-169;
        -webkit-animation-duration: 30931ms;
        animation-duration: 30931ms;
        -webkit-animation-delay: 33835ms;
        animation-delay: 33835ms;
    }
    @-webkit-keyframes move-frames-169 {
        from {
            transform: translate3d(79vw, 102vh, 0);
        }
        to {
            transform: translate3d(37vw, -103vh, 0);
        }
    }
    @keyframes move-frames-169 {
        from {
            transform: translate3d(79vw, 102vh, 0);
        }
        to {
            transform: translate3d(37vw, -103vh, 0);
        }
    }
    .circle-container:nth-child(169) .circle {
        -webkit-animation-delay: 2727ms;
        animation-delay: 2727ms;
    }
    .circle-container:nth-child(170) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-170;
        animation-name: move-frames-170;
        -webkit-animation-duration: 32078ms;
        animation-duration: 32078ms;
        -webkit-animation-delay: 5168ms;
        animation-delay: 5168ms;
    }
    @-webkit-keyframes move-frames-170 {
        from {
            transform: translate3d(77vw, 101vh, 0);
        }
        to {
            transform: translate3d(83vw, -109vh, 0);
        }
    }
    @keyframes move-frames-170 {
        from {
            transform: translate3d(77vw, 101vh, 0);
        }
        to {
            transform: translate3d(83vw, -109vh, 0);
        }
    }
    .circle-container:nth-child(170) .circle {
        -webkit-animation-delay: 1644ms;
        animation-delay: 1644ms;
    }
    .circle-container:nth-child(171) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-171;
        animation-name: move-frames-171;
        -webkit-animation-duration: 30866ms;
        animation-duration: 30866ms;
        -webkit-animation-delay: 23457ms;
        animation-delay: 23457ms;
    }
    @-webkit-keyframes move-frames-171 {
        from {
            transform: translate3d(50vw, 106vh, 0);
        }
        to {
            transform: translate3d(4vw, -116vh, 0);
        }
    }
    @keyframes move-frames-171 {
        from {
            transform: translate3d(50vw, 106vh, 0);
        }
        to {
            transform: translate3d(4vw, -116vh, 0);
        }
    }
    .circle-container:nth-child(171) .circle {
        -webkit-animation-delay: 1165ms;
        animation-delay: 1165ms;
    }
    .circle-container:nth-child(172) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-172;
        animation-name: move-frames-172;
        -webkit-animation-duration: 32887ms;
        animation-duration: 32887ms;
        -webkit-animation-delay: 6076ms;
        animation-delay: 6076ms;
    }
    @-webkit-keyframes move-frames-172 {
        from {
            transform: translate3d(40vw, 108vh, 0);
        }
        to {
            transform: translate3d(47vw, -138vh, 0);
        }
    }
    @keyframes move-frames-172 {
        from {
            transform: translate3d(40vw, 108vh, 0);
        }
        to {
            transform: translate3d(47vw, -138vh, 0);
        }
    }
    .circle-container:nth-child(172) .circle {
        -webkit-animation-delay: 3443ms;
        animation-delay: 3443ms;
    }
    .circle-container:nth-child(173) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-173;
        animation-name: move-frames-173;
        -webkit-animation-duration: 30226ms;
        animation-duration: 30226ms;
        -webkit-animation-delay: 25123ms;
        animation-delay: 25123ms;
    }
    @-webkit-keyframes move-frames-173 {
        from {
            transform: translate3d(27vw, 108vh, 0);
        }
        to {
            transform: translate3d(99vw, -123vh, 0);
        }
    }
    @keyframes move-frames-173 {
        from {
            transform: translate3d(27vw, 108vh, 0);
        }
        to {
            transform: translate3d(99vw, -123vh, 0);
        }
    }
    .circle-container:nth-child(173) .circle {
        -webkit-animation-delay: 3679ms;
        animation-delay: 3679ms;
    }
    .circle-container:nth-child(174) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-174;
        animation-name: move-frames-174;
        -webkit-animation-duration: 28305ms;
        animation-duration: 28305ms;
        -webkit-animation-delay: 4834ms;
        animation-delay: 4834ms;
    }
    @-webkit-keyframes move-frames-174 {
        from {
            transform: translate3d(90vw, 108vh, 0);
        }
        to {
            transform: translate3d(43vw, -133vh, 0);
        }
    }
    @keyframes move-frames-174 {
        from {
            transform: translate3d(90vw, 108vh, 0);
        }
        to {
            transform: translate3d(43vw, -133vh, 0);
        }
    }
    .circle-container:nth-child(174) .circle {
        -webkit-animation-delay: 1674ms;
        animation-delay: 1674ms;
    }
    .circle-container:nth-child(175) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-175;
        animation-name: move-frames-175;
        -webkit-animation-duration: 28427ms;
        animation-duration: 28427ms;
        -webkit-animation-delay: 1484ms;
        animation-delay: 1484ms;
    }
    @-webkit-keyframes move-frames-175 {
        from {
            transform: translate3d(42vw, 104vh, 0);
        }
        to {
            transform: translate3d(99vw, -128vh, 0);
        }
    }
    @keyframes move-frames-175 {
        from {
            transform: translate3d(42vw, 104vh, 0);
        }
        to {
            transform: translate3d(99vw, -128vh, 0);
        }
    }
    .circle-container:nth-child(175) .circle {
        -webkit-animation-delay: 2509ms;
        animation-delay: 2509ms;
    }
    .circle-container:nth-child(176) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-176;
        animation-name: move-frames-176;
        -webkit-animation-duration: 30064ms;
        animation-duration: 30064ms;
        -webkit-animation-delay: 15314ms;
        animation-delay: 15314ms;
    }
    @-webkit-keyframes move-frames-176 {
        from {
            transform: translate3d(69vw, 106vh, 0);
        }
        to {
            transform: translate3d(44vw, -136vh, 0);
        }
    }
    @keyframes move-frames-176 {
        from {
            transform: translate3d(69vw, 106vh, 0);
        }
        to {
            transform: translate3d(44vw, -136vh, 0);
        }
    }
    .circle-container:nth-child(176) .circle {
        -webkit-animation-delay: 3723ms;
        animation-delay: 3723ms;
    }
    .circle-container:nth-child(177) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-177;
        animation-name: move-frames-177;
        -webkit-animation-duration: 34208ms;
        animation-duration: 34208ms;
        -webkit-animation-delay: 33269ms;
        animation-delay: 33269ms;
    }
    @-webkit-keyframes move-frames-177 {
        from {
            transform: translate3d(89vw, 109vh, 0);
        }
        to {
            transform: translate3d(20vw, -136vh, 0);
        }
    }
    @keyframes move-frames-177 {
        from {
            transform: translate3d(89vw, 109vh, 0);
        }
        to {
            transform: translate3d(20vw, -136vh, 0);
        }
    }
    .circle-container:nth-child(177) .circle {
        -webkit-animation-delay: 3647ms;
        animation-delay: 3647ms;
    }
    .circle-container:nth-child(178) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-178;
        animation-name: move-frames-178;
        -webkit-animation-duration: 35861ms;
        animation-duration: 35861ms;
        -webkit-animation-delay: 498ms;
        animation-delay: 498ms;
    }
    @-webkit-keyframes move-frames-178 {
        from {
            transform: translate3d(88vw, 106vh, 0);
        }
        to {
            transform: translate3d(64vw, -130vh, 0);
        }
    }
    @keyframes move-frames-178 {
        from {
            transform: translate3d(88vw, 106vh, 0);
        }
        to {
            transform: translate3d(64vw, -130vh, 0);
        }
    }
    .circle-container:nth-child(178) .circle {
        -webkit-animation-delay: 283ms;
        animation-delay: 283ms;
    }
    .circle-container:nth-child(179) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-179;
        animation-name: move-frames-179;
        -webkit-animation-duration: 33017ms;
        animation-duration: 33017ms;
        -webkit-animation-delay: 19514ms;
        animation-delay: 19514ms;
    }
    @-webkit-keyframes move-frames-179 {
        from {
            transform: translate3d(68vw, 110vh, 0);
        }
        to {
            transform: translate3d(31vw, -138vh, 0);
        }
    }
    @keyframes move-frames-179 {
        from {
            transform: translate3d(68vw, 110vh, 0);
        }
        to {
            transform: translate3d(31vw, -138vh, 0);
        }
    }
    .circle-container:nth-child(179) .circle {
        -webkit-animation-delay: 1648ms;
        animation-delay: 1648ms;
    }
    .circle-container:nth-child(180) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-180;
        animation-name: move-frames-180;
        -webkit-animation-duration: 29837ms;
        animation-duration: 29837ms;
        -webkit-animation-delay: 25172ms;
        animation-delay: 25172ms;
    }
    @-webkit-keyframes move-frames-180 {
        from {
            transform: translate3d(36vw, 107vh, 0);
        }
        to {
            transform: translate3d(65vw, -112vh, 0);
        }
    }
    @keyframes move-frames-180 {
        from {
            transform: translate3d(36vw, 107vh, 0);
        }
        to {
            transform: translate3d(65vw, -112vh, 0);
        }
    }
    .circle-container:nth-child(180) .circle {
        -webkit-animation-delay: 1342ms;
        animation-delay: 1342ms;
    }
    .circle-container:nth-child(181) {
        width: 1px;
        height: 1px;
        -webkit-animation-name: move-frames-181;
        animation-name: move-frames-181;
        -webkit-animation-duration: 31928ms;
        animation-duration: 31928ms;
        -webkit-animation-delay: 17417ms;
        animation-delay: 17417ms;
    }
    @-webkit-keyframes move-frames-181 {
        from {
            transform: translate3d(60vw, 106vh, 0);
        }
        to {
            transform: translate3d(77vw, -125vh, 0);
        }
    }
    @keyframes move-frames-181 {
        from {
            transform: translate3d(60vw, 106vh, 0);
        }
        to {
            transform: translate3d(77vw, -125vh, 0);
        }
    }
    .circle-container:nth-child(181) .circle {
        -webkit-animation-delay: 1205ms;
        animation-delay: 1205ms;
    }
    .circle-container:nth-child(182) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-182;
        animation-name: move-frames-182;
        -webkit-animation-duration: 36567ms;
        animation-duration: 36567ms;
        -webkit-animation-delay: 5596ms;
        animation-delay: 5596ms;
    }
    @-webkit-keyframes move-frames-182 {
        from {
            transform: translate3d(9vw, 105vh, 0);
        }
        to {
            transform: translate3d(18vw, -120vh, 0);
        }
    }
    @keyframes move-frames-182 {
        from {
            transform: translate3d(9vw, 105vh, 0);
        }
        to {
            transform: translate3d(18vw, -120vh, 0);
        }
    }
    .circle-container:nth-child(182) .circle {
        -webkit-animation-delay: 3568ms;
        animation-delay: 3568ms;
    }
    .circle-container:nth-child(183) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-183;
        animation-name: move-frames-183;
        -webkit-animation-duration: 28978ms;
        animation-duration: 28978ms;
        -webkit-animation-delay: 1824ms;
        animation-delay: 1824ms;
    }
    @-webkit-keyframes move-frames-183 {
        from {
            transform: translate3d(31vw, 105vh, 0);
        }
        to {
            transform: translate3d(81vw, -127vh, 0);
        }
    }
    @keyframes move-frames-183 {
        from {
            transform: translate3d(31vw, 105vh, 0);
        }
        to {
            transform: translate3d(81vw, -127vh, 0);
        }
    }
    .circle-container:nth-child(183) .circle {
        -webkit-animation-delay: 3659ms;
        animation-delay: 3659ms;
    }
    .circle-container:nth-child(184) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-184;
        animation-name: move-frames-184;
        -webkit-animation-duration: 28743ms;
        animation-duration: 28743ms;
        -webkit-animation-delay: 24659ms;
        animation-delay: 24659ms;
    }
    @-webkit-keyframes move-frames-184 {
        from {
            transform: translate3d(27vw, 103vh, 0);
        }
        to {
            transform: translate3d(65vw, -131vh, 0);
        }
    }
    @keyframes move-frames-184 {
        from {
            transform: translate3d(27vw, 103vh, 0);
        }
        to {
            transform: translate3d(65vw, -131vh, 0);
        }
    }
    .circle-container:nth-child(184) .circle {
        -webkit-animation-delay: 187ms;
        animation-delay: 187ms;
    }
    .circle-container:nth-child(185) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-185;
        animation-name: move-frames-185;
        -webkit-animation-duration: 28558ms;
        animation-duration: 28558ms;
        -webkit-animation-delay: 20430ms;
        animation-delay: 20430ms;
    }
    @-webkit-keyframes move-frames-185 {
        from {
            transform: translate3d(77vw, 101vh, 0);
        }
        to {
            transform: translate3d(80vw, -109vh, 0);
        }
    }
    @keyframes move-frames-185 {
        from {
            transform: translate3d(77vw, 101vh, 0);
        }
        to {
            transform: translate3d(80vw, -109vh, 0);
        }
    }
    .circle-container:nth-child(185) .circle {
        -webkit-animation-delay: 3566ms;
        animation-delay: 3566ms;
    }
    .circle-container:nth-child(186) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-186;
        animation-name: move-frames-186;
        -webkit-animation-duration: 31645ms;
        animation-duration: 31645ms;
        -webkit-animation-delay: 24840ms;
        animation-delay: 24840ms;
    }
    @-webkit-keyframes move-frames-186 {
        from {
            transform: translate3d(55vw, 106vh, 0);
        }
        to {
            transform: translate3d(60vw, -111vh, 0);
        }
    }
    @keyframes move-frames-186 {
        from {
            transform: translate3d(55vw, 106vh, 0);
        }
        to {
            transform: translate3d(60vw, -111vh, 0);
        }
    }
    .circle-container:nth-child(186) .circle {
        -webkit-animation-delay: 1458ms;
        animation-delay: 1458ms;
    }
    .circle-container:nth-child(187) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-187;
        animation-name: move-frames-187;
        -webkit-animation-duration: 31588ms;
        animation-duration: 31588ms;
        -webkit-animation-delay: 20761ms;
        animation-delay: 20761ms;
    }
    @-webkit-keyframes move-frames-187 {
        from {
            transform: translate3d(86vw, 105vh, 0);
        }
        to {
            transform: translate3d(85vw, -133vh, 0);
        }
    }
    @keyframes move-frames-187 {
        from {
            transform: translate3d(86vw, 105vh, 0);
        }
        to {
            transform: translate3d(85vw, -133vh, 0);
        }
    }
    .circle-container:nth-child(187) .circle {
        -webkit-animation-delay: 2454ms;
        animation-delay: 2454ms;
    }
    .circle-container:nth-child(188) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-188;
        animation-name: move-frames-188;
        -webkit-animation-duration: 33632ms;
        animation-duration: 33632ms;
        -webkit-animation-delay: 2226ms;
        animation-delay: 2226ms;
    }
    @-webkit-keyframes move-frames-188 {
        from {
            transform: translate3d(63vw, 101vh, 0);
        }
        to {
            transform: translate3d(72vw, -127vh, 0);
        }
    }
    @keyframes move-frames-188 {
        from {
            transform: translate3d(63vw, 101vh, 0);
        }
        to {
            transform: translate3d(72vw, -127vh, 0);
        }
    }
    .circle-container:nth-child(188) .circle {
        -webkit-animation-delay: 1974ms;
        animation-delay: 1974ms;
    }
    .circle-container:nth-child(189) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-189;
        animation-name: move-frames-189;
        -webkit-animation-duration: 28748ms;
        animation-duration: 28748ms;
        -webkit-animation-delay: 3992ms;
        animation-delay: 3992ms;
    }
    @-webkit-keyframes move-frames-189 {
        from {
            transform: translate3d(16vw, 102vh, 0);
        }
        to {
            transform: translate3d(22vw, -123vh, 0);
        }
    }
    @keyframes move-frames-189 {
        from {
            transform: translate3d(16vw, 102vh, 0);
        }
        to {
            transform: translate3d(22vw, -123vh, 0);
        }
    }
    .circle-container:nth-child(189) .circle {
        -webkit-animation-delay: 3669ms;
        animation-delay: 3669ms;
    }
    .circle-container:nth-child(190) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-190;
        animation-name: move-frames-190;
        -webkit-animation-duration: 29285ms;
        animation-duration: 29285ms;
        -webkit-animation-delay: 3301ms;
        animation-delay: 3301ms;
    }
    @-webkit-keyframes move-frames-190 {
        from {
            transform: translate3d(56vw, 105vh, 0);
        }
        to {
            transform: translate3d(67vw, -109vh, 0);
        }
    }
    @keyframes move-frames-190 {
        from {
            transform: translate3d(56vw, 105vh, 0);
        }
        to {
            transform: translate3d(67vw, -109vh, 0);
        }
    }
    .circle-container:nth-child(190) .circle {
        -webkit-animation-delay: 1136ms;
        animation-delay: 1136ms;
    }
    .circle-container:nth-child(191) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-191;
        animation-name: move-frames-191;
        -webkit-animation-duration: 28995ms;
        animation-duration: 28995ms;
        -webkit-animation-delay: 36009ms;
        animation-delay: 36009ms;
    }
    @-webkit-keyframes move-frames-191 {
        from {
            transform: translate3d(49vw, 107vh, 0);
        }
        to {
            transform: translate3d(55vw, -121vh, 0);
        }
    }
    @keyframes move-frames-191 {
        from {
            transform: translate3d(49vw, 107vh, 0);
        }
        to {
            transform: translate3d(55vw, -121vh, 0);
        }
    }
    .circle-container:nth-child(191) .circle {
        -webkit-animation-delay: 3366ms;
        animation-delay: 3366ms;
    }
    .circle-container:nth-child(192) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-192;
        animation-name: move-frames-192;
        -webkit-animation-duration: 33817ms;
        animation-duration: 33817ms;
        -webkit-animation-delay: 14915ms;
        animation-delay: 14915ms;
    }
    @-webkit-keyframes move-frames-192 {
        from {
            transform: translate3d(84vw, 108vh, 0);
        }
        to {
            transform: translate3d(94vw, -115vh, 0);
        }
    }
    @keyframes move-frames-192 {
        from {
            transform: translate3d(84vw, 108vh, 0);
        }
        to {
            transform: translate3d(94vw, -115vh, 0);
        }
    }
    .circle-container:nth-child(192) .circle {
        -webkit-animation-delay: 2685ms;
        animation-delay: 2685ms;
    }
    .circle-container:nth-child(193) {
        width: 6px;
        height: 6px;
        -webkit-animation-name: move-frames-193;
        animation-name: move-frames-193;
        -webkit-animation-duration: 32125ms;
        animation-duration: 32125ms;
        -webkit-animation-delay: 16164ms;
        animation-delay: 16164ms;
    }
    @-webkit-keyframes move-frames-193 {
        from {
            transform: translate3d(65vw, 108vh, 0);
        }
        to {
            transform: translate3d(89vw, -112vh, 0);
        }
    }
    @keyframes move-frames-193 {
        from {
            transform: translate3d(65vw, 108vh, 0);
        }
        to {
            transform: translate3d(89vw, -112vh, 0);
        }
    }
    .circle-container:nth-child(193) .circle {
        -webkit-animation-delay: 3350ms;
        animation-delay: 3350ms;
    }
    .circle-container:nth-child(194) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-194;
        animation-name: move-frames-194;
        -webkit-animation-duration: 32413ms;
        animation-duration: 32413ms;
        -webkit-animation-delay: 13587ms;
        animation-delay: 13587ms;
    }
    @-webkit-keyframes move-frames-194 {
        from {
            transform: translate3d(85vw, 108vh, 0);
        }
        to {
            transform: translate3d(10vw, -114vh, 0);
        }
    }
    @keyframes move-frames-194 {
        from {
            transform: translate3d(85vw, 108vh, 0);
        }
        to {
            transform: translate3d(10vw, -114vh, 0);
        }
    }
    .circle-container:nth-child(194) .circle {
        -webkit-animation-delay: 789ms;
        animation-delay: 789ms;
    }
    .circle-container:nth-child(195) {
        width: 7px;
        height: 7px;
        -webkit-animation-name: move-frames-195;
        animation-name: move-frames-195;
        -webkit-animation-duration: 32210ms;
        animation-duration: 32210ms;
        -webkit-animation-delay: 27213ms;
        animation-delay: 27213ms;
    }
    @-webkit-keyframes move-frames-195 {
        from {
            transform: translate3d(58vw, 102vh, 0);
        }
        to {
            transform: translate3d(56vw, -119vh, 0);
        }
    }
    @keyframes move-frames-195 {
        from {
            transform: translate3d(58vw, 102vh, 0);
        }
        to {
            transform: translate3d(56vw, -119vh, 0);
        }
    }
    .circle-container:nth-child(195) .circle {
        -webkit-animation-delay: 452ms;
        animation-delay: 452ms;
    }
    .circle-container:nth-child(196) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-196;
        animation-name: move-frames-196;
        -webkit-animation-duration: 35195ms;
        animation-duration: 35195ms;
        -webkit-animation-delay: 8774ms;
        animation-delay: 8774ms;
    }
    @-webkit-keyframes move-frames-196 {
        from {
            transform: translate3d(1vw, 110vh, 0);
        }
        to {
            transform: translate3d(11vw, -128vh, 0);
        }
    }
    @keyframes move-frames-196 {
        from {
            transform: translate3d(1vw, 110vh, 0);
        }
        to {
            transform: translate3d(11vw, -128vh, 0);
        }
    }
    .circle-container:nth-child(196) .circle {
        -webkit-animation-delay: 2739ms;
        animation-delay: 2739ms;
    }
    .circle-container:nth-child(197) {
        width: 2px;
        height: 2px;
        -webkit-animation-name: move-frames-197;
        animation-name: move-frames-197;
        -webkit-animation-duration: 33791ms;
        animation-duration: 33791ms;
        -webkit-animation-delay: 18376ms;
        animation-delay: 18376ms;
    }
    @-webkit-keyframes move-frames-197 {
        from {
            transform: translate3d(75vw, 104vh, 0);
        }
        to {
            transform: translate3d(38vw, -114vh, 0);
        }
    }
    @keyframes move-frames-197 {
        from {
            transform: translate3d(75vw, 104vh, 0);
        }
        to {
            transform: translate3d(38vw, -114vh, 0);
        }
    }
    .circle-container:nth-child(197) .circle {
        -webkit-animation-delay: 1280ms;
        animation-delay: 1280ms;
    }
    .circle-container:nth-child(198) {
        width: 3px;
        height: 3px;
        -webkit-animation-name: move-frames-198;
        animation-name: move-frames-198;
        -webkit-animation-duration: 36729ms;
        animation-duration: 36729ms;
        -webkit-animation-delay: 5911ms;
        animation-delay: 5911ms;
    }
    @-webkit-keyframes move-frames-198 {
        from {
            transform: translate3d(59vw, 110vh, 0);
        }
        to {
            transform: translate3d(78vw, -130vh, 0);
        }
    }
    @keyframes move-frames-198 {
        from {
            transform: translate3d(59vw, 110vh, 0);
        }
        to {
            transform: translate3d(78vw, -130vh, 0);
        }
    }
    .circle-container:nth-child(198) .circle {
        -webkit-animation-delay: 3809ms;
        animation-delay: 3809ms;
    }
    .circle-container:nth-child(199) {
        width: 5px;
        height: 5px;
        -webkit-animation-name: move-frames-199;
        animation-name: move-frames-199;
        -webkit-animation-duration: 28322ms;
        animation-duration: 28322ms;
        -webkit-animation-delay: 21686ms;
        animation-delay: 21686ms;
    }
    @-webkit-keyframes move-frames-199 {
        from {
            transform: translate3d(58vw, 107vh, 0);
        }
        to {
            transform: translate3d(79vw, -112vh, 0);
        }
    }
    @keyframes move-frames-199 {
        from {
            transform: translate3d(58vw, 107vh, 0);
        }
        to {
            transform: translate3d(79vw, -112vh, 0);
        }
    }
    .circle-container:nth-child(199) .circle {
        -webkit-animation-delay: 2178ms;
        animation-delay: 2178ms;
    }
    .circle-container:nth-child(200) {
        width: 8px;
        height: 8px;
        -webkit-animation-name: move-frames-200;
        animation-name: move-frames-200;
        -webkit-animation-duration: 35888ms;
        animation-duration: 35888ms;
        -webkit-animation-delay: 26907ms;
        animation-delay: 26907ms;
    }
    @-webkit-keyframes move-frames-200 {
        from {
            transform: translate3d(71vw, 101vh, 0);
        }
        to {
            transform: translate3d(49vw, -104vh, 0);
        }
    }
    @keyframes move-frames-200 {
        from {
            transform: translate3d(71vw, 101vh, 0);
        }
        to {
            transform: translate3d(49vw, -104vh, 0);
        }
    }
    .circle-container:nth-child(200) .circle {
        -webkit-animation-delay: 1367ms;
        animation-delay: 1367ms;
    }
}
