/* .choose-color {
  position: relative;
}

.color-picker {
  z-index: 100;
  position: absolute;
  top: calc(100% + 1rem);
  right: 0;
  width: max-content;
  height: max-content;
  overflow: hidden;
  border: 1px solid #e3e8ee;
  transition: all 0.2s ease-in-out;
} */

.sketch-picker {
    border: none !important;
    width: 240px !important;
}
/* 
.color-picker__inactive {
  width: 0;
  height: 0;
  padding: 0;
}

.color-saver {
  position: absolute;
  bottom: 0;
  right: 0;
  border: none;
  border-radius: 3px;
  background-color: #4884e2;
  color: #fff;
  padding: 0 1em;
  margin-right: 1em;
  margin-bottom: 0.8em;
} */
