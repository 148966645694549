em-emoji-picker {
    height: 300px;
    position: absolute;
    bottom: calc(100% + 5px);
    right: 0;
    @media (max-height: 768px) {
        height: 220px;
    }
}
.editor-controls {
    em-emoji-picker {
        left: 0;
    }
}
