.auth-page {
    .wrapper {
        background: #f9f9f9;
        display: flex;
        flex-flow: column;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        .logo-text-gorilla {
            padding: 40px 10px;
        }
        .main-login {
            display: flex;
            flex-flow: column;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            flex: 1;
            width: 515px !important;
            @media screen and (max-width: 610px) {
                width: 95% !important;
            }
        }
        .gd-form-v2 {
            background-color: #fff;
            border-radius: 8px;
            border: solid 2px #f0f0f0;
            box-sizing: border-box !important;
            opacity: 1;
            // padding: 50px 20px 80px;
            width: 100%;
            h2 {
                text-transform: capitalize;
                text-align: center;
            }
        }
        .gd-login-confirm {
            padding: 15px 0 45px;
            color: #555b64;
            font-size: 13px;
            font-weight: 500;
            text-align: center;
        }
        .gd-login-label {
            color: #8d3eff;
            margin-left: 4px;
            &:hover {
                text-decoration: underline;
                opacity: 0.8;
            }
        }
        .mascot {
            margin-top: 15px;
            flex: 0 0 auto;
        }
        .gd-login-btn-group {
            padding: 0 41px;
            .user-box {
                margin-bottom: 16px;
            }
            label {
                color: #555b64;
                line-height: 1.6;
            }
            input {
                height: 40px;
            }
        }
        .v2-btn-main {
            justify-content: center;
            height: 40px;
            width: 100%;
        }
    }
}
