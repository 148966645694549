@import 'src/assets/css/common/variables';
@import 'src/assets/css/common/mixins';
.report-page {
    &:not(.active) {
        .container-wrap:not(.container-commision) {
            padding-left: 16px;
        }
    }
    .wrapper-columns {
        padding: 0 16px;
        &:not(.has-chart):not(:has(.table-time-tracker)) {
            padding-top: 2px;
        }
        &:not(.has-chart) {
            .tables {
                &:has(.content-empty) {
                    .tables-list {
                        position: sticky;
                        left: 0;
                    }
                }
            }
        }
    }
    .container-table {
        &:not(.is-hide-panel) {
            .wrapper-columns {
                gap: 16px;
            }
        }
        &.is-hide-panel {
            .wrapper-columns {
                padding-left: 0;
            }
            .modal-revenue-details {
                width: 100%;
            }
        }
        .wrap-tables {
            .tables-list {
                .rows {
                    overflow: unset !important;
                    &:last-child {
                        box-shadow: inset 0px -1px 0px $border-color-grey;
                    }
                    &:has(.col.--menu) {
                        .v2-dropdown.active {
                            z-index: 1;
                        }
                    }
                }
            }
            .tables {
                .rows {
                    .col {
                        .status-btn.failed.tooltip {
                            overflow: hidden;
                        }
                        &.--feedback {
                            max-width: 800px;
                            min-width: 280px;
                        }
                        &.col-note,
                        &.--feedback {
                            &:not(.is-more) {
                                .content {
                                    @include text-line-clamp(10);
                                }
                            }
                        }
                    }
                    &.--header {
                        background-color: $white-anti-flash;
                        border-radius: 6px 6px 0 0;
                    }
                }
                .title-material {
                    & + .rows.--header {
                        border-radius: unset;
                    }
                }
            }
            .header.--filter {
                box-shadow: inset 0 -1px 0 $grey-light-2;
                & + .tables,
                & + .tab-contents,
                & + .tab-conts {
                    .rows.--header {
                        border-radius: unset;
                    }
                }
            }
            .v2-dropdown__menu {
                @media (max-height: 650px) {
                    &:not(.content-checked) {
                        max-height: 250px;
                    }
                    .status-dropdown {
                        max-height: 250px;
                    }
                }
            }
            .btn-add-time {
                position: absolute;
                right: 16px;
                top: 8px;
            }
        }
        .header {
            &.--main,
            &.--filter {
                .header-items {
                    .dropbtn {
                        .txt-ellipsis {
                            max-width: 300px;
                        }
                    }
                }
            }
        }
        .react-datepicker-popper {
            .react-datepicker__day:focus-visible {
                outline: none;
            }
        }
    }
    .chartjs {
        padding: 16px;
        color: $black-2;
        height: fit-content;
        &.false {
            .chartjs__content {
                display: none;
            }
            .is-open svg {
                @include transform(rotateZ(180deg));
            }
        }
        &__header {
            @include betweentop;
            margin-bottom: 4px;
            .title {
                flex: 1;
                display: inline-block;
                font-size: 15px;
                line-height: 32px;
                font-weight: 500;
                @include text-ellipsis;
                margin-right: 3px;
                b {
                    margin-left: 5px;
                    font-size: 17px;
                }
            }
        }
        &__legend {
            @include flexcenter;
            min-height: 30px;
            .list-years {
                @include flexwrap;
                align-items: center;
                margin-top: -8px;
                &__items {
                    @include flexcenter;
                    opacity: 0.2;
                    font-size: 13px;
                    line-height: 18px;
                    cursor: pointer;
                    margin-right: 24px;
                    margin-top: 8px;
                    &:hover,
                    &.active {
                        opacity: 1;
                    }
                }
            }
        }
        &__content {
            margin-top: 15px;
            @include betweentop;
            position: relative;
            .preloader {
                position: absolute;
                background: rgba(0, 0, 0, 0.1);
                height: calc(100% + 45px);
                width: calc(100% + 10px);
                top: -45px;
                left: -5px;
            }
            .content-l {
                width: 75%;
                .number-count {
                    font-size: 15px;
                    font-weight: 500;
                    padding-bottom: 12px;
                    padding-left: 3px;
                }
            }
            .content-r {
                width: 23%;
                &__view {
                    @include size(170px);
                    margin: 0px auto;
                }
                &__details {
                    padding-top: 25px;
                    &.active {
                        .list-tracker {
                            overflow-y: auto;
                            max-height: 145px;
                        }
                    }
                }
                .rows {
                    font-size: 13px;
                    @include betweentop;
                    &__label {
                        @include flextop;
                    }
                    &__number {
                        font-weight: 500;
                    }
                    & + .rows {
                        margin-top: 10px;
                    }
                }
                .list-tracker {
                    max-height: 100px;
                    overflow-y: hidden;
                    padding: 0px 10px;
                }
                .show-tracker {
                    margin-left: 4px;
                    margin-top: 5px;
                    @include size(24px);
                    @include centeritem;
                    cursor: pointer;
                }
                .chartjs-render-monitor {
                    @include size(170px!important);
                    @media screen and (max-width: 1024px) {
                        @include size(140px!important);
                    }
                }
            }
            .chartjs-render-monitor {
                max-width: 100%;
                width: 100% !important;
            }
            &.--multi-chart {
                height: 340px;
                .content-l {
                    .chartjs-render-monitor {
                        height: 340px !important;
                    }
                }
                @media screen and (max-height: 700px) {
                    .content-r__view {
                        @include size(130px);
                        .chartjs-render-monitor {
                            @include size(130px!important);
                        }
                    }
                }
            }
            &.--signle-chart {
                height: 300px;
                .chartjs-render-monitor {
                    height: 300px !important;
                }
            }
        }
        .dots-color {
            flex: 0 0 auto;
            @include size(14px);
            margin-right: 6px;
            margin-top: 2px;
            border-radius: 5px;
        }
        .--years-items {
            margin-left: -10px;
            margin-right: 6px;
            .dropbtn {
                border: none;
                box-shadow: none;
            }
            .v2-dropdown__menu {
                width: 200px;
                z-index: 2;
            }
        }
        @media only screen and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
            .chartjs__content {
                .content-l {
                    width: 62%;
                }
                .content-r {
                    width: 35%;
                }
            }
        }
        @media only screen and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
            padding: 12px 16px;
            .chartjs__content {
                .content-l {
                    width: 60%;
                }
                .content-r {
                    width: 38%;
                }
            }
        }
    }
    .timeline-year {
        flex: 1;
        @include flexwrap;
        &__range {
            margin-left: 8px;
            color: $grey-extrlight;
            &.selected {
                color: $white-default;
                background-color: $background-title;
            }
        }
    }
    .container-commision {
        overflow: visible;
        .header-title {
            .tabs,
            .wrap-btn {
                flex: 1;
            }
            .wrap-btn {
                @include flexend;
            }
            .tooltiptext {
                min-height: 20px;
            }
        }
        .header {
            &.--filter {
                .header__left {
                    @media (max-width: 1350px) {
                        min-width: 100%;
                    }
                }
            }
        }
        .wrap-commission {
            overflow: hidden;
            flex: 1;
            &__content {
                @include size(100%);
            }
            .tab-conts {
                position: relative;
                @include size(100%);
            }
            .content-setup {
                border-top: solid 1px $border-color-grey;
            }
        }
        .setting-user {
            position: relative;
            width: 320px;
            border-right: solid 1px $border-color-grey;
            @include flexcolumns;
            justify-content: space-between;
            overflow-y: auto;
            &__list {
                flex: 1;
                .item-user {
                    padding: 16px;
                    cursor: pointer;
                    @include flexcenter;
                    border-bottom: 1px solid $border-color-grey;
                    &:hover,
                    &.selected {
                        background: $yellow-cosmic-latte;
                        box-shadow: inset -2px 0px 0px $yellow-default;
                    }
                    &__avt {
                        margin: 0px 8px;
                        @include size(32px);
                        background: $bg-body;
                        border-radius: 12px;
                        img {
                            object-fit: cover;
                            border-radius: 12px;
                        }
                    }
                    &__name {
                        flex: 1;
                        color: $black;
                        @include text-ellipsis;
                    }
                }
            }
            &__bottom {
                background: $btn-disable;
                box-shadow: 0px 1px 0px 0px $border-color-grey inset;
                padding: 16px;
                position: sticky;
                bottom: 0px;
                .label {
                    margin-bottom: 2px;
                    color: $grey-very-dark;
                }
                .v2-dropdown {
                    background: $white;
                    flex: 1;
                    margin-right: 8px;
                    &__menu {
                        bottom: calc(100% + 3px);
                        top: auto;
                        max-width: 300px;
                    }
                }
            }
        }
        .setup-tracking {
            @include size(100%);
            display: flex;
        }
        .table-setup {
            .tables-list {
                font-size: 14px;
                overflow: visible;
                .col {
                    &.--line-item {
                        font-weight: 500;
                        font-size: 13px;
                    }
                }
            }
            .rows {
                overflow: visible;
                align-items: center;
                &.--header {
                    font-size: 13px;
                    @extend .fw-600;
                    z-index: 3;
                    .col-divider {
                        height: 16px;
                        margin-top: 10px;
                    }
                }
                .tooltiptext {
                    max-width: 250px;
                }
            }
            .col-divider {
                border-left: solid 1px $border-color-grey;
                height: 40px;
                margin: 5px 16px 5px 0px;
            }
            .col {
                color: $grey-extradark;
                &.--commission {
                    min-width: 270px !important;
                    width: 270px !important;
                    .switch {
                        margin-top: -3px;
                    }
                }
                &.--line-item {
                    min-width: 200px;
                    width: 200px;
                    flex: 1;
                }
                &.--cost {
                    max-width: 120px !important;
                    width: 120px;
                }
            }
            .lineitems-selection {
                .v2-dropdown {
                    margin-left: 4px;
                    .dropbtn {
                        padding-left: 6px;
                        .txt-ellipsis {
                            width: 24px;
                        }
                    }
                    &__menu {
                        min-width: 200px;
                        width: max-content;
                        right: 0px;
                    }
                }
            }
            .is-disable {
                opacity: 0.6;
                pointer-events: none;
                .btn-item__input {
                    color: $grey-soft-txt;
                }
                .tab-items.active-tab-selector,
                .btn-item__value {
                    background: $grey-soft;
                }
                svg path {
                    stroke: $grey-verylight;
                }
            }
        }
        .container-print {
            .tables {
                height: 100%;
                .rows {
                    &.--footer {
                        z-index: auto !important;
                    }
                }
                .col {
                    &.col-xl {
                        @media (orientation: portrait) {
                            min-width: 200px;
                        }
                    }
                }
            }
        }
    }
    .new-customer {
        .chartjs__content .preloader {
            @include size(100%);
            left: 0px;
            top: -3px;
        }
    }
    .container-wrap {
        &:has(a[href*='revenue'].active) {
            .wrap-tables {
                overflow: visible;
            }
        }
    }
    // Style for modal revenue details
    .modal-revenue-details,
    .modal-clock-details {
        display: flex;
        align-items: flex-end;
        left: auto;
        padding: 0 16px;
        width: calc(100% - 256px);
        & > .modal__container {
            width: 100%;
            max-height: revert;
            height: 65%;
            @include transform(none);
            top: auto;
            border-radius: 8px 8px 0px 0px;
        }
        .header {
            .resize {
                @include centeritem;
                width: 100%;
                &-items {
                    width: 32px;
                    height: 4px;
                    border-radius: 3px;
                    background-color: $grey-middle;
                    cursor: pointer;
                }
            }
        }
    }
    .modal-revenue-details {
        @media screen and (max-width: 1024px) {
            width: 100% !important;
            position: absolute;
            overflow-x: visible;
            padding: 0px;
        }
        @media screen and (max-device-width: 1180px) {
            position: fixed;
            padding: 0 16px;
            .modal__overlay {
                background-color: $bg-overlay !important;
            }
        }
    }
    .header-v2 {
        z-index: 10001;
    }
    .header.--main,
    .container-report .sidebar-menu,
    .sidebar-container {
        background-color: $white-anti-flash;
    }
    .header.--main {
        z-index: 10000;
    }
    .sidebar-container {
        z-index: 9997;
        &:hover {
            z-index: 10001;
        }
    }
    .container-report {
        .sidebar-menu {
            z-index: 9996;
        }
        &:has(.--material) {
            .header.--main {
                .header__left {
                    .budget {
                        .txt-ellipsis {
                            max-width: 85px;
                        }
                    }
                }
            }
        }
        .header.--main:not(:has(> .search-input)) {
            .header__left {
                min-width: unset;
                max-width: 100%;
            }
        }
    }
    .v2-btn-default {
        &.btn-delete {
            background-color: $red-extralight !important;
            border-color: $transparent !important;
            color: $red-default;
            &:hover {
                color: $red-hover;
            }
            &:active {
                color: $red-active;
            }
            svg path {
                stroke: $red-default;
            }
        }
    }
    // Clock in/out View
    .modal-clock-details {
        width: 100%;
        & > .modal__container {
            height: 80%;
            .header {
                border-radius: 8px 8px 0 0;
                &__left {
                    .field-date {
                        & + .modal__container {
                            width: fit-content;
                            .react-datepicker {
                                display: flex;
                                .datepicker-header-custom.--just-label {
                                    .label-month {
                                        width: 100%;
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                    @media (max-width: 1024px) {
                        min-width: 100%;
                    }
                }
            }
        }
        .clock-container {
            padding: 16px;
            border-top: 1px solid $border-color-grey;
            height: 100%;
            overflow: auto;
            .wrapper-clock__container {
                min-width: 950px;
            }
            .clock-view {
                margin-bottom: 0;
                .clock-table {
                    border-radius: 8px;
                }
                .col-right {
                    border-bottom: 0;
                    &:has(.rows:nth-of-type(4)) {
                        .rows:has(.v2-dropdown.active) {
                            & + .v2-dropdown {
                                .v2-dropdown__menu {
                                    bottom: calc(100% + 36px);
                                    top: unset;
                                }
                            }
                        }
                    }
                }
                .fc-scrollgrid-section > td {
                    border-bottom: 0px;
                }
            }
        }
        &:has(.--menu > .v2-dropdown.active) {
            .v2-dropdown[data-popper-reference-hidden] {
                display: none !important;
            }
        }
        @media (min-width: 1351px) {
            position: absolute;
            padding: 0;
        }
        @media (max-width: 1350px) {
            height: calc(100dvh - 192px);
            top: unset;
            position: fixed !important;
            & > .modal__container {
                height: 90%;
            }
        }
    }
    &:has(.modal-clock-details.open) {
        .header-v2 {
            &:has(.alert) {
                z-index: 100001;
            }
        }
        @media (max-width: 1350px) {
            .wrapper-columns {
                overflow: unset;
            }
        }
    }
    @media screen and (max-width: 820px) {
        .container-print {
            &.new-customer .chartjs {
                min-width: 700px;
            }
        }
    }
    @media only screen and (max-width: 1300px) {
        .header {
            &.--main {
                & > .search-input {
                    width: 200px;
                }
            }
        }
    }
    @media only screen and (max-width: 1180px) {
        .container-wrap {
            &:has(a[href*='revenue'].active) {
                .tables:not(.--revenue) {
                    .rows {
                        &.--total {
                            .col:first-child .col-label {
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
    }
}

.review-page {
    .rate {
        margin-top: 0px;
        height: 20px;
        &__items {
            margin: 0px;
        }
        svg {
            @include transform(scale(1));
            margin-top: -4px;
        }
    }
    .tables {
        .rows .col {
            &.--lg-full {
                min-width: 40%;
            }
        }
        @media only screen and (max-width: 1180px) {
            .rows .col {
                &.--lg-full {
                    min-width: 200px;
                }
                &.--customer {
                    max-width: 100px;
                }
            }
        }
    }
}

.gorilladesk {
    &.active {
        .modal-revenue-details {
            width: calc(100% - 304px);
        }
        .container-wrap.is-hide-panel .modal-revenue-details {
            width: calc(100% - 64px);
        }
    }
}
