body {
    overflow-y: hidden;
    color-scheme: light;
    &.--dark-mode {
        filter: brightness(calc(var(--brightness) / 4 + 75%)) contrast(calc(var(--contrast) / 4 + 75%))
            sepia(var(--sepia)) #{'grayscale(var(--grayscale))'};
        color-scheme: dark;
        .header-v2:not(.--light) {
            background-color: $bg-header !important;
        }
    }
}

// Progress bar Modal resize & Dark Mode
.dropdown-dark-mode,
.modal-resize {
    .progress-bar {
        background-color: $border-progress;
        .range-overlay {
            background: $purple-default;
        }
        .slider-main {
            width: 100%;
            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                @include size(32px);
                border-radius: 50%;
                background-color: $bg-progress;
                box-shadow: 0px 0px 1px 0px $border-progress, 0px 1px 2px 0px $border-progress,
                    0px 1px 3px 0px $border-progress;
                border: 1px solid $border-progress;
            }
            &::-moz-range-thumb {
                @include size(32px);
                border-radius: 50%;
                background-color: $bg-progress;
                box-shadow: 0px 0px 1px 0px $border-progress, 0px 1px 2px 0px $border-progress,
                    0px 1px 3px 0px $border-progress;
                border: 1px solid $border-progress;
            }
        }
        &,
        .range-overlay,
        .slider-main {
            height: 8px;
        }
    }
}

// Dropdown dark mode
.dropdown-dark-mode {
    @include size(24px);
    .dropbtn {
        padding: 1px;
    }
    .box-dark-mode {
        min-width: 300px;
        .progress-bar {
            .slider-main {
                &::-webkit-slider-thumb {
                    @include size(24px);
                }
                &::-moz-range-thumb {
                    @include size(24px);
                }
            }
            &,
            .range-overlay,
            .slider-main {
                height: 6px;
            }
        }
        .is-line {
            height: 1px;
            background-color: $border-color-grey;
        }
        .v2-btn-default:hover {
            box-shadow: 0px 4px 6px $black-darker6, 0px 2px 3px $black-darker6, 0px 1px 2px $black-darker6,
                0px 0px 1px $black-darker6 !important;
        }
    }
}

.pac-container {
    background-color: $white;
    box-shadow: $boxshadow-dropdown-main;
    border-top: 1px solid $border-color-grey;
    .pac-item-query {
        color: $black;
    }
    .pac-matched {
        color: $grey-faux-granite;
    }
    .pac-item {
        border-top: 1px solid $border-color-grey;
        &:hover {
            background-color: $black-extradark;
        }
    }
}
