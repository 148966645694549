.chart-wrapper {
    canvas {
        height: 100% !important;
    }
}
.chartjs__content {
    .content-r {
        .wrapper {
            width: 170px;
            height: 170px;
            margin: auto;
            margin-bottom: 32px;
        }
    }
}
