@import 'src/assets/css/common/variables';
@import 'src/assets/css/common/mixins';

.c-error {
    width: 100%;
    min-height: 100%;
    height: max-content;
    background: $btn-disable !important;
    color: $grey-dark !important;
    @include betweenitems;
    flex-direction: column;
    .container {
        @include centeritem;
        flex-direction: column;
        flex: 1;
        max-width: 1024px;
        margin: 24px;
        &__box {
            width: 485px;
            background: $white;
            box-shadow: inset 0px 0px 0px 1px $light-silver;
            color: $grey-extradark;
            padding: 40px;
            border-radius: 4px;
            line-height: 21px;
            .desc {
                &.--number {
                    display: list-item;
                    margin-left: 1em;
                    line-height: 26px;
                }
            }
            .v2-dropdown {
                &__menu {
                    .items {
                        .txt-ellipsis {
                            white-space: nowrap;
                        }
                    }
                    @media (max-height: 700px) {
                        max-height: 200px;
                    }
                }
            }
        }
        @media screen and (max-width: 767px) {
            margin: 16px;
            &__box {
                width: 100%;
            }
        }
    }
    .title {
        color: $grey-extradark !important;
        font-size: 29px;
    }
    .description {
        margin: 24px 0px 50px;
    }
    .v2-btn-default {
        &:hover {
            background-color: $white !important;
        }
    }
    .btn-contact {
        background-color: $white;
        margin-left: 24px;
    }
}
