.zoom-bar {
    flex: 1;
    justify-content: flex-end;
    @include flexcenter;
    .btn-view {
        margin-right: 0;
        &:hover {
            cursor: pointer;
        }
    }
    .slidecontainer {
        pointer-events: auto;
        margin-right: 1px;
        @include flexcenter;
        .slider {
            width: 78px;
            height: 4px;
            border-radius: 2px;
            background-color: $black-darker6;
            -webkit-appearance: none;
            outline: none;
            &::-webkit-slider-thumb {
                @include size(16px);
                border-radius: 6px;
                background: $background-title;
                cursor: pointer;
                -webkit-appearance: none;
                appearance: none;
            }
            &::-moz-range-thumb {
                @include size(16px);
                border-radius: 6px;
                background: $background-title;
                cursor: pointer;
            }
        }
    }
}
